import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DeliveryZoneFormPageRoutingModule } from './delivery-zone-form-routing.module';

import { DeliveryZoneFormPage } from './delivery-zone-form.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModalModule } from 'src/app/components/loading-modal/loading-modal.module';
import { LocationPickerModule } from 'src/app/components/location-picker/location-picker.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DeliveryZoneFormPageRoutingModule,
    ReactiveFormsModule,
    LoadingModalModule,
    LocationPickerModule,
    ComponentsModule,
    TranslateModule.forChild()
  ],
  declarations: [DeliveryZoneFormPage]
})
export class DeliveryZoneFormPageModule {}
