import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { CountryService } from 'src/app/services/logged-in/country.service';
import { ShippingMethodService } from 'src/app/services/logged-in/shipping-method.service';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import mixpanel from 'mixpanel-browser';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-fedex',
  templateUrl: './fedex.page.html',
  styleUrls: ['./fedex.page.scss'],
})
export class FedexPage implements OnInit {

  public borderLimit = false;

  public form: FormGroup;

  public countryList = [];

  public loading: boolean = false;
  
  public saving: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public translateService: TranslateLabelService,
    public storeServices: StoreService,
    public countryService: CountryService,
    public shippingService: ShippingMethodService,
    public analytics: AnalyticsService
  ) { }

  ngOnInit() {
    this.analytics.page('Fedex config page');

    this.loadData();

    this.loadCountries();
  }
  
  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Fedex config page'
    });
  }

  loadCountries() {
    this.countryService.list(-1).subscribe(res => { 
      this.countryList = res.body;
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  loadData() {
    this.storeServices.getSettings('Fedex').subscribe(data => {
      this.initForm(data);
    });
  }

  initForm(data) {
    this.form = this.formBuilder.group({
      key: [data.shipping_fedex_key, Validators.required],
      password: [data.shipping_fedex_password, Validators.required],
      account: [data.shipping_fedex_account, Validators.required],
      meter: [data.shipping_fedex_meter, Validators.required],
      dropoff_type: [data.shipping_fedex_dropoff_type || 'DROP_BOX', Validators.required],
      fedpack_type: [data.shipping_fedex_fedpack_type || 'FEDEX_BOX', Validators.required],
      country_code: [data.shipping_fedex_country_code, Validators.required],
      postcode: [data.shipping_fedex_postcode, Validators.required]
    });
  }

  /**
   * save detail
   */
   save() {

    if(!this.form || !this.form.valid) {
      return false;
    }

    this.saving = true;

    this.shippingService.setConfig("Fedex", this.form.value).subscribe(async response => {

      this.saving = false;

      if(response.operation == 'success') {

        this.dismiss(this.form.value);

        /*this.router.navigate(['/']);
       
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Success'),
          message: response.message,
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();*/

      } else {
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        });
        
        await alert.present();
      }
    });
  }

  dismiss(data = {}) {
    this.modalCtrl.dismiss(data);
  }
}
