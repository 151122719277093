import { Component, OnInit } from '@angular/core';
import {ModalController, PopoverController, ToastController} from '@ionic/angular';
//services
import { ItemsService } from 'src/app/services/logged-in/items.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { CategoryService } from 'src/app/services/category.service';
import { AuthService } from 'src/app/services/auth.service';
//components
import { ItemOptionsComponent } from '../item-options/item-options.component';
import { ItemVariantsComponent } from '../item-variants/item-variants.component';


@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
})
export class ItemsComponent implements OnInit {

  public query: string;

  public items = [];

  public categories = [];

  public category_id: number;

  public perPageCount = 20;

  public currentPage = 0;
  public totalPages = 0;
  public totalCount = 0;

  public loading = false;

  public borderLimit = false;

  constructor(
    public modalCtrl: ModalController,
    public authService: AuthService,
    public translateService: TranslateLabelService,
    public itemServices: ItemsService,
    public categoryService: CategoryService,
    private _toastCtrl: ToastController,
  ) { }

  ngOnInit() {
    this.loadData();
    this.loadCategories();
  }

  /**
   * load item categories
   */
  loadCategories() {
    this.categoryService.listAll().subscribe(response => {
      this.categories = response;
    });
  }

  /**
   * load categories
   */
  loadData() {
    this.loading = true;

    const params = this.getParams();

    this.itemServices.list(1, params).subscribe((response) => {
      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'), 10);
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'), 10);
      this.items = response.body;
    },
      err => this.loading = false,
      () => this.loading = false,
    );
  }

  /**
   * load more on scroll to bottom
   * @param event
   */
   doInfinite(event) {

    if(this.currentPage >= this.totalPages) {
      
      if(event && event.target)
        event.target.complete();
      return null;
    }

    this.loading = true;

    this.currentPage++;

    const params = this.getParams();

    this.itemServices.list(this.currentPage, params).subscribe(response => {
      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));

      this.items = this.items.concat(response.body);

      if(event && event.target)
        event.target.complete();

    }, err => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  /**
   * url query string
   * @returns
   */
  getParams() {
    let params =  '&type=all&expand=currency,itemImage,options,options.extraOptions';

    if(this.category_id) {
      params += '&category_id=' + this.category_id;
    }

    if(this.query && this.query.length > 0) {
      params += '&keyword=' + this.query;
    }

    if(this.perPageCount) {
      params += '&per-page=' + this.perPageCount;
    }

    return params;
  }

  /**
   * search by query
   */
  filter() {
    this.currentPage = 1;
    this.loadData();
  }

  onBackButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dismiss();
  }

  /**
   * dismiss popup
   * @param data
   */
   dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if(o) {
        o.dismiss(data);
      }
    });
  }

  /**
   * filter by category_id
   * @param category_id
   */
  filterByCategory(category_id = null) {
    this.category_id = category_id;
    this.filter();
  }

  /**
   * close with selected item
   * @param item
   */
  async selectItem(item) {
    if (item.stock_qty  == 0) {

      const toast = await this._toastCtrl.create({
        message: this.translateService.transform('Product out of stock'),
        duration: 3000
      });
      toast.present();
      return false;
    }

    if(item.item_type == 2) {
      this.showVariants(item);
    } else if(item.options.length > 0) {
      this.showOptions(item);
    } else {
      this.dismiss({
        item: item
      });
    }
  }

  /**
   * show product options
   */
   async showOptions(item) {

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: ItemOptionsComponent,
      componentProps: {
        item: item
      },
      cssClass: 'popup-modal'
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }

      if (!e.data || !e.data.item) {
        return null;
      }

      setTimeout(() => {
        this.dismiss(e.data);
      }, 200);
    });
  }

  /**
   * show product variants
   */
  async showVariants(item) {

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: ItemVariantsComponent,
      componentProps: {
        item: item
      },
      cssClass: 'popup-modal'
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }

      if (!e.data || !e.data.item) {
        return null;
      }

      setTimeout(() => {
        this.dismiss(e.data);
      }, 200);
    });
  }

  onItemImageError(item) {
    if(item.itemImage)
      item.itemImage.product_file_name = null;
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
