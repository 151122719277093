import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//models
import { DeliveryZone } from 'src/app/model/DeliveryZone';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class OpeningHoursService {

  public endpoint: string = "opening-hours";

  constructor(
    public _authhttp: AuthHttpService,
    public auth: AuthService
  ) { }

  list(): Observable<any> {
    let url = `${this.endpoint}?expand=dayOfWeek&store_uuid=${this.auth.store_id}`;
    return this._authhttp.get(url);
  }

  /**
   * return opening hour detail by day
   * @param opening_hour_id
   * @returns 
   */
  view(opening_hour_id): Observable<any> {
    let url = `${this.endpoint}/${opening_hour_id}/${this.auth.store_id}?expand=dayOfWeek`;
    return this._authhttp.get(url);
  }

  /**
   * update opening hours by day
   * @param model 
   * @returns 
   */
  update(day_of_week, opening_hours): Observable<any> {
    const url = this.endpoint + '/' + day_of_week;
    const params = {
      opening_hours: opening_hours
    }
    return this._authhttp.patch(url, params);
  }
}
