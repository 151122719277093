import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DeliveryZoneStatesPageRoutingModule } from './delivery-zone-states-routing.module';

import { DeliveryZoneStatesPage } from './delivery-zone-states.page';
import { DeliveryZoneTaxModule } from 'src/app/components/delivery-zone-tax/delivery-zone-tax.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    DeliveryZoneTaxModule,
    ComponentsModule,
    TranslateModule.forChild(),
    DeliveryZoneStatesPageRoutingModule
  ],
  declarations: [DeliveryZoneStatesPage]
})
export class DeliveryZoneStatesPageModule {}
