import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { CountryModalDropdownComponent } from './country-modal-dropdown.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    CountryModalDropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule
  ]
})
export class CountryModalDropdownModule { }
