import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VoucherFormPageRoutingModule } from './voucher-form-routing.module';

import { VoucherFormPage } from './voucher-form.page';
 
import {TranslateModule} from "@ngx-translate/core";
import { ComponentsModule } from 'src/app/components/components.module';
import { LoadingModalModule } from 'src/app/components/loading-modal/loading-modal.module';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      VoucherFormPageRoutingModule,
      ComponentsModule,
      ReactiveFormsModule,
      LoadingModalModule,
      TranslateModule.forChild()
    ],
  declarations: [VoucherFormPage]
})
export class VoucherFormPageModule {}
