import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {AlertController, ModalController, ToastController} from "@ionic/angular";
//services
import { OpeningHoursService } from 'src/app/services/logged-in/opening-hours.service';
import { AuthService } from "../../../../../services/auth.service";
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { EventService } from "../../../../../services/event.service";


@Component({
  selector: 'app-opening-hours-form',
  templateUrl: './opening-hours-form.page.html',
  styleUrls: ['./opening-hours-form.page.scss'],
})
export class OpeningHoursFormPage implements OnInit {

  opening_info: any = [];

  form: FormGroup;

  day_of_week: string;

  public saving: boolean = false;

  public loading: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private toastCtrl: ToastController,
    public activatedRoute: ActivatedRoute,
    public translateService: TranslateLabelService,
    public authService: AuthService,
    public modelCtrl: ModalController,
    private openingHourService: OpeningHoursService,
    private eventService: EventService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;

    this.openingHourService.view(this.day_of_week).subscribe(response => {
      this.loading = false;

      this.opening_info = response;

      this.initForm();
    })
  }

  initForm() {
    let formArray = [];

    for (let hours of this.opening_info) {
      formArray.push(this.formBuilder.group({
        open_at: [hours.open_at_am_pm, Validators.required],
        close_at: [hours.close_at_am_pm, Validators.required]
      }));
    }

    if (this.opening_info.length == 0) {
      formArray.push(this.formBuilder.group({
        open_at: [null, Validators.required],
        close_at: [null, Validators.required]
      }));
    }

    this.form = this.formBuilder.group({
      opening_hours: this.formBuilder.array(formArray),
    });
  }

  addNewOpeningHours() {
    let opening_hours = this.form.get('opening_hours') as FormArray;

    opening_hours.push(this.formBuilder.group({
      open_at: [null, Validators.required],
      close_at: [null, Validators.required]
    }));
  }

  removeHours(id) {
    let opening_hours = this.form.get('opening_hours') as FormArray;
    opening_hours.removeAt(id);
  }

  save() {

    if (!this.form || !this.form.valid) {
      return false;
    }
    const oh = this.form.value.opening_hours;
    this.saving = true;

    this.openingHourService.update(this.day_of_week, oh).subscribe(response => {
      this.saving = false;
      if (response.operation == "error") {
        this.authService.showAlert(
          this.translateService.transform('Error saving opening hours'),
          this.translateService.errorMessage(response.message),
          this.form.value
        );  
      } else {
        this.eventService.refreshHours$.next({});
        this.toastCtrl.create({
          message: this.authService.errorMessage(response.message),
          duration: 2000
        }).then(alert => alert.present());
      }

      if (response.operation != "error") {
        this.close({ refresh: true });
      }
    });
  }

  close(data = {}) {
    this.modelCtrl.dismiss(data);
  }

  dayName(i) {
    switch (i) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        break;
    }
  }
}
