import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CreateTapAccountPageRoutingModule } from './create-tap-account-routing.module';

import { CreateTapAccountPage } from './create-tap-account.page';

import {ImageUploadModule} from "../../../../components/image-upload/image-upload.module";
import { TranslateModule } from '@ngx-translate/core';
import { TelInputModule } from 'src/app/components/tel-input/tel-input.module';
import { CountryModalDropdownModule } from 'src/app/components/country-modal-dropdown/country-modal-dropdown.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CreateTapAccountPageRoutingModule,
        TelInputModule,
        ImageUploadModule,
        CountryModalDropdownModule,
        ReactiveFormsModule,
        TranslateModule.forChild()
    ],
  declarations: [CreateTapAccountPage]
})
export class CreateTapAccountPageModule {}
