export class Campaign {
    utm_uuid: string; 
    restaurant_uuid : string; 
    utm_source: string; 
    utm_medium: string; 
    utm_campaign: string; 
    utm_content: string; 
    utm_term: string; 
    created_at: string; 
    updated_at: string;
}