import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ɵɵpipeBind1 } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { debounceTime } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import { Quill  } from 'quill'; 
import * as $ from 'jquery';
// models
import { ItemImage } from 'src/app/model/ItemImage';
import { Category } from 'src/app/model/Category';
import { Item } from 'src/app/model/Item';
import { ItemVariant } from 'src/app/model/item_variant';
import { Option } from 'src/app/model/item_option';
import { ExtraOption } from 'src/app/model/extra_option';
// services
import { ItemsService } from 'src/app/services/logged-in/items.service';
import { CategoryService } from 'src/app/services/category.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { EventService } from "../../../../services/event.service";
import { CameraService } from "../../../../services/camera.service";
import { AwsService } from "../../../../services/aws.service";
import { AuthService } from "../../../../services/auth.service";
//component
import { ItemCategoryPickerComponent } from 'src/app/components/item-category-picker/item-category-picker.component';
//page
import { ItemOptionsFormPage } from "./item-options-form/item-options-form.page";
import { ItemVariantFormPage } from '../item-variant-form/item-variant-form.page';
import { ItemVariantOption } from 'src/app/model/item_variant_option';
import { ItemVideo } from 'src/app/model/item-videos';
import { ItemVideoPage } from '../item-video/item-video.page';


@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.page.html',
  styleUrls: ['./item-form.page.scss'],
})
export class ItemFormPage implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileVariantImage') fileVariantImage: ElementRef;

  @Input() item_id;
  @Input() category_id;
  @Input() item: Item = new Item();
 
  public categories: any[] = [];
  public categoryDetail: Category;
  public borderLimit = false;

  public loading = false;

  public saving = false;
  public images = [];
  public videos = [];

  public imageList = [];

  public isUploading = false;

  public isVImageUploading = false;

  // Used for link generation after upload
  public bucketUrl: string;
  private _bucketUrlTemporary: string;
  private _bucketUrlPermanent: string;

  form: FormGroup;

  @ViewChild('ckeditor') ckeditor;

  public Editor = ClassicEditor;

  public editorConfig;

  public variantImageDestinationForm; 

  constructor(
    private ref: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public platform: Platform,
    public authService: AuthService,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public itemService: ItemsService,
    public categoryService: CategoryService,
    public translateService: TranslateLabelService,
    public toastCtrl: ToastController,
    public eventService: EventService,
    public _actionSheetCtrl: ActionSheetController,
    public _cameraService: CameraService, //todo: not working in android
    public awsService: AwsService,
  ) {
    this._bucketUrlPermanent = this.awsService.permanentBucketUrl + 'photos/';
    this._bucketUrlTemporary = this.awsService.bucketUrl;

    // By Default, use the permanent bucket url
    this.bucketUrl = this._bucketUrlPermanent;
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  ngOnInit() {
    this.editorConfig = {
      toolbar: ['Heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'indent', 'outdent'],
      height: '25em'
    };

    //const editor = new Quill('#editor');
  }

  ionViewWillEnter() {

    if (this.category_id) {
      this.loadCategoriesDetail();
    }

    if (this.item_id) {
      this.loadData();
    } else {
      this.initForm();
    }
  }

  loadCategoriesDetail() {
    this.categories = [];
    this.categoryService.view(this.category_id).subscribe((response) => {
      this.categories.push(response);
    });
  }

  loadData() {
    this.loading = true;

    this.itemService.view(this.item_id).subscribe(response => {
      this.item = response;

      this.categories = [];

      if (this.item.categoryItems && this.item.categoryItems.length > 0) {
        this.item.categoryItems.map(res => {
          this.categories.push(res.category);
        });
      }

      this.initForm();

    },
      err => this.loading = false,
      () => this.loading = false,
    );
  }

  get itemVariants() {
    return this.form.controls.itemVariants as FormArray;
  }

  get options() {
    return this.form.controls.options as FormArray;
  }

  extraOptions(optionForm) {
    return optionForm.controls["extraOptions"] as FormArray;
  }

  /**
   * add extra option  
   */ 
  addExtraOption(optionForm) {

    const extraOptionForm = this.formBuilder.group({
      extra_option_name: [null, Validators.required],
      extra_option_name_ar: [null, Validators.required],
      extra_option_price: [null],
      stock_qty: [null],
    });

    optionForm.controls["extraOptions"].push(extraOptionForm);
  }

  /**
   * remove extra option
   * @param optionIndex 
   * @param index 
   */
  removeExtraOption(optionIndex, index) {
    this.form.controls.itemVariants = this.formBuilder.array([]);

    this.options.controls[optionIndex]['controls']['extraOptions'].removeAt(index);
    this.options.updateValueAndValidity();
    //this.options.markAsDirty();

  }

  /**
   * remove variant
   * @param index 
   */
  removeVariant(index) {
    this.itemVariants.removeAt(index);
    this.itemVariants.markAsDirty();
    this.itemVariants.updateValueAndValidity();
  }

  /**
   * add option
   */
  addOption() {
 
    const extraOptionForm = this.formBuilder.group({
      extra_option_name: [null, Validators.required],
      extra_option_name_ar: [null, Validators.required],
      extra_option_price: [null],
      stock_qty: [null],
    });

    const form = this.formBuilder.group({
      min_qty: [''],
      max_qty: [''],
      option_price: [0],
      is_required: [null],
      sort_number: [0],
      option_type: [null, Validators.required],
      option_name: ['', Validators.required],
      option_name_ar: [''],
      extraOptions: this.formBuilder.array([extraOptionForm]),
    });

    this.options.push(form);
  }

  /**
   * remove option
   * @param index 
   */
  removeOption(index) {

    //remove all variant having this option 

    /*this.itemVariants.value.forEach((itemVariant, itemVariantIndex) => {
      const haveOption = itemVariant.itemVariantOptions.find(itemVariantOption => {
        console.log(itemVariantOption.option.option_name, this.options.controls[index].value.option_name);

        return itemVariantOption.option.option_name == this.options.controls[index].value.option_name;
      });

      if(haveOption) {
        
        this.form.controls['itemVariants'].value = new // .removeAt(itemVariantIndex);

        //this.form.controls['itemVariants'].removeAt(itemVariantIndex);
        //this.itemVariants.removeAt(itemVariantIndex);
        //this.itemVariants.markAsDirty();
      }
    });*/

    this.form.controls.itemVariants = this.formBuilder.array([]);

    this.options.removeAt(index);
    //this.options.markAsDirty();
  }

  onVariantImageSort(event, ctrlItemVariantImages) {
 
    //add to new position 

    ctrlItemVariantImages.value.splice(event.detail.to, 0, ctrlItemVariantImages.value[event.detail.from])
  
    //remove old 

    const indexToDelete = event.detail.to > event.detail.from? event.detail.from: 
      event.detail.from + 1;
       
    const a = ctrlItemVariantImages.value.filter((element, index) => index !== indexToDelete);

    //set sort number 

    let i = 0;

    a.forEach(element => {
      element.sort_number = i;
      i++;
    });
      
    ctrlItemVariantImages.setValue(a);
  
    event.target.complete(true);
  }

  onImageVideoSort(event) {

    let items = this.images.concat(this.videos);
 
    //add to new position 

    items.splice(event.detail.to, 0, items[event.detail.from])
   
    //remove old 

    const indexToDelete = event.detail.to > event.detail.from? event.detail.from: 
      event.detail.from + 1;
       
    items = items.filter((_, index) => index !== indexToDelete);
 
    //set sort number 

    let videos = []; 
    let images = [];
    
    let i = 0;

    items.forEach(element => {

      element.sort_number = i;

      if(element.youtube_video_id) {
        videos.push(element);
      } else {
        images.push(element);
      }

      i++;
    });
 
    this.images = images;
    this.videos = videos;

    event.target.complete(true);
  }

  /**
   * initialize form 
   */
  initForm() {
    this.images = [];

    if (this.item_id) { 

      if (this.item.itemImages && this.item.itemImages.length > 0) {
        for (const image of this.item.itemImages) {
          if (image) {
            this.images.push({
              path: this.awsService.cloudinaryUrl + "c_scale,h_60,w_60/restaurants/" + this.authService.store_id + '/items/',
              name: image.product_file_name,
              sort_number: 0,
              new: 0
            });
          }
        }
      }

      for (const video of this.item.itemVideos) {
      
        this.videos.push({ 
          ...video,
          sort_number: 0,
          thumbnail: "https://img.youtube.com/vi/" + video.youtube_video_id + "/mqdefault.jpg",
        });  
      }
    }

    let optionForm = [];
    let itemVariants = [];

    if (!this.item.options) {
      this.item.options = [];
    }

    if (!this.item.itemVariants) {
      this.item.itemVariants = [];
    }

    this.item.itemVariants.forEach(itemVariant => {

      /*itemVariant.itemVariantOptions.forEach(itemVariantOption => {

        extraOptionForms.push(this.formBuilder.group({
          option_id: [extraOption.extra_option_name, Validators.required],
          extra_option_id: [extraOption.extra_option_name_ar, Validators.required],
        }))
      });*/

      const form = this.formBuilder.group({
        item_variant_uuid: [itemVariant.item_variant_uuid],
        stock_qty: [itemVariant.stock_qty, this.item?.track_quantity ? [Validators.required]:[]],
        sku: [itemVariant.sku],
        barcode: [itemVariant.barcode],
        price: [itemVariant.price, Validators.required],
        compare_at_price: [itemVariant.compare_at_price],
        weight: [itemVariant.weight],
        height: [itemVariant.height],
        width: [itemVariant.width],
        length: [itemVariant.length],
        itemVariantOptions: [itemVariant.itemVariantOptions],
        itemVariantImages: [itemVariant.itemVariantImages || []]
      });

      itemVariants.push(form);
    });

    this.item.options.forEach(option => {

      let extraOptionForms = [];

      option.extraOptions.forEach(extraOption => {

        extraOptionForms.push(this.formBuilder.group({
          extra_option_id: [extraOption.extra_option_id],
          extra_option_name: [extraOption.extra_option_name, Validators.required],
          extra_option_name_ar: [extraOption.extra_option_name_ar, Validators.required],
          extra_option_price: [extraOption.extra_option_price],
          stock_qty: [extraOption.stock_qty],
        }))
      });

      const form = this.formBuilder.group({
        option_id: [option.option_id],
        option_price: [option.option_price],
        min_qty: [option.min_qty],
        max_qty: [option.max_qty],
        is_required: [option.is_required],
        sort_number: [option.sort_number],
        option_type: [option.option_type+'', Validators.required],
        option_name: [option.option_name, Validators.required],
        option_name_ar: [option.option_name_ar],
        extraOptions: this.formBuilder.array(extraOptionForms),
      });

      optionForm.push(form);
    });

    let form = this.formBuilder.group({
      name: [this.item.item_name || '', Validators.required],
      name_ar: [this.item.item_name_ar || '', Validators.required],
      description: [this.item.item_description || ''],
      description_ar: [this.item.item_description_ar || ''],
      meta_title: [this.item.item_meta_title || ''],
      meta_title_ar: [this.item.item_meta_title_ar || ''],
      meta_description: [this.item.item_meta_description],
      meta_description_ar: [this.item.item_meta_description_ar],
      sort_number: [this.item.sort_number || ''],
      prep_time: [this.item.prep_time || '', Validators.min(0)],
      prep_time_unit: [this.item.prep_time_unit ? this.item.prep_time_unit : 'day'],
      price: [this.item.item_price || ''],
      compare_at_price: [this.item.compare_at_price],
      sku: [this.item.sku || ''],
      barcode: [this.item.barcode || ''],
      track_quantity: [this.item.track_quantity || false],
      stock_qty: [this.item.stock_qty || ''],
      item_type: [this.item.item_type? this.item.item_type: 1, Validators.required],
      categories: [this.categories],
      options: this.formBuilder.array(optionForm),
      itemVariants: this.formBuilder.array(itemVariants),
      height: [this.item.height],
      width: [this.item.width],
      length: [this.item.length],
      weight: [this.item.weight],
      shipping: [this.item.shipping || false],
      videos: [],
      images: []
    }, {
          validators: (control: AbstractControl) => {
    
            //for simple product only 
            
            if (control.get('item_type').value != 1) {
              return true;
            }
    
            const compare_at_price = control.get('compare_at_price');
            const price = control.get('price');
    
            if(compare_at_price.value > 0 && 
              compare_at_price.value <= price.value
            ) {
              compare_at_price.setErrors({
                invalid: true
              });
            }
          }
    });

    //for simple product 

    if (form.value.item_type == 1) {
      if (form.value.track_quantity)
        form.controls.stock_qty.setValidators([Validators.required]);

      form.controls.price.setValidators([Validators.required]);
    }

    form.get('item_type').valueChanges.subscribe(val => {

      if (val == 1) {
        form.controls.price.setValidators([Validators.required]);

        if(form.controls.track_quantity.value) {
          form.controls.stock_qty.setValidators([Validators.required]);
        } else {
          form.controls.stock_qty.clearValidators();
        }

        //clear all itemVariants on type change?

        this.form.controls.itemVariants = this.formBuilder.array([]);

        //this.clearFormArray(this.itemVariants);

        //for safety
        this.itemVariants.updateValueAndValidity();

      } else {
        form.controls.price.clearValidators();
        form.controls.stock_qty.clearValidators();
      }

      form.controls.stock_qty.updateValueAndValidity();
      form.controls.price.updateValueAndValidity();
    });

    form.get('track_quantity').valueChanges.subscribe(val => {

      if (val && form.value.item_type == 1) 
      { 
        form.controls.stock_qty.setValidators([Validators.required]);
      } else {
        form.controls.stock_qty.clearValidators();
      } 
       
      form.controls.stock_qty.updateValueAndValidity();
       
      if(val) {
        for(let variantForm of this.itemVariants.controls) {
          variantForm['controls'].stock_qty.setValidators([Validators.required]);
          variantForm['controls'].stock_qty.updateValueAndValidity();
        }
      } else {
        for(let variantForm of this.itemVariants.controls) {
          variantForm['controls'].stock_qty.clearValidators();
          variantForm['controls'].stock_qty.updateValueAndValidity();
        }
      }
    });

    form.get('options').valueChanges.pipe(debounceTime(500)).subscribe(e => {
      
      if(form.value.item_type == 2) {
        
        //this.form.controls.itemVariants = this.formBuilder.array([]);
      
        this.generateVartiants();
      }
    });

    this.form = form;

    this.ref.detectChanges();
  }

  /**
   * clear form array 
   * @param formArray 
   */
  clearFormArray(formArray: FormArray) {
     
    formArray.controls.forEach((form, index) => {
      formArray.removeAt(index);
    }); 
  }

  /**
   * remove option values on option type change to textbox
   * @param optionForm 
   */
  onOptionTypeChange(optionForm) {
    if(optionForm.value.option_type == 3) {
       
      this.clearFormArray(optionForm.controls.extraOptions);
      
      //for safety
      optionForm.controls.extraOptions.updateValueAndValidity();

      optionForm.controls.is_required.setValue(true);
      optionForm.controls.is_required.updateValueAndValidity();
    }
  }
  
  /**
   * update product type 
   * @param event 
   */
  onVariantToggle(event) {

    const value = event.detail.checked ? 2 : 1;

    this.form.controls.item_type.setValue(value);
    this.form.controls.item_type.updateValueAndValidity();

    if(value == 2) {
      this.generateVartiants();
    } /*else {
      this.clearFormArray(this.itemVariants);
    }*/
  }

  /**
   * update item model from form values
   */
  updateModelFromFormValues() {

    this.item.height = this.form.controls.height.value;
    this.item.width = this.form.controls.width.value;
    this.item.length = this.form.controls.length.value;
    this.item.weight = this.form.controls.weight.value;
    this.item.shipping = this.form.controls.shipping.value;

    this.item.item_name = this.form.controls.name.value;
    this.item.item_name_ar = this.form.controls.name_ar.value;
    this.item.item_description = this.form.controls.description.value;
    this.item.item_description_ar = this.form.controls.description_ar.value;

    this.item.item_meta_title = this.form.controls.meta_title.value;
    this.item.item_meta_title_ar = this.form.controls.meta_title_ar.value;
    this.item.item_meta_description = this.form.controls.meta_description.value;
    this.item.item_meta_description_ar = this.form.controls.meta_description_ar.value;

    this.item.prep_time = this.form.controls.prep_time.value;
    this.item.prep_time_unit = this.form.controls.prep_time_unit.value;
    this.item.item_price = this.form.controls.price.value;
    this.item.compare_at_price = this.form.controls.compare_at_price.value;
    this.item.sku = this.form.controls.sku.value;
    this.item.barcode = this.form.controls.barcode.value;
    this.item.track_quantity = this.form.controls.track_quantity.value;
    this.item.stock_qty = this.form.controls.stock_qty.value;
    this.item.sort_number = this.form.controls.sort_number.value;
    this.item.item_type = this.form.controls.item_type.value;

    this.item.itemImages = [];
    this.item.itemCategories = [];

    this.item.options = this.form.controls.options.value;

    this.item.itemVariants = this.form.controls.itemVariants.value;

    this.item.itemCategories = this.categories;

    for (const image of this.images) {
      if (image.name) {
        const images = new ItemImage();
        images.product_file_name = image.name;
        images.sort_number = image.sort_number;
        this.item.itemImages.push(images);
      }
    }

    this.item.itemVideos = this.videos;
  }

  /**
   * save item details
   * @returns
   */
  save() {

    if (!this.form || !this.form.valid) {
      return false;
    }

    this.saving = true;

    this.updateModelFromFormValues();

    let action;

    if (this.item_id) {
      action = this.itemService.update(this.item_id, this.item);
    } else { 
      action = this.itemService.add(this.item);
    }

    action.subscribe(async response => {

      if (response.operation == 'success') {
 
        // this.router.navigate(['/view/item-list']);
        this.toastCtrl.create({
          message: this.translateService.errorMessage(response.message),
          duration: 3000,
        }).then(toast => toast.present());

        this.dismiss(true);

        this.eventService.onboardStepCompleted$.next({
          step: "item-added"
        });

      } else {
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }
    },
      err => this.saving = false,
      () => this.saving = false,
    );
  }

  /**
   * on note editor change
   * @param event
   * @param field
   */
  onChange(event, field) {

    if (!event.editor) {
      return event;
    }

    const data = event.editor.getData();

    if (field == 'en') {
      this.form.controls.description.setValue(data);
    } else {
      this.form.controls.description_ar.setValue(data);
    }
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  addVariant() {
    const variant = new ItemVariant;
    variant.item_uuid = this.item_id;

    this.showVariantForm(variant);
  }

  /**
   * open popup to select option values for new variant 
   * @param variant 
   */
  async showVariantForm(variant) {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: ItemVariantFormPage,
      swipeToClose: true,
      componentProps: {
        model: variant,
        options: this.options.value,
        track_quantity: this.form.controls.track_quantity.value
      }
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if (data && data.model && !data.model.item_variant_uuid) {
 
      const form = this.formBuilder.group({
        item_variant_uuid: [data.model.item_variant_uuid],
        stock_qty: [data.model.stock_qty],
        sku: [data.model.sku],
        barcode: [data.model.barcode],
        price: [data.model.price, Validators.required],
        compare_at_price: [data.model.compare_at_price],
        weight: [data.model.weight],
        height: [data.model.height],
        width: [data.model.width],
        length: [data.model.length],
        itemVariantOptions: [data.model.itemVariantOptions],
        itemVariantImages: [[]]
      });

      this.itemVariants.push(form);
    }
  }

  async selectCategory() {
    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: ItemCategoryPickerComponent,
      cssClass: 'short-modal',
      swipeToClose: true,
      // presentingElement: this.routerOutlet.nativeEl,
      componentProps: {
        selectedCategories: this.categories
      }
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if (data && data.length > 0) {
      this.categories = data;
      this.form.controls.categories.setValue(this.categories);
      this.form.controls.categories.setValidators([Validators.required]);
      this.form.markAsDirty();
      this.form.updateValueAndValidity();
    }
  }

  /**
   * close popup
   * @param refresh 
   */
  dismiss(refresh = false, event = null) {

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss({ refresh });
      }
    });
  }

  counter(i: number) {
    return new Array(i);
  }

  async uploadBtnClicked(event, itemVariantForm = null) {

    // If already uploading, do nothing, just return
    if (this.isUploading || this.isVImageUploading) { return; }

    //   this.alertCtrl.create({
    //     header: this.translateService.transform('Image already exist'),
    //     message: this.translateService.transform('You want to remove old image?'),
    //     buttons: [
    //       {
    //         text: this.translateService.transform('Yes'),
    //         role: 'Yes',
    //         handler: (blah) => {
    //           // if (this.prefix == 'item-image') {
    //           //   this.itemService.deleteImage(this.data, this.value).subscribe(async res => {
    //           //     if (res.operation == 'success') {
    //           //       this.value = null;
    //           //       await this.imageUploadBtn(event);
    //           //     }
    //           //   });
    //           // }
    //           // else
    //           // {
    //           //   //on form submit delete on backend
    //           //
    //           //   this.value = null;
    //           //   this.imageUploadBtn(event);
    //           // }
    //         }
    //       },
    //       {
    //         text: 'No'
    //       }
    //     ]
    //   }).then(alert => alert.present());
    // } else {
    await this.imageUploadBtn(event, itemVariantForm);
    // }
  }

  async imageUploadBtn(event, itemVariantForm = null) {
    /* todo: cordova plugin got broken so bypassing 
    
    if (this.platform.is('cordova')) {
      // Display action sheet giving user option of camera vs local filesystem.
      const actionSheet = await this._actionSheetCtrl.create({
        header: this.translateService.transform('Select image source'),
        buttons: [
          {
            text: this.translateService.transform('Load from Library'),
            handler: () => {
              this._cameraService.getImageFromLibrary().then((nativeImageFilePath) => {
                // Upload and process for progress
                this.uploadFileViaNativeFilePath(nativeImageFilePath, itemVariantForm);
              }, (err) => {
                // Error getting picture
                console.log('Error getting picture from Library: ' + JSON.stringify(err));
              });
            }
          },
          {
            text: 'Use Camera',
            handler: () => {
              this._cameraService.getImageFromCamera().then((nativeImageFilePath) => {
                // Upload and process for progress
                this.uploadFileViaNativeFilePath(nativeImageFilePath, itemVariantForm);
              }, (err) => {
                // Error getting picture
                // alert("Error getting picture from Camera: " + JSON.stringify(err));
                console.log('Error getting picture from Camera: ' + JSON.stringify(err));
              });
            }
          }
        ]
      });
      actionSheet.present();
    
    } else */
    
    if(itemVariantForm) {
      this.variantImageDestinationForm = itemVariantForm;
      this.fileVariantImage.nativeElement.click();
    } else {
      // Trigger click event on regular HTML file input
      this.fileInput.nativeElement.click();    // And also this with @ViewChild
    }
  }

  uploadVariantImageViaHtmlFileInput(event) {
 
    const fileList: FileList = event.target.files;

    // Check if files available
    if (fileList.length > 0) {
      const file = fileList.item(0);

      // Upload The File
      const uploadObservable = this.awsService.uploadFile(file);
      this.processFileUpload(uploadObservable, this.variantImageDestinationForm);
    }
  }

  /**
   * Upload the selected file through regular HTML file input
   * This method will only be called if the target is not a cordova app.
   * @param  {any} $event
   */
  uploadFileViaHtmlFileInput($event, itemVariantForm = null) {

    const fileList: FileList = $event.target.files;

    // Check if files available
    if (fileList.length > 0) {
      const file = fileList.item(0);

      // Upload The File
      const uploadObservable = this.awsService.uploadFile(file);
      this.processFileUpload(uploadObservable, itemVariantForm);
    }
  }

  /**
   * Upload the selected file through regular HTML file input
   * This method will only be called if the target is not a cordova app.
   * @param  {any} path
   */
  uploadFileViaNativeFilePath(path, itemVariantForm = null) {

    // Upload and process for progress
    this.awsService.uploadNativePath(path)
      .then((uploadObservable) => {
        this.processFileUpload(uploadObservable, itemVariantForm);
      })
      .catch((err) => {
        alert(err);
      });
  }

  /**
   * Process S3 upload by subscribing to progress observable
   * @param  {} uploadObservable
   */
  processFileUpload(uploadObservable, itemVariantForm = null) {

    // Create Temporary Transfer Record
    const newUpload = {
      name: this.translateService.transform('Preparing file for upload'),
      status: 'uploading',
      loaded: 0,
      total: 100,
      link: ''
    };

    // Show File Upload Indicator based on which file is being uploaded
    if(itemVariantForm) {
      this.isVImageUploading = true;
    } else {
      this.isUploading = true;
    }
    
    // Process Upload and Display Progress
    uploadObservable.subscribe((progress) => {

      // Update progress, possibly create emitter for this data if needed
      if (progress.loaded && progress.loaded != progress.total) {
        newUpload.status = 'uploading';
        newUpload.loaded = progress.loaded;
        newUpload.total = progress.total;
      }
      // If Multipart upload (big file), Key with capital "K"
      if (progress.key || progress.Key) {
        newUpload.name = progress.key ? progress.key : progress.Key;
        newUpload.link = this._bucketUrlTemporary + newUpload.name;
      }
    }, (err) => {
      console.error('Error', err);
      newUpload.status = 'error';
      // Hide File Upload Indicator based on which file is being uploaded
      this.isUploading = false;
      this.isVImageUploading = false;
      
    }, () => {
      newUpload.status = 'complete';
      // Hide File Upload Indicator based on which file is being uploaded
      this.isUploading = false;
      this.isVImageUploading = false;

      // Switch to temporary bucket url
      this.bucketUrl = this._bucketUrlTemporary;

      // Set the new value of this file upload
      if(itemVariantForm) {

        const values = itemVariantForm.controls.itemVariantImages.value; 

        values.push({
          path: this.bucketUrl,
          product_file_name: newUpload.name,
          sort_number: 0,
          new: 1
        });

        itemVariantForm.controls.itemVariantImages.setValue(values);
        itemVariantForm.controls.itemVariantImages.markAsDirty();
        itemVariantForm.controls.itemVariantImages.updateValueAndValidity();

        this.variantImageDestinationForm = null;//resetting 
      }
      else 
      {
        this.images.push({
          path: this.bucketUrl,
          name: newUpload.name,
          new: 1
        });
      }
    });
  }

  async addVideo(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    let itemVideo = new ItemVideo;
    itemVideo.item_uuid = this.item.item_uuid;
    
    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: ItemVideoPage,
      swipeToClose: true,
      componentProps: {
        model: itemVideo,
      }
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if (data && data.model && data.model.youtube_video_id) {
      this.videos.push({
        ...data.model,
        sort_number: 0,
        new: 1,
        thumbnail: "https://img.youtube.com/vi/" + data.model.youtube_video_id + "/mqdefault.jpg",
      });
    }
  }

  onImgError() {
    return null;
  }

  removeVariantImage(event, itemVariantForm, index) {
    event.stopPropagation();
    event.preventDefault();
    
    const imageToDelete = itemVariantForm.value.itemVariantImages[index];

    if (!imageToDelete.new) {
      this.itemService.deleteVariantImage(this.item, imageToDelete.product_file_name).subscribe();
    }  
 
    const images = itemVariantForm.value.itemVariantImages.filter(res => res.product_file_name != imageToDelete.product_file_name);
    
    itemVariantForm['controls']['itemVariantImages'].setValue(images);
    itemVariantForm['controls']['itemVariantImages'].updateValueAndValidity();
  }

  removeVideo(event, video) {

    event.stopPropagation();
    event.preventDefault();

    if (!video.new) {
      this.itemService.deleteVideo(video.item_video_id ).subscribe(async res => {
        if (res.operation == 'success') {
          this.videos = this.videos.filter(res => res.item_video_id != video.item_video_id);
        }
      });
    } else {
      this.videos = this.videos.filter(res => res.item_video_id != video.item_video_id);
    }
  }

  removeImage(event, img) {
    event.stopPropagation();
    event.preventDefault();

    if (!img.new) {
      this.itemService.deleteImage(this.item, img.name).subscribe(async res => {
        if (res.operation == 'success') {
          this.images = this.images.filter(res => res.name != img.name);
        }
      });
    } else {
      this.images = this.images.filter(res => res.name != img.name);
    }
  }

  removeCat(event, category) {
    event.stopPropagation();
    event.preventDefault();
    this.categories = this.categories.filter(res => res.title != category.title);
    this.form.controls.categories.setValidators([Validators.required]);
    this.form.controls.categories.setValue(this.categories);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  filterNumber(event) {
    return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)
  }

  /**
   * generate variants from given set
   * @param itemVariantOptions 
   * @param options 
   */
  generateVartiants(itemVariantOptions = [], options = null) {

    if (!options) {

      //if option have error 

      if(this.options.status == "INVALID")
      {
        return false;
        /*
        return this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.transform("Please check option form for errors"),
          buttons: [this.translateService.transform('Okay')]
        }).then((alert) => {
          alert.present();
        });*/
      }
      
      options = this.options.value.filter(option => {
        return option.option_type != 3;
      });
    }
  
    //if final set 

    if (options.length == 0) {

      const allOptions = this.options.value.filter(option => {
        return option.option_type != 3;
      });

      //if last option 

      if (itemVariantOptions.length == allOptions.length) {

        let form = this.checkIfAlreadyAdded(itemVariantOptions)
 
        if(form) {
          
          form.setValue({
            stock_qty: form.value.stock_qty,
            sku: form.value.sku,
            barcode: form.value.barcode,
            price: form.value.price,
            compare_at_price: form.value.compare_at_price,
            weight: form.value.weight,
            itemVariantOptions: itemVariantOptions,
            itemVariantImages: form.value.itemVariantImages
          });
          /*form = this.formBuilder.group({
            stock_qty: [form.value.stock_qty],
            sku: [form.value.sku],
            barcode: [form.value.barcode],
            price: [form.value.price, Validators.required],
            compare_at_price: [form.value.compare_at_price],
            weight: [form.value.weight],
            itemVariantOptions: [itemVariantOptions],
            itemVariantImages: [form.value.itemVariantImages]
          }*/
  
        } else {

          form = this.formBuilder.group({
            stock_qty: [1],
            sku: [''],
            barcode: [''],
            price: [this.form.value.price, Validators.required],
            compare_at_price: [this.form.value.compare_at_price],
            weight: [this.form.value.weight],
            height: [this.form.value.height],
            width: [this.form.value.width],
            length: [this.form.value.length],
            itemVariantOptions: [itemVariantOptions],
            itemVariantImages: [[]]
          });

          this.itemVariants.push(form);       
        }
      }

      return itemVariantOptions;
    }

    let i = 1;

    for (let option of options) {
 
      var ro = options.slice(i);

      for (let value of option.extraOptions) {
 
        let itemVariantOption = new ItemVariantOption;
        itemVariantOption.option = option; 
        itemVariantOption.extraOption = value; 
        itemVariantOption.option_id = option.option_id;
        itemVariantOption.extra_option_id = value.extra_option_id;

        let ns = itemVariantOptions.slice();
        ns.push(itemVariantOption);

        //if(!ro) {
        //    variations.push(ns);
        //} else {
        this.generateVartiants(ns, ro);
        //}
      }

      //if first time, iterate throw first option only

      if (itemVariantOptions.length == 0)
        return;

      i++;
    }

    //if no more options 

    //variations.push(extraOptions);

    //return extraOptions;        
  }

  /**
   * check if variant already added 
   * @param itemVariantOptions 
   */
  checkIfAlreadyAdded(itemVariantOptions) {
    
    for(let variant of this.itemVariants.controls) {

      if(this.isSameOptions(variant['controls']['itemVariantOptions'].value, itemVariantOptions)) {
        return variant;
      }
    }

    return false;
  }

  /**
   * check if same variant 
   * @param formValue 
   * @param itemVariantOptions 
   * @returns 
   */
  isSameOptions(formValue, itemVariantOptions) {
    
    /**
     * what if new variant have more option 
     * 
     * what 
     */

    /**
     * if new variant have less option then existing one 
     */
    if(itemVariantOptions.length < formValue.length) {
      // add more option + variant?

      //remove? 


    }

    //options not matching 

    if(itemVariantOptions.length != formValue.length) {
      return false;
    }

    for(let itemVariantOption of itemVariantOptions) {

      let found = false; 

      for(let formOption of formValue) 
      {
        if (
          (formOption.extra_option_id && itemVariantOption.extra_option_id && formOption.extra_option_id == itemVariantOption.extra_option_id) || 
          formOption.extraOption.extra_option_name == itemVariantOption.extraOption.extra_option_name 
        ) {
          found = true; 
        }
      }

      //if variant option not in variant form 

      if(!found) {
        return false;
      }
    }

    //if all checked and no missing found 

    return true;
  }
}
