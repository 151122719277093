import { Component, Input, OnInit } from '@angular/core';
import { NavController, Platform, PopoverController } from '@ionic/angular';
//pages
import { HeaderProfilePopupPage } from 'src/app/modals/header-profile-popup/header-profile-popup.page';
//services
import { AuthService } from 'src/app/services/auth.service';
import { AccountsService } from 'src/app/services/logged-in/accounts.service';
import { SettingsService } from 'src/app/services/logged-in/settings.service';
import { TranslateLabelService } from "../../../services/translate-label.service";
import { EventService } from "../../../services/event.service";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  lang: string = "English"

  @Input() title: string = '';

  constructor(
    public plt: Platform,
    public authService: AuthService,
    public _accountservices: AccountsService,
    public _settingservice: SettingsService,
    public translateService: TranslateLabelService,
    public eventService: EventService,
    public popoverController: PopoverController,
    public navCtrl: NavController
  ) { }

  async ngOnInit() {
  }

  async changeLanguage() {
    this.eventService.setLanguagePref$.next((this.authService.language.code == 'en') ? 'ar' : 'en');
    this.navCtrl.navigateRoot(['/']);
  }

  async profilePopup(ev) {
    
    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const popover = await this.popoverController.create({
      component: HeaderProfilePopupPage,
      animated: true,
      cssClass: 'profile-popover',
      mode: 'md',
      event: ev,
      translucent: true
    });
    
    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    
    return await popover.present();
  }

}
