import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as ApexCharts from 'apexcharts';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';


declare var document: any;

@Component({
  selector: 'chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss'],
})
export class ChartCardComponent {

  categories = [];
  seriesData = [];

  @Input() color: string;
  @Input() set chartdata(value) {

    this.categories = [];
    this.seriesData = [];

    for (let row of value) {
      this.categories.push(row.month ? row.month : row.day);
      this.seriesData.push(row.total);
    }

    if(this.chart) {
      this.setData(this.seriesData, this.categories);
    }
  }

  @Input() title: string;
  @Input() label: string;
  @Input() subtitle: string;
  @Input() number: string;
  @Input() icon: string;
  @Input() iconBg: string;
  @Input() id: string;
  @Input() currency_code: string;

  @Output() onIntervalChange: EventEmitter<any> = new EventEmitter();

  public chart;

  public chartOptions: any;

  constructor(
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {

    let tooltip;

    if (this.currency_code) {
      tooltip = {
        y: {
          formatter: (val) => {
            return val + ' ' + this.currency_code;
          }
        },
        x: {
          show: false,
        }
      };
    } else {
      tooltip = {
        y: {
          formatter: (val) => {
            return val;
          }
        },
        x: {
          show: false,
        }
      }
    }

    this.chartOptions = {
      chart: {
        height: 100,
        type: 'area',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0
          }
        },
      },
      colors: [this.color],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2.5
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.7,
          opacityTo: 0.5,
          stops: [0, 80, 100]
        }
      },
      series: [{
        name: this.translateService.transform(this.label),
        data: this.seriesData,
      }],
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        categories: this.categories
      },
      yaxis: [{
        y: 0,
        offsetX: 0,
        offsetY: 0,
        padding: { left: 0, right: 0 },
      }],
      tooltip: tooltip
    };

    setTimeout(() => {
      this.chart = new ApexCharts(document.querySelector(`#${this.id}-chart`), this.chartOptions);
      this.chart.render();
    }, 50);
  }

  /**
   * update chart data
   * @param data
   * @param categories
   */
  setData(data, categories) {
    this.chart.opts.series[0].data = data;
    this.chart.opts.xaxis.categories = categories;

    this.chart.update();
  }

  /**
   * update cahrt data on interval change
   * @param event
   *
  onIntervalChange(event) {

  }*/
}
