import { Injectable } from '@angular/core'; 
import { AuthHttpService } from './authhttp.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PageService {
 
  constructor( 
    private http: AuthHttpService
  ) { }

  list(page: number = 1, keyword, pageSize = 20): Observable<any> {
    let url = `pages?page=${page}&per-page=${pageSize}`;
    if (keyword) {
      url += `&keyword=${keyword}`;
    }
    return this.http.getRaw(url);
  }

  /**
   * detail API
   * @param page_uuid
   */
  view(page_uuid): Observable<any> {
    return this.http.get(`pages/${page_uuid}`);
  }

  /**
   * create api
   * @param data
   */
  create(param: any): Observable<any> {
    return this.http.post(`pages`, param);
  }

  /**
   * update API
   * @param param
   * @param page_uuid
   */
  update(param: any, page_uuid: string): Observable<any> {
    return this.http.patch(`pages/${page_uuid}`, param);
  }

  /**
   * update API
   * @param ID
   */
  remove(page_uuid): Observable<any> {
    return this.http.delete(`pages/${page_uuid}`);
  }
}
