import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
//pages
import { CustomerFormPage } from 'src/app/pages/logged-in/customer/customer-form/customer-form.page';
//services
import { CustomerService } from 'src/app/services/logged-in/customer.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {

  public customers = [];

  public perPageCount = 20;
  public currentPage = 1;
  public totalPages = 0;
  public totalCount = 0;

  public search: string = null;

  public loading: boolean = false;

  public borderLimit: boolean = false; 

  constructor(
    public modalCtrl: ModalController,
    public translateService: TranslateLabelService,
    public customerService: CustomerService
  ) { }

  ngOnInit() {
    this.loadData();
  }
  
  /**
   * load customers
   */
  loadData() {
    this.customerService.list(this.currentPage, this.search, this.perPageCount).subscribe(response => {
      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));
      this.customers = response.body;
    },
    error => this.loading = false,
    () => this.loading = false,
    );
  }

  /**
   * load more on scroll to bottom
   * @param event 
   */
  doInfinite(event) {

    if(this.currentPage >= this.totalPages) {
      
      if(event && event.target)
        event.target.complete();
      return null;
    }
    
    this.loading = true;

    this.currentPage++;

    this.customerService.list(this.currentPage, this.search, this.perPageCount).subscribe(response => {
      
      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));

      this.customers = this.customers.concat(response.body);

      if(event && event.target)
        event.target.complete();

    }, err => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
  
  /**
   * return name initial for profile photo placeholder 
   */
  getInitials(customer) {
    const name = customer.customer_name;
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  /**
   * search by query
   */
  filter() {
    this.currentPage = 1;
    this.loadData();
  }

  /**
   * dismiss/close with customer details
   * @param customer 
   */
  selectCustomer(customer) {
    this.dismiss({
      customer: customer
    });
  }

  /**
   * dismiss/close with guest name
   */
  guestCheckout() {
    this.dismiss({
      customer_name: this.search
    });
  }

  onBackButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dismiss();
  }

  /**
   * dismiss popup
   * @param data 
   */
  dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if(o) {
        o.dismiss(data);
      }
    });
  }

  /**
   * open popup to add new customer
   */
  async addCustomer() {

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: CustomerFormPage,
      cssClass: 'popup-modal'
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
      
      if (!e.data || !e.data.customer) {
        return null;
      }

      setTimeout(() => {
        this.dismiss(e.data);
      }, 200);
    });
  } 

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
