import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ShippingToPageRoutingModule } from './shipping-to-routing.module';

import { ShippingToPage } from './shipping-to.page';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    IonicModule,
    //RouterModule.forChild(routes)
    //ShippingToPageRoutingModule
  ],
  entryComponents: [ShippingToPage],
  declarations: [ShippingToPage]
})
export class ShippingToPageModule { }
