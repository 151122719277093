import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { CategoryActionComponent } from './category-action';

@NgModule({
  declarations: [CategoryActionComponent],
  entryComponents:[
    CategoryActionComponent
  ],
  imports: [
    TranslateModule.forChild(),
    IonicModule
  ],
  exports: [CategoryActionComponent]
})
export class CategoryActionModule { }
