import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

/**
 * Display alert message to update app on new version availability
 */
@Component({
  selector: 'app-guidance',
  templateUrl: './app-guidance.component.html',
  styleUrls: ['./app-guidance.component.scss'],
})
export class AppGuidanceComponent implements OnInit {

  @Output() onRefresh: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor(
    public modalCtrl: ModalController
  ) {
  }

  ngOnInit() { }

  /**
   * Reload app
   */
  refresh() {
    this.onRefresh.emit();
  }

  /**
   * close update prompt
   */
  close(event) {

    this.modalCtrl.getTop().then(o => {
      if (o) {

        event.preventDefault();
        event.stopPropagation();

        o.dismiss();
      }
    })
  }
}
