
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { SunmiDevice } from 'plugn-device';
import { Observable } from 'rxjs';
//services
import { AuthService } from './auth.service';


@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    AGENT_ROLE_OWNER = 1;
    AGENT_ROLE_STAFF = 2;
    AGENT_ROLE_BRANCH_MANAGER = 3;

    constructor(
        private _authService: AuthService, 
        private platform: Platform, 
        private navCtrl: NavController
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


        if (!this._authService.store_id) {
            
            this.navCtrl.navigateRoot(['store-create']);
            return false;
        } else if (this._authService.role != this.AGENT_ROLE_OWNER) {
            
            this.navCtrl.navigateRoot(['view/orders']);
            return false;
        } else {
            
            SunmiDevice.getPrinterVersion().then(result => {
    
                if(( result['value'] && this.platform.is('capacitor') )){
                    this.navCtrl.navigateRoot(['view/orders']);
                    return false;
                } else {
                    return true;
                }
            });

        }

        return true;
    }
}
