import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class PlanService {

  public endpoint: string = "plans";

  constructor(
    public _authhttp: AuthHttpService
  ) { }
 
  /**
   * return plan detail
   * @param plan_id 
   * @returns 
   */ 
  view(plan_id): Observable<any> {
    let url = `${this.endpoint}/${plan_id}?expand=formatedPrice,paymentMethods`;
    return this._authhttp.get(url);
  }

  /**
   * get apple pay parameters to initialize apple pay button 
   * @param plan_id 
   * @returns 
   */
  getApplePayParams(plan_id, currency) : Observable<any> {
    let url = `${this.endpoint}/apple-pay-params/${plan_id}?currency=${currency}`;
    return this._authhttp.get(url);
  }

  /**
   * confirm plan id
   * @param params 
   * @returns 
   */
  confirm(params) : Observable<any> {
    let url = `${this.endpoint}/confirm`;
    return this._authhttp.post(url, params);
  }
}
