import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// services
import { AuthService } from 'src/app/services/auth.service';
import { AuthHttpService } from 'src/app/services/logged-in/authhttp.service';



@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  listAll(): Observable<any> {
    return this.http.getRaw(`bank-discount?expand=bank`);
  }

  list(page: number = 1) : Observable<any>{
    return this.http.getRaw(`bank-discount?page=${page}&expand=bank`);
  }

  /**
   * voucher detail page
   * @param discountID
   */
  view(discountID: number = null): Observable<any>  {
    return this.http.get(`bank-discount/detail?expand=bank&bank_discount_id=${discountID}`);
  }

  /**
   * create bank discount
   * @param bankDiscount
   */
  create(bankDiscount: any): Observable<any> {

    const param = {
      ...bankDiscount, store_uuid : this.authService.store_id
    };
    return this.http.post(`staff/create`, param);
  }


  /**
   * update request
   * @param bankDiscount
   * @param bank_discount_id
   */
  update(bankDiscount: any, bank_discount_id): Observable<any> {

    const param = {
      ...bankDiscount
    };
    return this.http.post(`staff/update?bank_discount_id=${bank_discount_id}`, param);
  }

  /**
   * remove staff from store if not owner
   * @param assignment_id 
   * @returns 
   */
  remove(assignment_id): Observable<any> {
    return this.http.delete(`staff/${assignment_id}/${this.authService.store_id}`);
  }
}
