import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  private _endpoint = 'currencies';

  constructor(
    private _authhttp: AuthHttpService
  ) { }

  /**
   * list all currencies 
   * @param page 
   * @param query 
   * @returns 
   */
  list(page: number = 1, query = ''): Observable<any> {
    let url = `${this._endpoint}?page=${page}`;

    if(query) {
      url += `&keyword=${query}`;
    }

    return this._authhttp.getRaw(url);
  }
  
  /**
   * list all currencies 
   * @param page 
   * @param query 
   * @returns 
   */
  listStoreCurrencies(page: number = 1, query = ''): Observable<any> {
    let url = `${this._endpoint}/store-currencies?page=${page}`;

    if(query) {
      url += `&keyword=${query}`;
    }

    return this._authhttp.getRaw(url);
  }

  /**
   * Update store currencies
   * @param params 
   * @returns 
   */
  update(params): Observable<any> {

    const url = `${this._endpoint}`;

    return this._authhttp.post(url, {
      currencies: params.currencies,
      currency_id: params.currency_id
    });
  }
}
