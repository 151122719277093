import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EventService {

  public userLogout$ = new Subject();
  public userLogin$ = new Subject();
  public userLogined$ = new Subject();
  public userLoggedOut$ = new Subject();
  public internetOffline$ = new Subject();

  //todo: implement
  public internetOnline$= new Subject();

  public error400$ = new Subject();
  public error404$ = new Subject();
  public error500$ = new Subject();
  public errorStorage$ = new Subject();
  public setLanguagePref$ = new Subject();
  public printInvoice$ = new Subject();
  public errorBlocked$ = new Subject();

  public newOrder$ = new Subject();
  public updatedOrder$ = new Subject();
  public deletedOrder$ = new Subject();
  public refundOrder$ = new Subject();

  public refreshStoreList$ = new Subject();
  public storeCreated$ = new Subject();
  public storeUnAssigned$ = new Subject();
  public storeUpdated$ = new Subject();
  public noStoreFound$ = new Subject();
  public storeChanged$ = new Subject();
  public accountAssignmentRemoved$ = new Subject();

  public refreshCategories$ = new Subject();

  public voucherDeleted$ = new Subject();
  public discountDeleted$ = new Subject();

  public refreshVouchers$ = new Subject();
  public refreshDiscount$ = new Subject();

  public setOneSignalSubscription$ = new Subject();
  public setOneSignal$ = new Subject();

  public userUpdated$ = new Subject();

  public deliveryAreasUpdated$ = new Subject();
  public deliveryZoneDeleted$ = new Subject();
   
  public businessLocationReload$ = new Subject();
  public editBusinessZone$ = new Subject();
  public refreshHours$ = new Subject();
  public removeEvent$ = new Subject();
  public reloadStock$ = new Subject();
  public imgAdded$ = new Subject();
  public agentReload$ = new Subject();

  public refreshCampaign$ = new Subject();

  public googleLoginFinished$ = new Subject();
  public locationUpdated$ = new Subject();

  public tapQueueCreated$ = new Subject();
 
  public onboardStepCompleted$ = new Subject();

}
