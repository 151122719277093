import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services 
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class ShippingMethodService {

  private _endpoint = 'shipping-method';

  constructor(
    private _authhttp: AuthHttpService
  ) { }

  disable(code): Observable<any> {
    const url = `${this._endpoint}/disable/${code}`;
    return this._authhttp.delete(url);
  }

  /**
   * Update shipping method config
   * @param params 
   * @returns 
   */
  setConfig(code, params): Observable<any> {

    const url = `${this._endpoint}/config/${code}`;

    return this._authhttp.post(url, params);
  }
}
