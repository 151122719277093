import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { TelCountryPickerComponent } from './tel-country-picker/tel-country-picker.component';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-tel-input',
  templateUrl: './tel-input.component.html',
  styleUrls: ['./tel-input.component.scss'],
})
export class TelInputComponent implements OnInit {

  @Input() country_code = 965;
  @Input() phone_number;
  @Input() externalCss: boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private _auth: AuthService,
    public eventService: EventService,
    public translateService: TranslateLabelService,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public platform: Platform
  ) { }

  ngOnInit() { 
    if(!this.country_code) {
      //this.country_code = 965;

      if (this._auth.currentLocation && this._auth.currentLocation.country_code) {
        this.country_code = this._auth.currentLocation.location.calling_code;
        //this.country_code = this._auth.currentLocation.country_code;
      } 
      /*else {
          this._auth.locate().subscribe(resp => {
              //const countryCode = resp ? resp.country_code : 'US';
              this.country_code = resp ? resp.location.calling_code : 965; 
          });
      }*/

      this.eventService.locationUpdated$.subscribe(() => {
        if(this._auth.currentLocation)
          this.country_code = this._auth.currentLocation.location.calling_code;
      });
    }
  }

  async openPopover(event) {
    
    event.preventDefault();
    event.stopPropagation(); 
    
    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    let modal;
    
    if(this.platform.is('desktop')) {
      modal = await this.popoverCtrl.create({
        component: TelCountryPickerComponent,
        //alignment: "bottom", 
       // size: "cover",
       // triggerAction: 'context-menu',
       // reference: "event",
        cssClass: 'tel-input',
        componentProps: {
          country_code: this.country_code
        },
        showBackdrop: false,
        event: event
      });
    } else {
      modal = await this.modalCtrl.create({
        component: TelCountryPickerComponent,
        componentProps: {
          country_code: this.country_code
        },
      });
    }

    modal.present();
    modal.onDidDismiss().then(e => {
       
      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
      
      if(e.data && e.data.country) {
        
        this.country_code = e.data.country[1];

        this.onChange.emit({
          phone_number: this.phone_number, 
          country_code: this.country_code
        });
      }
    });
  }

  setPhoneNumber(event) {
    this.phone_number = event.target.value;
    this.onChange.emit({
      phone_number: this.phone_number, 
      country_code: this.country_code
    });
  }
}
