import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
//components
import { LocationPickerComponent } from 'src/app/components/location-picker/location-picker.component';
import { MapLocationPickerComponent } from 'src/app/components/map-location-picker/map-location-picker.component';
// models
import { BusinessLocation } from 'src/app/interfaces/business_location';
import { countries } from 'src/app/interfaces/countries';
//services
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/logged-in/settings.service';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { BusinessLocationService } from 'src/app/services/logged-in/business-location.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { EventService } from "../../../../../services/event.service";


@Component({
  selector: 'app-add-business-location',
  templateUrl: './add-business-location.page.html',
  styleUrls: ['./add-business-location.page.scss'],
})
export class AddBusinessLocationPage implements OnInit {

  form: FormGroup;

  @Input() business_location: BusinessLocation;

  @Input() business_id: string;

  countries = countries;
 
  public loading = false;

  public saving = false;

  location = 'Kuwait,City';

  googleAPIKey;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public activatedRoute: ActivatedRoute,
    public translateService: TranslateLabelService,
    public settingService: SettingsService,
    public storeService: StoreService,
    public authService: AuthService,
    public eventService: EventService,
    public businessLocationService: BusinessLocationService,
    public loadingCtrl: LoadingController
  ) {
  }

  ngOnInit() {

    this.googleAPIKey = environment.googleAPIKey;
    
    if (this.business_id) {
      this.loadData();
    } else {
      this.business_location = new BusinessLocation;
      this.business_location.business_location_id = this.business_id;
      this.business_location.store_uuid = this.authService.store_id;
      this.initForm();
    }

    // load rastaurant
    
    /*if (!this.business_id) {
      if (this.authService.store.country_id == 12) { // Bahrain
        this.location = 'Manama';
      }
      else if (this.authService.store.country_id == 129) { // KSA
        this.location = 'Riyadh';
      }
    }*/

    // load countries
    this.loadCountries();
  }

  loadCountries() {
    this.settingService.get_country().subscribe(data => {
      this.countries = data;
    });
  }

  loadData() {

    if(!this.business_id) {
      return null;
    }
    
    this.loading = true;

    this.businessLocationService.view(this.business_id).subscribe(location => {

      this.loading = false;

      this.business_location = location;

      if (this.business_location.latitude && this.business_location.longitude) {
        this.location = this.business_location.latitude + ',' + this.business_location.longitude;
      } else {
        if (this.business_location.country_id == 12) { // Bahrain
          this.location = 'Manama';
        }
        else if (this.business_location.country_id == 129) { // KSA
          this.location = 'Riyadh';
        }
      }

      this.initForm();
    }, () => {
      this.loading = false;
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      business_location_name: [this.business_location.business_location_name, Validators.required],
      business_location_name_ar: [this.business_location.business_location_name_ar, Validators.required],
      country_id: [this.business_location.country_id, Validators.required],
      country: [(this.business_location.country) ? this.business_location.country.country_name : null, Validators.required],
      latitude: [this.business_location.latitude],//Validators.required
      longitude: [this.business_location.longitude],//Validators.required
      address: [this.business_location.address], // Validators.compose([Validators.required])
      armada_api_key: [this.business_location.armada_api_key], // Validators.compose([Validators.required])
      mashkor_branch_id: [this.business_location.mashkor_branch_id], // , Validators.compose([Validators.required])
      max_num_orders: [this.business_location.max_num_orders], // , Validators.compose([Validators.required])
    });
  }

  updateModelFromFormValues() {
    this.business_location.business_location_name = this.form.value.business_location_name;
    this.business_location.business_location_name_ar = this.form.value.business_location_name_ar;
    this.business_location.country_id = this.form.value.country_id;

    this.business_location.latitude = this.form.value.latitude;
    this.business_location.longitude = this.form.value.longitude;
    this.business_location.address = this.form.value.address;
    this.business_location.armada_api_key = this.form.value.armada_api_key;
    this.business_location.mashkor_branch_id = this.form.value.mashkor_branch_id;
    this.business_location.max_num_orders = this.form.value.max_num_orders;
  }

  /**
   * save location detail
   */
  save() {

    if (!this.form || !this.form.valid) {
      return false;
    }

    this.saving = true;

    this.updateModelFromFormValues();

    let action;

    if (this.business_id) {
      action = this.businessLocationService.update(this.business_id, this.business_location);
    } else {
      action = this.businessLocationService.add(this.business_location);
    }

    action.subscribe(async response => {

      this.saving = false;

      if (response.operation == 'success') {

        // this.router.navigate(['settings/business-locations']);
        this.eventService.businessLocationReload$.next({});
        this.dismiss(true);

      } else {
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }
    });
  }

  /**
   * open google map to select location
   */
  async selectLocation() {
    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: LocationPickerComponent,
      cssClass : 'country-modal',
      componentProps: {
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        location: this.location
      }
    });
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      this.form.controls.country_id.setValue(data.country_id);
      this.form.controls.country_id.markAsDirty();

      this.form.controls.country.setValue(data.country_name);
      this.form.controls.country.markAsDirty();
      //
      // this.form.controls.latitude.setValue(data.latitude);
      // this.form.controls.longitude.setValue(data.longitude);
    }
  }

  /**
   * open google map to select location
   */
  async selectMapLocation() {
    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: MapLocationPickerComponent,
      componentProps: {
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        location: this.location
      }
    });
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data && data.latitude) {
      this.form.controls.latitude.setValue(data.latitude);
      this.form.controls.longitude.setValue(data.longitude);
      this.location = this.form.value.latitude + ',' + this.form.value.longitude;
    }
  }

  /**
   * close popup if any 
   * @param event 
   */
  closePopup(event) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        event.preventDefault();
        event.stopPropagation();
        o.dismiss();
      }
    })
  }

  dismiss(refresh = false) {
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss({ refresh: refresh });
      }
    });
  }

  close(data = {}) {
    this.dismiss(false);
  }

  async removeBusiness() {

    const alert = await this.alertCtrl.create({
      header: this.translateService.transform('Are you sure want to delete this business location?'),
      buttons: [
        {
          text: this.translateService.transform('No'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translateService.transform('Yes'),
          handler: async () => {
            let loadngCtrl = await this.loadingCtrl.create();
            loadngCtrl.present();
            this.businessLocationService.delete(this.business_id).subscribe(response => {
              this.eventService.businessLocationReload$.next({});
              this.dismiss(false);
              loadngCtrl.dismiss();
            });
          }
        }
      ]
    });
    alert.present();
  }
}
