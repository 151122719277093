import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-catalog-actions',
  templateUrl: './catalog-actions.component.html',
  styleUrls: ['./catalog-actions.component.scss'],
})
export class CatalogActionsComponent implements OnInit {

  constructor(
    public popupCtrl: PopoverController
  ) { }

  ngOnInit() {}

  /**
   * close popup
   */
  dismiss(action = '') {
    this.popupCtrl.getTop().then(overlay => {
      if(overlay) {
        overlay.dismiss({
          action: action
        });
      }
    });
  }
}
