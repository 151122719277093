import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
//servies
import { SettingsService } from '../../services/logged-in/settings.service';
import { AuthService } from '../../services/auth.service';
import { TranslateLabelService } from '../../services/translate-label.service';
import { DeliveryZoneService } from '../../services/logged-in/delivery-zone.service';
import { EventService } from '../../services/event.service';
import { BusinessLocationService } from '../../services/logged-in/business-location.service';
//pages
import { DeliveryZoneFormPage } from '../../pages/logged-in/settings/delivery-zone/delivery-zone-form/delivery-zone-form.page';
import { AddBusinessLocationPage } from "../../pages/logged-in/settings/business-location/add-business-location/add-business-location.page";
import { DeliveryZoneCitiesPage } from "../../pages/logged-in/settings/delivery-zone/delivery-zone-cities/delivery-zone-cities.page";
import { DeliveryZoneTaxComponent } from '../delivery-zone-tax/delivery-zone-tax.component';
import { DeliveryZoneStatesPage } from 'src/app/pages/logged-in/settings/delivery-zone/delivery-zone-states/delivery-zone-states.page';


@Component({
  selector: 'app-delivery-zone-component',
  templateUrl: './delivery-zone.component.html',
  styleUrls: ['./delivery-zone.component.scss'],
})
export class DeliveryZoneComponent implements OnInit, OnChanges {

  loader = false;
  businessDetail: any;

  @Input() desktopView = 0;

  @Input() business_location_id;

  public deletingZone;
  public deliveryZoneCountryExist = false;
  zones: any[];

  public pageCount = 0;
  public currentPage = 1;

  constructor(
    private router: Router,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public activatedRoute: ActivatedRoute,
    public _settingservices: SettingsService,
    public authService: AuthService,
    public translateService: TranslateLabelService,
    public deliveryZoneService: DeliveryZoneService,
    public eventService: EventService,
    public businessLocationService: BusinessLocationService,
    public toastCtrl: ToastController,
  ) { }

  ngOnInit() {
    this.eventService.editBusinessZone$.subscribe(res => {
      this.editBusinessZone(this.businessDetail);
    });

    this.eventService.businessLocationReload$.subscribe(res => {
      this.loadData();
      this.loadZone();
    });
    
    this.eventService.deliveryAreasUpdated$.subscribe((data: any) => {
      this.loadData();
      this.loadZone();
    });

    this.eventService.deliveryZoneDeleted$.subscribe((data: any) => {
      this.loadZone();
    }); 
  }

  ngOnChanges() {
    this.loadData();
    this.loadZone();
  }

  ionViewWillEnter() {

  }

  loadData(sildent = false, $event = null) {

    if(!this.business_location_id) {
      return null;
    }
    
    if (!sildent) {
      this.loader = true;
    }

    this.businessLocationService.view(this.business_location_id).subscribe((zones: any) => {
      this.loader = false;
      this.businessDetail = zones;

      this.checkDeliveryZoneCountryExist();
      this.deletingZone = null;
    });
  }

  // async updateVat(zone) {
  //   const alert = await this.alertCtrl.create({
  //     header: this.translateService.transform('Set Tax(%)'),
  //     inputs: [
  //       {
  //         name: 'delivery_zone_tax',
  //         type: 'number',
  //         min: 0,
  //         // max: 100,
  //         placeholder: this.translateService.transform('How many percentage?')
  //       }
  //     ],
  //     buttons: [
  //       {
  //         text: this.translateService.transform('Cancel'),
  //         role: 'cancel',
  //         cssClass: 'secondary'
  //       }, {
  //         text: this.translateService.transform('Submit'),
  //         handler: (data) => {
  //
  //           if (data.delivery_zone_tax <= 0) {
  //             return false;
  //           }
  //
  //           const oldZone = Object.assign({}, zone);
  //
  //           zone.delivery_zone_tax = data.delivery_zone_tax;
  //
  //           this.deliveryZoneService.update(zone).subscribe(async (response: any) => {
  //             if (response.operation != 'success')
  //             {
  //               zone = oldZone;
  //
  //               const alert = await this.alertCtrl.create({
  //                 header: this.translateService.transform('Error'),
  //                 message: this.translateService.errorMessage(response.message),
  //                 buttons: [this.translateService.transform('Okay')]
  //               });
  //
  //               await alert.present();
  //             }
  //           });
  //         }
  //       }
  //     ]
  //   });
  //
  //   await alert.present();
  // }

  /**
   * open popup to add zone
   * @param zone
   */
  async addZone() {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: DeliveryZoneFormPage,
      cssClass: 'popup-modal',
      componentProps: {
        business_location_id: this.business_location_id,
        country_id: this.businessDetail?.country?.country_id
      }
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if(data && data.zone && !data.zone.deliver_whole_country) {
      
      setTimeout(() => {
        this.detailZone(data.zone);
      }, 200);
    } 
    else if (data && data.refresh) {
      this.loadData();
    }
  }

  /**
   * open popup to update/delete zone
   * @param zone
   */
  async editZone(zone) {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: DeliveryZoneFormPage,
      cssClass: 'popup-modal',
      componentProps: {
        business_location_id: this.business_location_id,
        zone_id: zone.delivery_zone_id
      }
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();
  
    if (data && data.delete) {
      this.loadData();
    } else if(data && data.refresh && data.zone && (!data.zone.deliver_whole_country || data.zone.deliver_whole_country == 0 || data.zone.deliver_whole_country == "0")) {
      setTimeout(() => {
        this.detailZone(data.zone);
      }, 100);
    } else if (data && data.refresh) {
      this.loadData();
      //zone = data.zone;
    }
  }

  /**
   * open popup to update/delete zone
   * @param zone
   */
  async detailZone(zone) {
 
    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: zone.country && zone.country.iso == "KW" ? DeliveryZoneCitiesPage: 
        DeliveryZoneStatesPage,
      cssClass: 'popup-modal',
      componentProps: {
        business_location_id: this.business_location_id,
        delivery_zone_id: zone.delivery_zone_id,
        deliveryZone: zone,
        store_uuid: this.businessDetail.restaurant_uuid,
      }
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if (data && data.refresh) {
      zone = data.zone;
    }

    if (data && data.delete) {
      this.loadData();
    }
  }


  /**
   * delete delivery zone
   * @param zone
   */
  delete(event, zone) {

    event.preventDefault();
    event.stopPropagation();

    this.deletingZone = zone.delivery_zone_id;

    this.deliveryZoneService.delete(zone).subscribe(async response => {
      if (response.operation == 'success') {

        this.loadData(true);
      }
      else {
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [
            {
              text: this.translateService.transform('Okay')
            }
          ]
        });
        alert.present();
      }
    });
  }

  /**
   * remove VAT value
   * @param zone
   */
  // cancelVat(zone) {
  //   this.deletingZone = zone.delivery_zone_id;
  //   this.deliveryZoneService.cancelOverride(zone).subscribe(async response => {
  //     if (response.operation == 'success') {
  //       this.loadData(true);
  //     } else {
  //       const alert = await this.alertCtrl.create({
  //         header: this.translateService.transform('Error'),
  //         message: this.translateService.errorMessage(response.message),
  //         buttons: [
  //           {
  //             text: this.translateService.transform('Okay')
  //           }
  //         ]
  //       });
  //       alert.present();
  //     }
  //   });
  // }


  checkDeliveryZoneCountryExist() {
    if (this.zones) {
      this.zones.map((zone, index) => {
        if (zone.country) {
          if (zone.country.country_id == this.businessDetail.country_id) {
            this.deliveryZoneCountryExist = true;
          }
        }
      });
    }
  }

  /**
   * load delivery zones
   */
  loadZone() {
    this.deliveryZoneService.listWithPages(this.business_location_id).subscribe(res => {
      this.pageCount = parseInt(res.headers.get('X-Pagination-Page-Count'));
      this.currentPage = parseInt(res.headers.get('X-Pagination-Current-Page'));
      this.zones = res.body;
    });
  }

  /**
   * load more zone on scroll to bottom
   * @param event
   */
  doInfinite(event) {

    if(this.currentPage >= this.pageCount) {
      if(event && event.target)
        event.target.complete();
      return null;
    }

    this.loader = true;

    this.currentPage++;

    this.deliveryZoneService.listWithPages(this.business_location_id, this.currentPage).subscribe(response => {

      this.loader = false;

      this.pageCount = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));

      this.zones = this.zones.concat(response.body);

      if(event && event.target)
        event.target.complete();

    }, () => {
      this.loader = false;
    });
  }

  removeTax(location) {

    const params = Object.assign({}, location);

    params.business_location_tax = 0;

    this.businessLocationService.update(location.business_location_id, params).subscribe(async (response: any) => {
      if (response.operation == 'success') {
        location.business_location_tax = 0;
      }
      else {
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }
    });
  }

  /**
   * open popup to get tax value
   * @param location
   */
  async setTax(location: any) {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: DeliveryZoneTaxComponent,
      cssClass: 'modal-delivery-zone-tax',
      componentProps: {
        tax: location.business_location_tax,
        desktopView: this.desktopView
      }
    });
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if(!data || !data.tax || data.tax < 0) {
      return false;
    }

    const params = Object.assign({}, location);

    params.business_location_tax = data.tax;

    this.businessLocationService.update(location.business_location_id, params).subscribe(async (response: any) => {
      if (response.operation == 'success') {
        location.business_location_tax = data.tax;
        this.toastCtrl.create({
          message: this.translateService.errorMessage(response.message),
          duration: 3000
        }).then(toast => toast.present());
      }
      else {
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }
    });
  }

  async editBusinessZone(businessDetail) {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: AddBusinessLocationPage,
      cssClass: 'popup-modal',
      componentProps: {
        business_id: this.businessDetail.business_location_id,
        business_location: businessDetail
      }
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();
  }

  disablePickup(location) {

    const params = Object.assign({}, location);

    params.support_pick_up = 0;

    this.businessLocationService.update(location.business_location_id, params).subscribe(async (response: any) => {
      if (response.operation == 'success') {
        location.support_pick_up = 0;
      }
      else {
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }
    });
  }

  enablePickup(location) {

    const params = Object.assign({}, location);

    params.support_pick_up = 1;

    this.businessLocationService.update(location.business_location_id, params).subscribe(async (response: any) => {
      if (response.operation == 'success') {
        location.support_pick_up = 1;
      }
      else {
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }
    });
  }
  changePickup() {
    if (this.businessDetail.support_pick_up) {
      this.enablePickup(this.businessDetail);
    } else {
      this.disablePickup(this.businessDetail);
    }
  }
}
