import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-component',
  templateUrl: './skeleton-component.component.html',
  styleUrls: ['./skeleton-component.component.scss'],
})
export class SkeletonComponentComponent implements OnInit {

  @Input() type: string = '';
  @Input() loading: boolean = false;
  
  constructor() { }

  ngOnInit() {}

}
