import { NgModule } from '@angular/core';
import { OrderTimePipe } from './order-time.pipe';
import { ExpiryPipe } from './expiry.pipe';
import { CurrencyPipe as cp } from './currency.pipe';
import { PricePipe } from './price.pipe';
import { CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

//import custom pipes here
@NgModule({
    declarations: [
        ExpiryPipe,
        OrderTimePipe,
        cp,
        PricePipe
    ],
    providers: [
        CurrencyPipe,
    ],
    imports: [
        TranslateModule.forChild()
    ],
    exports: [
        ExpiryPipe,
        OrderTimePipe,
        cp,
        PricePipe
    ]
})
export class PipesModule {}