import { PopoverController } from '@ionic/angular';
import { Component } from '@angular/core';

@Component({
    template: `
      <ion-list>
        <ion-item (click)="close('add-item')" tappable lines="none">
          {{ "Add Item" | translate }}
        </ion-item>
        <ion-item (click)="close('edit')" tappable lines="none">
          {{ "Edit Category" | translate }}
        </ion-item>
        <ion-item (click)="close('delete')" tappable lines="none">
          {{ "Delete Category" | translate }}
        </ion-item>
      </ion-list>
    `
})
export class CategoryActionComponent {

    constructor(public popCtrl: PopoverController) { }

    close(data) {
        this.popCtrl.getTop().then(overlay => {
            if (overlay) {
              this.popCtrl.dismiss({ action: data });
            }
        });
    }
}
