import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
//models
import { ExtraOption } from 'src/app/model/extra_option';
import { Option } from 'src/app/model/item_option';
import { ItemVariant } from 'src/app/model/item_variant';
import { ItemVariantOption } from 'src/app/model/item_variant_option';
//services
import { AuthService } from 'src/app/services/auth.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-item-variant-form',
  templateUrl: './item-variant-form.page.html',
  styleUrls: ['./item-variant-form.page.scss'],
})
export class ItemVariantFormPage implements OnInit {

  public track_quantity;
  
  public options: Option[];
  
  public option: Option;
  public extraOption: ExtraOption;

  public model: ItemVariant;

  form: FormGroup;
  
  public borderLimit = false;

  constructor(
    public platform: Platform,
    public formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    public authService: AuthService,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {
    if(!this.model.itemVariantOptions) {
      this.model.itemVariantOptions = [];
    }

    this.initForm();
  }

  initForm() 
  {
    this.form = this.formBuilder.group({
      price: [this.model.price || '', Validators.required],
      compare_at_price: [this.model.compare_at_price],
      sku: [this.model.sku || ''],
      barcode: [this.model.barcode || ''],
      track_quantity: [this.model.track_quantity || false],
      stock_qty: [this.model.stock_qty || 0],
      weight: [this.model.weight],
      height: [this.model.height],
      width: [this.model.width],
      length: [this.model.length],
    }, {
      validators: (control: AbstractControl) => {

        const compare_at_price = control.get('compare_at_price');
        const price = control.get('price');

        if(compare_at_price.value > 0 && 
          compare_at_price.value <= price.value
        ) {
          compare_at_price.setErrors({
            invalid: true
          });
        }
      }
    });

    this.form.get('track_quantity').valueChanges.subscribe(val => {
      if (val) {
        this.form.controls.stock_qty.setValidators([Validators.required]);
      } else {
        this.form.controls.stock_qty.clearValidators();
      }
      this.form.controls.stock_qty.updateValueAndValidity();
    });
  }

  updateModelFromFormValues() {
    this.model.stock_qty = this.form.value.stock_qty;
    this.model.track_quantity = this.form.value.track_quantity;
    this.model.sku = this.form.value.sku;
    this.model.barcode = this.form.value.barcode;
    this.model.price = this.form.value.price;
    this.model.compare_at_price = this.form.value.compare_at_price;
    this.model.weight = this.form.value.weight;
    this.model.width = this.form.value.width;
    this.model.height = this.form.value.height;
    this.model.length = this.form.value.length;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  onOptionSelect(event) {

    this.options.forEach(o => {
      if(o.option_name == event.target.value) {
        this.option = o; 
      };

      return;
    });
  }

  onExtraOptionSelect(event) {
    this.option.extraOptions.forEach(o => {
      if(o.extra_option_name == event.target.value) {
        this.extraOption = o; 
      };

      return;
    });
  }

  addOption() {
    let itemVariantOption = new ItemVariantOption;
    itemVariantOption.option = this.option; 
    itemVariantOption.extraOption = this.extraOption; 
    itemVariantOption.option_id = this.option.option_id;
    itemVariantOption.extra_option_id = this.extraOption.extra_option_id; 

    this.model.itemVariantOptions.push(itemVariantOption);

    //this.option = this.extraOption = null;
  }

  add() {
    this.updateModelFromFormValues(); 

    this.modalCtrl.dismiss({
      model: this.model
    });
  }
}
