import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
//services
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from "src/app/services/event.service";


@Component({
  selector: 'app-header-shop-popup',
  templateUrl: './header-shop-popup.page.html',
  styleUrls: ['./header-shop-popup.page.scss'],
})
export class HeaderShopPopupPage implements OnInit {

  public search;

  public filtersStores = [];

  constructor(
    private _router: Router,
    public authService: AuthService,
    public popoverController: PopoverController,
    public eventService: EventService,
  ) {
  }

  ngOnInit() {
    if(!this.authService.stores || this.authService.stores.length == 0) {
      this.eventService.refreshStoreList$.next({});
    }
  }

  navigate(route: string) {
    this.popoverController.dismiss();
    this._router.navigate([route]);
  }

  logout() {
    this.popoverController.dismiss();
    this.authService.logout();
  }

  /**
   * change store
   * @param store
   */
  changeStore(assignment) {

    this.authService.store_id = assignment.restaurant.restaurant_uuid;
    this.authService.saveInStorage();

    this.eventService.storeChanged$.next({
      store: assignment.restaurant,
      role: assignment.role
    });

    this.eventService.refreshStoreList$.next({});
    this.popoverController.dismiss();
  }

  /**
   * filter by store name
   * @param ev
   */
  filter(ev: any) {

      this.search = ev.target.value;

      if (this.authService.stores) {
        this.filtersStores = this.authService.stores.filter(item => {
          return (
            item.restaurant.name.toLowerCase().indexOf(ev.target.value.toLowerCase()) > -1 ||
            item.restaurant.name_ar.toLowerCase().indexOf(ev.target.value.toLowerCase()) > -1
          );
        });
      }
  }
}
