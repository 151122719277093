import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
//services
import { TranslateLabelService } from '../services/translate-label.service';


@Pipe({
	name: 'expiry',
	pure: false
})
export class ExpiryPipe implements PipeTransform, OnDestroy {

    private timer: number;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private translate: TranslateLabelService
	) {}

	transform(value: string, txt_expire_in = "txt_time_left", txt_expired_at = 'txt_expired_at') {
		
		this.removeTimer();

		const d = new Date(value.replace(/-/g, '/') + ' UTC');
		const now = new Date();
		let delta = Math.round((d.getTime() - now.getTime()) / 1000);
		const timeToUpdate = 1000; // (Number.isNaN(delta)) ? 1000 : this.getSecondsUntilUpdate(delta) *1000;

        this.timer = this.ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.ngZone.run(() => this.changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
        });

		if (delta <= 0) {
			const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
			return this.translate.transform(txt_expired_at, { value: this.timeAgo(seconds) });
		}

		let days, hours, minutes, seconds;

		days = Math.floor(delta / 86400);
		delta -= days * 86400;
		hours = Math.floor(delta  / 3600) % 24;
		delta -= hours * 3600;
		minutes = Math.floor(delta  / 60) % 60;
		delta -= minutes * 60;
		seconds = delta % 60;

		let time = '';

		if (days > 0) {
			if (days == 1) {
				time += days + ' ' + this.translate.transform('day') + ' ';
			} else {
				time += days + ' ' + this.translate.transform('days')+ ' ';
			}
		}

		if (hours > 0) {
			if (hours == 1) {
				time += hours + ' ' + this.translate.transform('hour') + ' ';
			} else {
				time += hours + ' ' + this.translate.transform('hours') + ' ';
			}
		}

		if (minutes > 0) {
			if (minutes == 1) {
				time += minutes + ' ' + this.translate.transform('minute') + ' ';
			} else {
				time += minutes + ' ' + this.translate.transform('minutes') + ' ';
			}
		}

		//time += seconds + ' second';

		return this.translate.transform(txt_expire_in, { value: time });
    }

	/**
	 * if got expired
	 * @param seconds
	 */
	timeAgo(seconds) {
		const minutes = Math.round(Math.abs(seconds / 60));
		const hours = Math.round(Math.abs(minutes / 60));
		const days = Math.round(Math.abs(hours / 24));
		const months = Math.round(Math.abs(days / 30.416));
        const years = Math.round(Math.abs(days / 365));

		if (Number.isNaN(seconds)) {
			return '';
		} else if (seconds <= 45) {
			return this.translate.transform('a few seconds ago');
		} else if (seconds <= 90) {
			return this.translate.transform('a minute ago');
		} else if (minutes <= 45) {
			return this.translate.transform('txt_minutes_ago', { value: minutes });
		} else if (minutes <= 90) {
			return this.translate.transform('an hour ago');
		} else if (hours <= 22) {
			return this.translate.transform('txt_hours_ago', { value: hours });
		} else if (hours <= 36) {
			return this.translate.transform('a day ago');
		} else if (days <= 25) {
			return this.translate.transform('txt_days_ago', { value: days });
		} else if (days <= 45) {
			return this.translate.transform('a month ago');
		} else if (days <= 345) {
			return this.translate.transform('txt_months_ago', { value: months });
		} else if (days <= 545) {
			return this.translate.transform('a year ago');
		} else { // (days > 545)
			return this.translate.transform('txt_years_ago', { value: years });
		}
	}

	ngOnDestroy(): void {
		this.removeTimer();
    }

	private removeTimer() {
		if (this.timer) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
    }

	private getSecondsUntilUpdate(seconds: number) {
		const min = 60;
		const hr = min * 60;
		const day = hr * 24;
		if (seconds < min) { // less than 1 min, update every 2 secs
			return 2;
		} else if (seconds < hr) { // less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) { // less then a day, update every 5 mins
			return 300;
		} else { // update every hour
			return 3600;
		}
	}
}

