import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BusinessLocationPickerComponent } from './business-location-picker.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BusinessLocationPickerComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild()
  ],
  exports: [
    BusinessLocationPickerComponent
  ]
})
export class BusinessLocationPickerModule { }
