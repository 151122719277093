import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class StateService {

  public endpoint: string = "states";

  constructor(
    public _authhttp: AuthHttpService,
    public auth: AuthService
  ) { }

  /**
   * list states
   * @param page 
   * @param query 
   * @returns 
   */
  list(country_id, query): Observable<any> {
    const url = `${this.endpoint}?country_id=${country_id}&store_uuid=${this.auth.store_id}${query}`;
    return this._authhttp.getRaw(url);
  }
}
