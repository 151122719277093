import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrderViewPageRoutingModule } from './order-view-routing.module';

import { OrderViewPage } from './order-view.page';
import {ComponentsModule} from "../../../../components/components.module";
import { TranslateModule } from '@ngx-translate/core';
import { OrderOptionComponent } from 'src/app/components/order-option/order-option.component';
import { LoadingModalModule } from 'src/app/components/loading-modal/loading-modal.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        OrderViewPageRoutingModule,
        ComponentsModule,
        LoadingModalModule,
        PipesModule,
        TranslateModule.forChild(),
    ],
  declarations: [
    OrderViewPage,
    OrderOptionComponent
  ]
})
export class OrderViewPageModule {}
