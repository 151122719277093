import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
// services
import { CategoryService } from 'src/app/services/category.service';
import { AuthService } from "../../../../services/auth.service";
import { AwsService } from 'src/app/services/aws.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { EventService } from 'src/app/services/event.service';


@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.page.html',
  styleUrls: ['./category-form.page.scss'],
})
export class CategoryFormPage implements OnInit {

  @ViewChild('imageInput', { static: false }) public imageInput: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  public uploadingImage: boolean = false;

  public category_form: FormGroup;

  public loading: boolean = false;

  public saving: boolean = false;

  public category;

  public borderLimit = false;

  @Input() category_id;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public toastCtrl: ToastController,
    public activatedRoute: ActivatedRoute,
    public platform: Platform,
    public categoryService: CategoryService,
    public modalCtrl: ModalController,
    public eventService: EventService,
    public authService: AuthService,
    public translateService: TranslateLabelService,
    public awsService: AwsService,
    public alertCtrl: AlertController,
    public navCtrl: NavController
  ) {
  }

  ngOnInit() {

    if (window.history.state.model) {
      this.category = window.history.state.model;
      this.initForm();
    } else if (this.category_id) {
      this.view();
    } else {
      this.initForm();
    }
  }

  initForm() {

    const category_image_url = this.category && this.category.restaurant_uuid ?
      'https://res.cloudinary.com/plugn/image/upload/c_scale,h_105,w_105/restaurants/' + this.category.restaurant_uuid +
			'/category/' + this.category.category_image: '';

    this.category_form = this.formBuilder.group({
      title: [this.category?.title || '', Validators.required],
      title_ar: [this.category?.title_ar || '', Validators.required],
      subtitle: [this.category?.subtitle || ''],
      subtitle_ar: [this.category?.subtitle_ar || ''],
      sort_number: [this.category?.sort_number || ''],
      category_image: [this.category?.category_image || ''],
			category_image_url: [category_image_url],
      category_meta_description: [this.category?.category_meta_description],
      category_meta_description_ar: [this.category?.category_meta_description_ar],
    });
  }

  /**
   * open file chooser
   */
	updateImage() {
		this.imageInput.nativeElement.click();
	}

	/**
	 * upload from browser
	 * @param event
	 * @returns
	 */
	browserUploadOption(event) {
    this.fileInput.nativeElement.click();    // And also this with @ViewCh
  }

	browserUpload(event) {

		this.uploadingImage = true;

		const fileList: FileList = event.target.files;

		if (fileList.length == 0) {
			return false;
		}

		const prefix = fileList[0].name.split('.')[0];

		const type = fileList[0].type.split('/')[0];

		if (type != 'image') {
			this.alertCtrl.create({
				message: this.translateService.transform('Invalid File format'),
				buttons: [this.translateService.transform('Okay')],
			}).then((alert) => { alert.present(); });
		}
		else {

			const uploadFileSubscription = this.awsService.uploadFile(fileList[0]).subscribe((event) => {

				this._handleFileSuccess(event);

			}, async (err) => {

				if (!err.message || !err.message.includes('aborted')) {

					// log to sentry

					// this.sentryService.handleError(err);

					const alert = await this.alertCtrl.create({
						header: this.translateService.transform('Error'),
						message: this.translateService.transform('Error while uploading file!'),
						buttons: [this.translateService.transform('Okay')],
					});

					await alert.present();
				}

				this.resetFileInputs();

				//this.progress = null;

			}, () => {
				//clearInterval(this.interval);
				//this.progress = 100;
				uploadFileSubscription.unsubscribe();
			});
		}
	}

	/**
	 * reset file inputs so can select new to update
	 */
	resetFileInputs() {
    this.uploadingImage = false;
    if (this.imageInput && this.imageInput.nativeElement)
      this.imageInput.nativeElement.value = null;
	}

	/**
	 * Handle upload api response
	 * @param event
	 */
	public _handleFileSuccess(event) {
		// Via this API, you get access to the raw event stream.
		// Look for upload progress events.

		if (event.type === 'progress') {
			// This is an upload progress event. Compute and show the % done:
			// this.progress = Math.round(100 * event.loaded / event.total);
		} else if (event.Key && event.Key.length > 0) {

			this.resetFileInputs();

			/*const imgLarge = new Image();
			imgLarge.src = event.Location;
			imgLarge.onload = () => {*/

        this.category_form.controls.category_image.setValue(event.Key);
        this.category_form.controls.category_image_url.setValue(event.Location);
        this.category_form.controls.category_image.markAsDirty();
        this.category_form.controls.category_image_url.markAsDirty();

				this.category_form.updateValueAndValidity();
			//};
		}
		/*else if (!this.currentTarget) {
			this.currentTarget = event;
		}*/
	}

    /**
   * close popup
   * @param refresh
   */
  dismiss(refresh = false) {
    this.modalCtrl.getTop().then(overlay => {
      if(overlay) {
        overlay.dismiss({ refresh });
      }
    });
  }


  /**
   * save category details
   */
  save() {
    this.saving = true;

    let request;

    if (this.category_id) {
      request = this.categoryService.update(this.category_form.value, this.category_id);
    } else {
      request = this.categoryService.create(this.category_form.value);
    }

    request.subscribe(res => {

      if(res.operation == 'success') {
        this.eventService.refreshCategories$.next({});
        this.category_form.reset();

        this.dismiss(true);
      }

      this.toastCtrl.create({
        message: this.authService.errorMessage(res.message),
        duration: 2000
      }).then(toast => {
        toast.present();
      });


    },
      err => this.saving = false,
      () => this.saving = false,
    );
  }

  /**
   * view detail
   */
  view() {
    this.loading = true;
    this.categoryService.view(this.category_id).subscribe(res => {
      this.category = res;
      this.initForm();
    }, err => this.loading = false,
      () => this.loading = false
    );
  }

  async confirmCategoryDelete(category) {
    this.alertCtrl.create({
      message: this.translateService.transform('Are you sure you want to remove this category?'),
      buttons: [
        {
          text: this.translateService.transform('Yes'),
          role: 'Yes',
          handler: () => {
            this.loading = true;

            this.categoryService.delete(category).subscribe(res => {
              if (res.operation != 'success') {
                this.alertCtrl.create({
                  message: this.authService.errorMessage(res.message)
                }).then(alert => alert.present());
              } else {
                /*this.alertCtrl.create({
                  message: this.authService.errorMessage(res.message)
                }).then(alert => alert.present());*/

                this.dismiss(true);

              }
            },
              err => this.loading = false,
              () => this.loading = false
            );
          }
        },
        {
          text: this.translateService.transform('No'),
          role: 'Cancel',
        }
      ]
    }).then(alert => alert.present());
  }


  onImageError() {
    this.category_form.controls.category_image_url.setValue(null);
    this.category_form.controls.category_image.setValue(null);
  }

  removeImage(event) {
    this.category_form.controls.category_image_url.setValue(null);
    this.category_form.controls.category_image.setValue(null);
    event.preventDefault();
    event.stopPropagation();
  }
  
  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
