import { Injectable } from '@angular/core';
import { AuthHttpService } from './authhttp.service';

@Injectable({
  providedIn: 'root'
})
export class ApplePayService {

  public endpoint: string = "payment/apple-pay";

  constructor(
    public _authhttp: AuthHttpService
  ) { }

  validateApplePayMerchant(params) {
    let url = `${this.endpoint}/validate-merchant`;
    return this._authhttp.post(url, params);
  }

  processAppleToken(params) {
    let url = `${this.endpoint}/process-payment`;
    return this._authhttp.post(url, params);
  }
}
