import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { Item } from 'src/app/model/Item';
import { ItemVideo } from 'src/app/model/item-videos';
import { TranslateLabelService } from 'src/app/services/translate-label.service';

@Component({
  selector: 'app-item-video',
  templateUrl: './item-video.page.html',
  styleUrls: ['./item-video.page.scss'],
})
export class ItemVideoPage implements OnInit {

  model: ItemVideo;

  form: FormGroup;

  public borderLimit = false;

  constructor(
    public platform: Platform,
    public modalCtrl: ModalController,
    public formBuilder: FormBuilder,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() 
  {
    this.form = this.formBuilder.group({
      item_uuid : [this.model.item_uuid, Validators.required],
      youtube_video_id : [this.model.youtube_video_id],
      //product_file_name : [this.model.product_file_name],

    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  add() {
    this.model.youtube_video_id = this.form.value.youtube_video_id;

    this.modalCtrl.dismiss({
      model: this.model
    })
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
