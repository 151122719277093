import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ConfirmPlanPageRoutingModule } from './confirm-plan-routing.module';

import { ConfirmPlanPage } from './confirm-plan.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { StripeFormModule } from 'src/app/components/stripe-form/stripe-form.module';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      StripeFormModule,
      ComponentsModule,
      ConfirmPlanPageRoutingModule,
      ReactiveFormsModule,
      TranslateModule.forChild(),
    ],
  declarations: [ConfirmPlanPage]
})
export class ConfirmPlanPageModule {}
