import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-item-actions',
  templateUrl: './item-actions.component.html',
  styleUrls: ['./item-actions.component.scss'],
})
export class ItemActionsComponent implements OnInit {

  @Input() item;

  constructor(
    public popupCtrl: PopoverController
  ) { }

  ngOnInit() {}
  
  /**
   * close popup
   */
  dismiss(action = '') {
    this.popupCtrl.getTop().then(overlay => {
      if(overlay) {
        overlay.dismiss({
          action: action
        });
      }
    });
  }
}
