import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ItemListPage } from './item-list.page';

const routes: Routes = [
  {
    path: '',
    component: ItemListPage
  },
  // {
  //   path: 'item-form',
  //   loadChildren: () => import('./item-form/item-form.module').then( m => m.ItemFormPageModule)
  // },
  // {
  //   path: 'item-form/:id',
  //   loadChildren: () => import('./item-form/item-form.module').then( m => m.ItemFormPageModule)
  // },
  // {
  //   path: 'item-form/:category/:id',
  //   loadChildren: () => import('./item-form/item-form.module').then( m => m.ItemFormPageModule)
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ItemListPageRoutingModule {}
