import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthHttpService } from '../authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class StripeService {

  public endpoint: string = "payment/stripe";

  constructor(
    public _authhttp: AuthHttpService
  ) { }

  /**
   * get params to init moyasar
   * @param page 
   * @returns 
   */
  getInitParams(params): Observable<any> {
    let url = `${this.endpoint}`;
    return this._authhttp.post(url, params);
  }
}

