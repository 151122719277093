import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AreaLocationPickerComponent } from './area-location-picker.component';
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    AreaLocationPickerComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    PipesModule
  ],
  exports: [
    AreaLocationPickerComponent
  ]
})
export class AreaLocationPickerModule { }
