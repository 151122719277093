import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { CountryService } from 'src/app/services/logged-in/country.service';
import { ShippingMethodService } from 'src/app/services/logged-in/shipping-method.service';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import mixpanel from 'mixpanel-browser';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-mashkor',
  templateUrl: './mashkor.page.html',
  styleUrls: ['./mashkor.page.scss'],
})
export class MashkorPage implements OnInit {
 
    public borderLimit = false;
  
    public form: FormGroup;
  
    public countryList = [];
  
    public loading: boolean = false;
    
    public saving: boolean = false;
  
    constructor(
      public formBuilder: FormBuilder,
      public router: Router,
      public modalCtrl: ModalController,
      public alertCtrl: AlertController,
      public translateService: TranslateLabelService,
      public storeServices: StoreService,
      public authService: AuthService,
      public countryService: CountryService,
      public shippingService: ShippingMethodService,
      public analytics: AnalyticsService
    ) { }
  
    ngOnInit() {
      this.analytics.page('Mashkor config page');
      
      this.initForm();
     // this.loadData(); 
    }
      
    ionViewWillLeave() {
      this.analytics.track('page_exit', {
        'page': 'Mashkor config page'
      });
    }
    
    logScrolling(e) {
      this.borderLimit = (e.detail.scrollTop > 25);
    }
  
    /*loadData() {
      this.storeServices.getSettings('Mashkor').subscribe(data => {
        this.initForm(data);
      });
    }*/
  
    initForm() {
      this.form = this.formBuilder.group({
        branch_id: [this.authService.store.mashkor_branch_id, Validators.required]
      });
    }
  
    /**
     * save detail
     */
    save() {
  
      if(!this.form || !this.form.valid) {
        return false;
      }
  
      this.saving = true;
  
      this.shippingService.setConfig("Mashkor", this.form.value).subscribe(async response => {
  
        this.saving = false;
  
        if(response.operation == 'success') {
  
          this.authService.store.mashkor_branch_id = this.form.value.mashkor_branch_id;
          
          this.dismiss(this.form.value);
  
          /*this.router.navigate(['/']);
         
          const alert = await this.alertCtrl.create({
            header: this.translateService.transform('Success'),
            message: response.message,
            buttons: [this.translateService.transform('Okay')]
          });
  
          await alert.present();*/
  
        } else {

          this.authService.showAlert(
            this.translateService.transform('Error saving mashkor config'),
            this.translateService.errorMessage(response.message),
            this.form.value
          );  
        }
      });
    }
  
    dismiss(data = {}) {
      this.modalCtrl.dismiss(data);
    }
  }
  