import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, Platform, PopoverController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import mixpanel from 'mixpanel-browser';
//models
import { PaymentMethod } from 'src/app/model/payment_method';
//services
import { AuthService } from 'src/app/services/auth.service';
import { AddonService } from 'src/app/services/logged-in/addon.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ApplePayService } from 'src/app/services/logged-in/apple-pay.service';


declare let ApplePaySession;

@Component({
  selector: 'app-confirm-addon',
  templateUrl: './confirm-addon.page.html',
  styleUrls: ['./confirm-addon.page.scss'],
})
export class ConfirmAddonPage implements OnInit {

  public addon_uuid;
  
  public addon;

  public paymentMethods: PaymentMethod[] = [];

  public loading: boolean = false;

  public submitting: boolean = false;

  public form: FormGroup;

  public showApplePay: boolean = false; 

  constructor(
    public platform: Platform,
    public formBuilder: FormBuilder,
    public applePayService: ApplePayService,
    public translateService: TranslateLabelService,
    public alertCtrl: AlertController,
    public popOverCtrl: PopoverController,
    public router: Router,
    public route: ActivatedRoute,
    public analytics: AnalyticsService,
    public authService: AuthService,
    public addonService: AddonService
  ) { }

  ngOnInit() {
   
		this.analytics.page('Purchase addon page');

    if(!this.addon_uuid)
      this.addon_uuid = this.route.snapshot.paramMap.get('id');

    this.loadData();
  }

  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Purchase addon page'
    });
  }

  /**
   * load addon detail
   */
  loadData() {
    this.loading = true;

    this.addonService.view(this.addon_uuid).subscribe(data => {

      this.addon = data;

      this.paymentMethods = data.paymentMethods;

      this.loading = false;

      this.initForm();

      if (window['ApplePaySession'] && window['ApplePaySession'].canMakePayments()) {
        this.showApplePay = true;
      } 
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      addon_uuid: [this.addon_uuid, Validators.compose([Validators.required])], 
      payment_method_id: ['', Validators.compose([Validators.required])],
    });
  }

  beginApplePay() {

    const paymentRequest = {
        countryCode: 'KW',
        currencyCode: 'KWD',
        supportedNetworks: ['visa', 'masterCard', 'mada'],//'amex', 'discover'
        merchantCapabilities: ['supports3DS', 'supportsEMV', 'supportsCredit', 'supportsDebit'],
        total: {
            label: 'Total',
            amount: this.addon.special_price > 0? this.addon.special_price: this.addon.price
        }
    };

    const session = new ApplePaySession(3, paymentRequest);

    // Merchant validation
    session.onvalidatemerchant = (event) => { 
      this.applePayService.validateApplePayMerchant(event).subscribe(data => {
        console.log(data);
          session.completeMerchantValidation(data);
      }, error => {
          console.error('Error during merchant validation', error);
          session.abort();
      });
    };

    // Payment authorized
    session.onpaymentauthorized = (event) => {
      console.log("onpaymentauthorized", event);

      const params = {
        ...event, 
        ...this.form.value 
      };
      
      this.applePayService.processAppleToken(params).subscribe(data => {
        console.log(data);
          if (data.success) {
              session.completePayment(ApplePaySession.STATUS_SUCCESS);
          } else {
              session.completePayment(ApplePaySession.STATUS_FAILURE);
          }
        }, error => {
          console.error('Error processing payment', error);
          session.completePayment(ApplePaySession.STATUS_FAILURE);
        });
    };

    // Begin the Apple Pay session
    session.begin();
  }

  /**
   * make payment
   */
  makePayment() {

    if(!this.form.controls.payment_method_id.value) {
      return false;
    }

    this.submitting = true; 

    this.addonService.confirm(this.form.value).subscribe(response => {
    
      //check callback working 

      //api in postman 

      //on app load show message/redirect accordingly 

      if(response.redirect) 
      {
        if(this.platform.is('hybrid')) {

          Browser.open({ url: response.redirect }).then((e) => { 
          });

          Browser.addListener('browserPageLoaded', () => {

            if(window.location.href.includes('plugn')) {
              /*this.alertCtrl.create({
                header:this.translateService.transform('Success'), 
                message: this.translateService.transform('Plan updated successfully!'),
                buttons: [this.translateService.transform('Okay')]
              }).then(prompt => prompt.present());*/

              Browser.removeAllListeners();
              
              return this.dismiss({ refresh: true });
            }
          });

          Browser.addListener('browserFinished', () => {

            if(window.location.href.includes('plugn')) {
              /*this.alertCtrl.create({
                header:this.translateService.transform('Success'), 
                message: this.translateService.transform('Plan updated successfully!'),
                buttons: [this.translateService.transform('Okay')]
              }).then(prompt => prompt.present());*/

              Browser.removeAllListeners();
              
              return this.dismiss({ refresh: true });
            }
          });

        } else {
          window.open(response.redirect, '_self');
        }
        
      } 
      else if(response.operation == 'success')
      {
        this.submitting = false;
    
        //todo: refresh activeSubscription 

        this.dismiss();

        //this.router.navigate(['settings/payment-methods']);

        /*this.alertCtrl.create({
          message: response.message,
          buttons: [this.translateService.transform('Okay')]
        }).then(prompt => prompt.present());*/
      }
      else 
      {
        this.submitting = false;
    
        this.alertCtrl.create({
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        }).then(prompt => prompt.present());
      }
    });
  }

  /**
   * price to purchase
   * @returns 
   */
  getTotal() {
    return this.addon.special_price > 0? this.addon.special_price: this.addon.price;
  }

  onMethodSelect(method) {
    this.form.controls.payment_method_id.setValue(method.payment_method_id);
    this.form.controls.payment_method_id.updateValueAndValidity();
  }

  /**
   * close popup
   */
  dismiss(data = {}) {
    this.popOverCtrl.dismiss(data);
  }
}
