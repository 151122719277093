import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
 
import { StripeFormComponent } from './stripe-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';


@NgModule({
    declarations: [StripeFormComponent],
    imports: [ 
        IonicModule,
        CommonModule,
        FormsModule,
        PipesModule,
        TranslateModule.forChild()
    ],
    exports: [StripeFormComponent]
})
export class StripeFormModule { }