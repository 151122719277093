import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SunmiDevice } from 'plugn-device';
import { DatePipe } from '@angular/common';
import { Platform } from '@ionic/angular';
import mixpanel from 'mixpanel-browser';
//services
import { OrdersService } from 'src/app/services/logged-in/orders.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { EventService } from 'src/app/services/event.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

declare var window: any;

@Component({
  selector: 'app-order-invoice',
  templateUrl: './order-invoice.page.html',
  styleUrls: ['./order-invoice.page.scss'],
})
export class OrderInvoicePage {

  public base64DefaultURL;

  public downloading: boolean = false;

  public order_uuid;

  public borderLimit: boolean = false;

  public loading: boolean = false;

  public order;

  constructor(
    public modelCtrl: ModalController,
    public orderService: OrdersService,
    public translateService: TranslateLabelService,
    public datePipe: DatePipe,
    public eventService: EventService,
    public analytics: AnalyticsService,
    public platform: Platform,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    
		this.analytics.page('Order Invoice page');

    if(!this.order_uuid) {
      this.order_uuid = this.route.snapshot.paramMap.get('id');
    }

    this.loading = true;

    this.orderService.view(this.order_uuid).subscribe(data => {

      this.order = data;
      
      this.loading = false;
    })
  }

  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Order Invoice page'
    });
  }

  dimenstionToText(orderItem) {

    let value = [];

    if(orderItem.weight > 0) {
      value.push(orderItem.weight+ ' kg');
    }

    if(orderItem.width > 0 || orderItem.length > 0 || orderItem.height > 0) {
      value.push(orderItem.width + ' cm (W) X ' + orderItem.length + ' cm (L) X ' + orderItem.height + ' cm (H)');
    }
 
    if (value.length > 0) {
      return ' · ' + value.join(' / ');
    }

    return null;
  }
  
  extraOptionsToText(orderItemExtraOptions) {

    let value = [];

    for(let extraOption of orderItemExtraOptions) {
      value.push(this.translateService.langContent(extraOption['extra_option_name'],extraOption['extra_option_name_ar']));
    }

    if(value.length > 0)
      return value.join(', ');
  }

  getVoucherDiscount(voucher) {
    return voucher.discount_type == 1 ?
      (this.order.subtotal * (this.order.voucher.discount_amount / 100)) : this.order.voucher.discount_amount;
  }

  getBankDiscount(bankDiscount) {
    return bankDiscount.discount_type == 1 ?
      this.order.subtotal * (this.order.bankDiscount.discount_amount / 100) : this.order.bankDiscount.discount_amount;
  }

  async print() {

    SunmiDevice.getPrinterVersion().then(result => {

      if(result['value'] && this.platform.is('capacitor')){
        const params = {
          order: this.order,
          printTwoInvoices : false
        };
        this.eventService.printInvoice$.next(params);
      } else {
        //window.print();
        this.downloadInvoice();
      }
    });
  }

  /**
   * download invoice pdf
   */
  downloadInvoice() {
    this.downloading = true;

    this.orderService.downloadInvoice(this.order).subscribe(() => {
      this.downloading = false;
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  /**
   * Make date readable by Safari
   * @param date
   */
   toDate(date) {
    if (date && Date.parse(date)) {
      return new Date(date.replace(/-/g, '/'));
    }
  }

  /**
   * close popup if any
   * @param event
   */
  close(event) {

    event.preventDefault();
    event.stopPropagation();

    this.modelCtrl.getTop().then(o => {
      if(o) {
        o.dismiss();
      }
    })
  }

  onLogoError(restaurant) {
    restaurant.logo = null;
  }
}
