import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ItemFormPage } from './item-form.page';

const routes: Routes = [
  {
    path: '',
    component: ItemFormPage
  },
  {
    path: 'item-options-form',
    loadChildren: () => import('./item-options-form/item-options-form.module').then( m => m.ItemOptionsFormPageModule)
  },
  // {
  //   path: ':id',
  //   component: ItemFormPage
  // },
  // {
  //   path: ':id/:category',
  //   component: ItemFormPage
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ItemFormPageRoutingModule {}
