import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SchedulePickupPageRoutingModule } from './schedule-pickup-routing.module';

import { SchedulePickupPage } from './schedule-pickup.page';
import { TelInputModule } from 'src/app/components/tel-input/tel-input.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TelInputModule,
    PipesModule,
    TranslateModule.forChild(),
    SchedulePickupPageRoutingModule
  ],
  declarations: [SchedulePickupPage]
})
export class SchedulePickupPageModule {}
