import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/services/analytics.service';
//services
import { PaymentMethodService } from 'src/app/services/logged-in/payment-method.service';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-apple-pay',
  templateUrl: './apple-pay.page.html',
  styleUrls: ['./apple-pay.page.scss'],
})
export class ApplePayPage implements OnInit {

  public borderLimit = false;

  public form: FormGroup;

  public loading: boolean = false;
  
  public saving: boolean = false;

  public store; 

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public translateService: TranslateLabelService,
    public storeService: StoreService,
    public analytics: AnalyticsService,
    public paymentServices: PaymentMethodService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.analytics.page('Apple config page');
    
    this.initForm();
  } 
  
  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Apple config page'
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
 
  initForm() {

    const content = this.store.restaurantUploads && this.store.restaurantUploads.length > 0 ?
      this.store.restaurantUploads[0].content: "";

    this.form = this.formBuilder.group({
      apple_domain_association: [content, Validators.required],
    });
  }

  /**
   * save detail
   */
   save() {

    if(!this.form || !this.form.valid) {
      return false;
    }

    this.saving = true;

    this.storeService.uploadAppleDomainAssociation(this.form.value.apple_domain_association).subscribe(async res => {
      if(res.operation == "success") {
        this.dismiss(res);
      } else {
        this.authService.showAlert(
          this.translateService.transform('Error saving apple pay domain association'),
          this.translateService.errorMessage(res.message),
          this.form.value
        );  
      }
    });
  }

  dismiss(data = {}) {
    this.modalCtrl.dismiss(data);
  }
}
