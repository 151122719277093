import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
//services
import { AuthService } from '../services/auth.service';


@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  constructor(
    private _decimalPipe: DecimalPipe,
    public authService: AuthService
  ) {}

  transform(value: number, convertTo: string = null, code: string = null): unknown {

    if(!code) {
      code = this.authService.store?.currency?.code;
    }

    if(convertTo && code != convertTo) {

      const fromCurrency = this.getCurrency(code);
      const toCurrency = this.getCurrency(convertTo);
       
      value = (value / fromCurrency.rate) * toCurrency.rate;
    }

    if(!convertTo) {
      return code + ' ' + this._decimalPipe.transform(value, '1.3-3');
    }

    return convertTo + ' ' + this._decimalPipe.transform(value, '1.3-3');
  }

  getCurrency(code) {
    for(let currency of this.authService.store.currencies) {
      if(currency.code == code)
        return currency
    }
  }
}
