import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { TelCountryPickerComponent } from './tel-country-picker/tel-country-picker.component';
import { TelInputComponent } from './tel-input.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule, 
    IonicModule,
    TranslateModule.forChild()
  ],
  declarations: [
    TelInputComponent,
    TelCountryPickerComponent
  ],
  exports: [
    TelInputComponent
  ]
})
export class TelInputModule { }
