import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SelectiveLoadingStrategy } from './util/SelectiveLoadingStrategy';
//services
import { AuthService } from './services/auth.service';
//guards
import { AuthGuard } from "./guards/auth/auth.guard";
import { RoleGuard } from './services/role-guard.service';


/**
 * Only for owner
 * --------------------------
 * 1- Store Info
 * 2- Payment methods
 * 3- Analytics
 * 4- Report page
 * 5- Homepage
 * 6- Voucher pages ( Create - update - view - list)
 * 6- Bank discount pages ( Create - update - view - list)
 */

const routes: Routes = [
  /*{
    path: '',
    redirectTo: '/overview',
    pathMatch: 'full'
  },*/
  {
    path: '',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'orders',
    redirectTo: '/orders/index',
    pathMatch: 'full'
  },
  {
    path: 'analytics',
    redirectTo: '/analytics/statistics',
    pathMatch: 'full'
  }, {
    path: 'discounts',
    redirectTo: '/discounts/voucher',
    pathMatch: 'full'
  }, {
    path: 'settings',
    redirectTo: '/settings/store',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/start-pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/start-pages/register/register.module').then(m => m.RegisterPageModule),
    canActivate: [AuthGuard]
  }, {
    path: 'password-reset',
    loadChildren: () => import('./pages/start-pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
    canActivate: [AuthGuard]
  },
  //todo: tabs for mobile devices
  {
    path: 'overview',
    redirectTo: '/view/overview'
    // loadChildren: () => import('./pages/logged-in/overview/overview.module').then( m => m.OverviewPageModule)
  },
  {
    path: 'analytics-statistics',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/analytics/analytics-statistics/analytics-statistics.module').then(m => m.AnalyticsStatisticsPageModule)
  }, {
    path: 'analytics-reports',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/analytics/analytics-reports/analytics-reports.module').then(m => m.AnalyticsReportsPageModule)
  },
  {
    path: 'customers',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/customer/customer.module').then(m => m.CustomerPageModule)
  },
  {
    path: 'customer-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/customer/customer-form/customer-form.module').then(m => m.CustomerFormPageModule)
  },
  {
    path: 'customer-form/:id',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/customer/customer-form/customer-form.module').then(m => m.CustomerFormPageModule)
  },
  {
    path: 'customer-view/:id',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/customer/customer-view/customer-view.module').then(m => m.CustomerViewPageModule)
  },

  {
    path: 'item-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/item/item-list/item-list.module').then(m => m.ItemListPageModule)
  },
  {
    path: 'item-inventory',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/item/item-inventory/item-inventory.module').then(m => m.ItemInventoryPageModule)
  },

  {
    path: 'item-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/item/item-form/item-form.module').then(m => m.ItemFormPageModule)
  },
  // {
  //   path: 'item-form/:id/:category',
  //   canActivate: [AuthService],
  //   loadChildren: () => import('./pages/logged-in/item/item-form/item-form.module').then(m => m.ItemFormPageModule)
  // },
  // {
  //   path: 'add-category-item/:category',
  //   canActivate: [AuthService],
  //   loadChildren: () => import('./pages/logged-in/item/item-form/item-form.module').then(m => m.ItemFormPageModule)
  // },
  // {
  //   path: 'item-form/:category/:id',
  //   canActivate: [AuthService],
  //   loadChildren: () => import('./pages/logged-in/item/item-form/item-form.module').then(m => m.ItemFormPageModule)
  // },
  {
    path: 'discounts/voucher',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/discount/voucher-list/voucher-list.module').then(m => m.VoucherListPageModule)
  }, {
    path: 'discounts/bank',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/discount/bank-discount-list/bank-discount-list.module').then(m => m.BankDiscountListPageModule)
  }, 
  {
    path: 'settings/store',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/store-info/store-info.module').then(m => m.StoreInfoPageModule)
  }, 
  {
    path: 'settings/update-design-layout',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/update-design-layout/update-design-layout.module').then(m => m.UpdateDesignLayoutPageModule)
  },
  {
    path: 'settings/payment-methods',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/payment-method/payment-method.module').then(m => m.PaymentMethodPageModule)
  },
  {
    path: 'settings/shipping-methods',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/shipping-method/shipping-method.module').then(m => m.ShippingMethodPageModule)
  },
  {
    path: 'settings/business-locations',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/business-location/business-location.module').then(m => m.BusinessLocationPageModule)
  },
  {
    path: 'add-business-location',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/business-location/add-business-location/add-business-location.module').then(m => m.AddBusinessLocationPageModule)
  },
  {
    path: 'settings/view-business-location',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/business-location/view-business-location/view-business-location.module').then(m => m.ViewBusinessLocationPageModule)
  },
  /*{
    path: 'add-business-location/vat/:id',
    loadChildren: () => import('./add-business-location/add-business-location.module').then( m => m.AddBusinessLocationPageModule)
  },*/
  {
    path: 'update-business-location/:id',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/business-location/add-business-location/add-business-location.module').then(m => m.AddBusinessLocationPageModule)
  },
  {
    path: 'settings/delivery-zone',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/delivery-zone/delivery-zone.module').then(m => m.DeliveryZonePageModule)
  },
  /*{
    path: 'add-delivery-zone',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/delivery-zone-form/delivery-zone-form.module').then( m => m.DeliveryZonePageModule)
  },*/
  {
    path: 'settings/domains',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/domains/domains.module').then(m => m.DomainsPageModule)
  }, {
    path: 'settings/weblinks',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/weblinks/weblinks.module').then(m => m.WeblinksPageModule)
  }, {
    path: 'settings/opening-hours',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/opening-hours/opening-hours.module').then(m => m.OpeningHoursPageModule)
  },
  {
    path: 'opening-hours-form/:day',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/opening-hours/opening-hours-form/opening-hours-form.module').then(m => m.OpeningHoursFormPageModule)
  },
  {
    path: 'settings/staff-management',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/staff-management/staff-management.module').then(m => m.StaffManagementPageModule)
  }, 
  {
    path: 'change-password',
    loadChildren: () => import('./pages/logged-in/account/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'update-profile',
    loadChildren: () => import('./pages/logged-in/account/update-profile/update-profile.module').then(m => m.UpdateProfilePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'agent-profile',
    loadChildren: () => import('./pages/logged-in/account/agent-profile/agent-profile.module').then(m => m.AgentProfilePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'category-form',
    loadChildren: () => import('./pages/logged-in/category/category-form/category-form.module').then(m => m.CategoryFormPageModule),
    canActivate: [AuthService],
  },
  // {
  //   path: 'vat-form',
  //   canActivate: [AuthService],
  //   loadChildren: () => import('./pages/logged-in/vat-form/vat-form.module').then( m => m.VatFormPageModule)
  // },
  {
    path: 'orders/all',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/order/order-list/order-list.module').then(m => m.OrderListPageModule)
  },
  {
    path: 'order-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/order/order-form/order-form.module').then(m => m.OrderFormPageModule)
  },
  {
    path: 'order-invoice',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/order/order-invoice/order-invoice.module').then(m => m.OrderInvoicePageModule)
  },
  {
    path: 'orders/:category/:id',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/order/order-view/order-view.module').then(m => m.OrderViewPageModule)
  },
  {
    path: 'order-view/:id',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/order/order-view/order-view.module').then(m => m.OrderViewPageModule)
  },

  {
    path: 'no-internet',
    loadChildren: () => import('./pages/errors/no-internet/no-internet.module').then((m) => m.NoInternetPageModule),
    data: {
      name: 'NoInternetPage',
    },
  },

  {
    path: 'ip-blocked-error',
    loadChildren: () => import('./pages/errors/ip-blocked-error/ip-blocked-error.module').then( m => m.IpBlockedErrorPageModule),
    data: {
      name: 'IpBlockedPage',
    },
  },

  {
    path: 'server-error',
    loadChildren: () => import('./pages/errors/server-error/server-error.module').then((m) => m.ServerErrorPageModule),
    data: {
      name: 'ServerErrorPage',
    },
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/errors/not-found/not-found.module').then((m) => m.NotFoundPageModule),
    data: {
      name: 'NotFoundPage',
    },
  },
  {
    path: 'currency',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/currency/currency.module').then( m => m.CurrencyPageModule)
  },
  {
    path: 'settings/currency',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/currency/currency.module').then( m => m.CurrencyPageModule)
  },
  {
    path: 'settings/confirm-plan',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/confirm-plan/confirm-plan.module').then(m => m.ConfirmPlanPageModule)
  },
  {
    path: 'settings/create-tap-account',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/create-tap-account/create-tap-account.module').then(m => m.CreateTapAccountPageModule)
  },
  {
    path: 'app-error',
    loadChildren: () => import('./pages/errors/app-error/app-error.module').then((m) => m.AppErrorPageModule),
  },
  {
    path: 'update-password',
    loadChildren: () => import('./pages/start-pages/update-password/update-password.module').then(m => m.UpdatePasswordPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/start-pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'voucher-view',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/discount/voucher-view/voucher-view.module').then(m => m.VoucherViewPageModule)
  },
  {
    path: 'voucher-form',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/discount/voucher-form/voucher-form.module').then(m => m.VoucherFormPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/start-pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./pages/start-pages/terms-of-use/terms-of-use.module').then(m => m.TermsOfUsePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'setting-menu',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/setting-menu/setting-menu.module').then(m => m.SettingMenuPageModule)
  },
  {
    path: 'discounts/bank/bank-discount-view',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/discount/bank-discount-view/bank-discount-view.module').then(m => m.BankDiscountViewPageModule)
  },
  {
    path: 'payment-method-rates',
    loadChildren: () => import('./pages/logged-in/settings/payment-method-rates/payment-method-rates.module').then( m => m.PaymentMethodRatesPageModule)
  },  
  {
    path: 'settings/seo',
    loadChildren: () => import('./pages/logged-in/settings/seo/seo.module').then( m => m.SeoPageModule)
  },
  {
    path: 'item-variant-form',
    loadChildren: () => import('./pages/logged-in/item/item-variant-form/item-variant-form.module').then( m => m.ItemVariantFormPageModule)
  },
  {
    path: 'settings/create-tap-account',
    canActivate: [AuthService, RoleGuard],
    loadChildren: () => import('./pages/logged-in/settings/create-tap-account/create-tap-account.module').then(m => m.CreateTapAccountPageModule)
  }, 
  
  {
    path: 'support',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/support/ticket-list/ticket-list.module').then( m => m.TicketListPageModule)
  },
  {
    path: 'ticket-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/support/ticket-view/ticket-view.module').then( m => m.TicketViewPageModule)
  },
  {
    path: 'ticket-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/support/ticket-form/ticket-form.module').then( m => m.TicketFormPageModule)
  },
  
  {
    path: 'campaign-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/marketing/campaign/campaign-list/campaign-list.module').then( m => m.CampaignListPageModule)
  },
  {
    path: 'campaign-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/marketing/campaign/campaign-view/campaign-view.module').then( m => m.CampaignViewPageModule)
  },
  {
    path: 'campaign-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/marketing/campaign/campaign-form/campaign-form.module').then( m => m.CampaignFormPageModule)
  },
  {
    path: 'settings/email-config',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/email-config/email-config.module').then( m => m.EmailConfigPageModule)
  },
  {
    path: 'addon-list',
    //canActivate: [AuthGuard],
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/addon/addon-list/addon-list.module').then( m => m.AddonListPageModule)
  },  
  {
    path: 'settings/addon-list',
   // canActivate: [AuthGuard],
   canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/addon/addon-list/addon-list.module').then( m => m.AddonListPageModule)
  }, 
  {
    path: 'addon-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/addon/addon-view/addon-view.module').then( m => m.AddonViewPageModule)
  },
  {
    path: 'bank-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/bank-form/bank-form.module').then( m => m.BankFormPageModule)
  },
  {
    path: 'apps',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/apps/apps.module').then( m => m.AppsPageModule)
  },
  
  {
    path: 'plugn-updates',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/plugn-updates/plugn-updates.module').then( m => m.PlugnUpdatesPageModule)
  },
  {
    path: 'invoice-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/invoice/invoice-list/invoice-list.module').then( m => m.InvoiceListPageModule)
  },
  {
    path: 'invoice-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/invoice/invoice-view/invoice-view.module').then( m => m.InvoiceViewPageModule)
  },

  {
    path: 'store-create',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/store-create/store-create.module').then( m => m.StoreCreatePageModule)
  },
  {
    path: 'item-video',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/item/item-video/item-video.module').then( m => m.ItemVideoPageModule)
  },
  {
    path: 'shipping-method',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/shipping-method/shipping-method.module').then( m => m.ShippingMethodPageModule)
  },
  {
    path: 'flat-rate',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/shipping-method/flat-rate/flat-rate.module').then( m => m.FlatRatePageModule)
  },
  {
    path: 'armada',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/settings/shipping-method/armada/armada.module').then( m => m.ArmadaPageModule)
  },
  {
    path: 'create-shipment',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/order/aramex/create-shipment/create-shipment.module').then( m => m.CreateShipmentPageModule)
  },
  {
    path: 'schedule-pickup',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/order/aramex/schedule-pickup/schedule-pickup.module').then( m => m.SchedulePickupPageModule)
  },
  {
    path: 'select-shipping-method',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/order/select-shipping-method/select-shipping-method.module').then( m => m.SelectShippingMethodPageModule)
  },
  {
    path: 'store-pages',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/store-page/store-page-list/store-page-list.module').then( m => m.StorePageListPageModule)
  },
  {
    path: 'store-page-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/store-page/store-page-view/store-page-view.module').then( m => m.StorePageViewPageModule)
  },
  {
    path: 'store-page-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/store-page/store-page-form/store-page-form.module').then( m => m.StorePageFormPageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/logged-in/blog/blog.module').then( m => m.BlogPageModule)
  },

  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: SelectiveLoadingStrategy })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

