import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController, ToastController } from '@ionic/angular';
//services
import { OrdersService } from 'src/app/services/logged-in/orders.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-order-actions',
  templateUrl: './order-status-actions.component.html',
  styleUrls: ['./order-status-actions.component.scss'],
})
export class OrderStatusActionsComponent implements OnInit {

  public saving = null;

  public order;

  constructor(
    public alertCtrl: AlertController,
    public toastCrl: ToastController,
    public popupCtrl: PopoverController,
    public orderService: OrdersService,
    public authService: AuthService,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() { }

  /**
   * prompt to change order status
   * @param status
   */
  async changeOrderStatus(status) {
    if (status == 5) {

      const alert = await this.alertCtrl.create({
        header: this.translateService.transform('Confirm'),
        message: this.translateService.transform("Are you sure you want to mark it as cancelled?"),
        buttons: [
          {
            text: this.translateService.transform('Cancel'),
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: this.translateService.transform('Okay'),
            handler: () => {
              this.saveOrderStatus(status);
            }
          }
        ]
      });
      alert.present();

    } else {
      this.saveOrderStatus(status);
    }
  }

  /**
  * save order status
  * @param status
  */
  saveOrderStatus(status) {

    this.saving = status;

    this.orderService.updateStatus(this.order.order_uuid, status).subscribe(async response => {

      if (response.operation == 'success') {
        const message = await this.toastCrl.create({
          message: response.message,
          duration: 3000
        });
        message.present();

        this.order.order_status = status;

        this.dismiss({ refresh: true });

      } else {
        this.authService.showAlert(
          this.translateService.transform('Error changing order status'),
          this.translateService.errorMessage(response.message),
          this.order
        );  
      }
    },
      err => this.saving = null,
      () => this.saving = null
    );
  }

  /**
   * close popup
   */
  dismiss(data = {}) {
    this.popupCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }
}
