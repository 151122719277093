import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
//services
import { AuthService } from '@auth0/auth0-angular';
import { PageService } from 'src/app/services/logged-in/page.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-store-page-form',
  templateUrl: './store-page-form.page.html',
  styleUrls: ['./store-page-form.page.scss'],
})
export class StorePageFormPage implements OnInit {

  public form: FormGroup;

  public loading = false;

  public saving: boolean = false; 

  public borderLimit: boolean = false; 

  public page_uuid;

  public model;
  
  constructor(
    public formBuilder: FormBuilder,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public pageService: PageService,
    public translateService: TranslateLabelService
  ) {
  }

  async ngOnInit() {

    /*if(!this.page_uuid)
      this.page_uuid = this.activatedRoute.snapshot.paramMap.get('id');
    
    if(!this.page_uuid) {
      this.initForm();
    } else {
      this.loadData();
    }*/

    this.initForm();
  }

  /**
   * initialize customer form 
   */
  initForm() {
    this.form = this.formBuilder.group({
      title: [this.model?.title, Validators.required],
      title_ar: [this.model?.title_ar, Validators.required],
      description: [this.model?.description, Validators.required],
      description_ar: [this.model?.description_ar, Validators.required],
      sort_number: [this.model?.sort_number],
    });
  }

  /**
   * load customer details
   */
  loadData() {
    this.loading = true;

    this.pageService.view(this.page_uuid).subscribe(response => {
      this.loading = false;

      this.model = response;

      this.initForm();
    });
  }

  /**
   * dismiss popup
   * @param data 
   */
   dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        o.dismiss(data);
      }
    });
  }

  /**
   * save custome details 
   */
  save() {

    if(!this.form || !this.form.valid) {
      return false;
    }
    
    this.saving = true; 
 
    let action; 

    if(this.model) {
      action = this.pageService.update(this.form.value, this.page_uuid);
    } else {
      action = this.pageService.create(this.form.value);
    }
    
    action.subscribe(response => {

      this.saving = false; 

      if (response.operation != 'success') {
        this.alertCtrl.create({
          message: this.translateService.errorMessage(response.message)
        }).then(alert => alert.present());
      } else { 
        
        this.dismiss({
          refresh: true
        });
      }
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
