import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import mixpanel from 'mixpanel-browser';
import { AnalyticsService } from 'src/app/services/analytics.service';
//services
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-bank-form',
  templateUrl: './bank-form.page.html',
  styleUrls: ['./bank-form.page.scss'],
})
export class BankFormPage implements OnInit {

  public loading = false;
  
  public saving = false;

  public form: FormGroup;

  public borderLimit = false;

  constructor(
    public storeService: StoreService,
    public translateService: TranslateLabelService,
    public fb: FormBuilder,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public modelCtrl: ModalController,
    public authService: AuthService,
    public analytics: AnalyticsService,
  ) { }


  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Update bank account page'
    });
  }

  ngOnInit() {
    
    if (!this.authService.store.wallet_id) 
    {
      return this.close();
    }

		this.analytics.page('Update bank account page');

    if (!this.authService.store) {
      this.loadData();
    } else {
      this.initForm();
    }
  }
  
  initForm() {

    this.form = this.fb.group({
      iban: [this.authService.store.iban || '', Validators.required],
    });
  }
  
  /**
   * load store detail
   */
   loadData() {
    this.loading = true;

    this.storeService.detail().subscribe(response => {

      this.authService.store = response;

      if (!this.authService.store.wallet_id) {
        return this.close();
      }

      this.initForm();
    },
      err => this.loading = false,
      () => this.loading = false,
    );
  }

  updateModel() {
    this.authService.store.iban = this.form.value.iban;
  }
  
  /**
   * save form
   */
   save() {

    if (!this.form || this.form.invalid) {
      return false;
    }

    this.updateModel();

    this.saving = true;
    
    this.storeService.updateBankAccount(this.authService.store).subscribe(res => {

      if(res.operation == 'success') {

        this.toastCtrl.create({
          message: this.authService.errorMessage(res.message),
          duration: 3000
        }).then(toast => {
          toast.present();
        });
        
        this.close('success');
      }
      else
      {
        this.alertCtrl.create({
          message: this.authService.errorMessage(res.message)
        }).then(alert => {
          alert.present();
        });
      }

      // this.navCtrl.navigateForward(['settings/payment-methods']);

    },
      error => this.saving = false,
      () => this.saving = false,
    );
  }
  
  /**
   * close page
   */
   close(data = '') {
    this.modelCtrl.getTop().then(o => {
      if (o) {
        o.dismiss(data);
      }
    });
  }
  
  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

}
