import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import {
  AlertController,
  ModalController,
  Platform,
  PopoverController,
  IonReorderGroup
} from "@ionic/angular";
import * as $ from 'jquery';
// services
import { ItemsService } from 'src/app/services/logged-in/items.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from "../../../../services/event.service";
import { CategoryService } from 'src/app/services/category.service';
// components
import { CatalogActionsComponent } from 'src/app/components/catalog-actions/catalog-actions.component';
// pages
import { ItemInventoryPage } from "../item-inventory/item-inventory.page";
import { ItemFormPage } from "../item-form/item-form.page";
import { CategoryFormPage } from '../../category/category-form/category-form.page';
import { CategoryActionComponent } from "../../../../components/category-action/category-action";
import { AnalyticsService } from 'src/app/services/analytics.service';


@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.page.html',
  styleUrls: ['./item-list.page.scss'],
})
export class ItemListPage implements OnInit {

  public categories = [];
  public items = [];
  public hideAllCat = false;
  public perPageCount = 20;
  public currentPage = 0;
  public totalPages = 0;
  public totalCount = 0;

  public itemPerPageCount = 20;
  public itemCurrentPage = 0;
  public itemTotalPages = 0;
  public itemTotalCount = 0;
  public hiddenContainer = [];
  public search: string = null;

  public loading = false;
  public loadMore = false;
  public loadingMore = null;
  public itemExist = 0;
  public borderLimit = false;

  public listItems: any;

  public uncategorizedItems = [];

  public currentPageUncategorized = 0;
  public totalPageUncategorized = 0;

  public loadingMoreUncategorizedItems: boolean = false;

  public hideUnCategorizedItems: boolean = false;
  public isMobile = false;

  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;

  constructor(
    public router: Router,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public authService: AuthService,
    public alertCtrl: AlertController,
    public itemServices: ItemsService,
    public platform: Platform,
    public categoryService: CategoryService,
    public analytics: AnalyticsService,
    public translateService: TranslateLabelService,
    public eventService: EventService
  ) {
    this.eventService.storeChanged$.subscribe(data => {
      this.loadData();
    });

    if (this.platform.is('capacitor') && this.platform.is('mobile')) {
      this.isMobile = true;
    }
  }

  async onRenderItems(event, category, items) {

    event.detail.complete(true);
    
    const updatedList = [];

    $('.cat_item_list_' + category.category_id).each((i, element) => {
      updatedList[i] = $(element).attr('id');
    });

    this.itemServices.updateSortNumber(updatedList).subscribe(res => {
    });
  }

  async onRenderCategory(event) {

    event.detail.complete();
    const updatedList = [];
    $('.cat_list').each((i, element) => {
      updatedList[i] = $(element).attr('id');
    });

    this.categoryService.updateSortNumber(updatedList).subscribe(res => {
    });
  }

  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'item list page'
    });
  }

  async ngOnInit() {

    this.analytics.page('Item list page');

    this.loadData();

    this.eventService.removeEvent$.subscribe(item => {
      this.removeItem(item);
    });

    this.eventService.reloadStock$.subscribe(item => {
      this.loadData();
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  /**
   * load categories
   */
  loadData(silent = true) {

    if(!silent)
      this.loading = true;

    const expand = 'allItems,allItems.item,allItems.item.extraOptions,allItems.item.itemImage,allItems.item.currency,pagination';

    this.categoryService.list(1, this.search, 5, expand).subscribe((response) => {

      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'), 10);
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'), 10);
      this.categories = response.body;

      // just to show hide update inventory button in case item exist then show else hide
      if (this.categories && this.categories.length > 0) {
        this.itemExist = this.categories.find(cat => cat.allItems.length > 0);
      }
    },
      err => this.loading = false,
      () => this.loading = false,
    );

    this.loadUncategorizedItems();
  }

  /**
   * load categories
   */
  loadItemData() {
    this.loading = true;

    const expand = 'extraOptions,itemImage,currency&type=all';

    this.itemServices.list(1, this.search, this.perPageCount, expand).subscribe((response) => {
      this.itemPerPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'), 10);
      this.itemCurrentPage = parseInt(response.headers.get('X-Pagination-Current-Page'), 10);
      this.itemTotalPages = parseInt(response.headers.get('X-Pagination-Page-Count'), 10);
      this.itemTotalCount = parseInt(response.headers.get('X-Pagination-Total-Count'), 10)
      this.items = response.body;
    },
      err => this.loading = false,
      () => this.loading = false,
    );
  }

  /**
   * load uncategorized items
   */
  loadUncategorizedItems() {

    this.loadingMoreUncategorizedItems = true;

    this.currentPageUncategorized = 1;

    const expand = 'extraOptions,itemImage,currency&type=all&category_id=-1';

    this.itemServices.list(this.currentPageUncategorized, this.search, this.perPageCount, expand).subscribe((response) => {

      this.loadingMoreUncategorizedItems = false;

      this.currentPageUncategorized = parseInt(response.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPageUncategorized = parseInt(response.headers.get('X-Pagination-Page-Count'), 10);

      this.uncategorizedItems = response.body;

      //event.target.complete();

    }, () => {
      this.loadingMoreUncategorizedItems = false;
    });
  }

  /**
   * load more uncategorized items
   */
  loadMoreUncategorizedItems() {
    this.loadingMoreUncategorizedItems = true;

    this.currentPageUncategorized++;

    const expand = 'extraOptions,itemImage,currency&type=all&category_id=-1';

    this.itemServices.list(this.currentPageUncategorized, this.search, this.perPageCount, expand).subscribe((response) => {

      this.loadingMoreUncategorizedItems = false;

      this.currentPageUncategorized = parseInt(response.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPageUncategorized = parseInt(response.headers.get('X-Pagination-Page-Count'), 10);

      this.uncategorizedItems = this.items.concat(response.body);

      //event.target.complete();

    }, () => {
      this.loadingMoreUncategorizedItems = false;
    });
  }

  /**
   * update publish status
   * @param item
   */
  publish(item) {

    this.itemServices.updateStatus(item.item_uuid).subscribe(res => {
      if (res.operation != 'success') {
        this.alertCtrl.create({
          message: this.authService.errorMessage(res.message)
        }).then(alert => alert.present());
      } else {
        this.loadData();
      }
    });
  }

  /**
   * order action based on order status
   * @param event
   * @param cat
   */
  async catActions(event, cat) {
    event.preventDefault();
    event.stopPropagation();

    /*window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);*/

    const popover = await this.popoverCtrl.create({
      component: CategoryActionComponent,
      animated: true,
      event,
      translucent: true
    });
    /*popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });*/
    await popover.present();

    const { data } = await popover.onDidDismiss();

    if (data && data.action == 'delete') {
      this.confirmCategoryDelete(cat);
    } else if (data && data.action == 'edit') {
      this.openCategoryForm(cat.category_id);
    } else if (data && data.action == 'add-item') {
      this.updateAddUpdateItem(null, cat.category_id);
    }
  }

  confirmCategoryDelete(category) {
    this.alertCtrl.create({
      message: this.translateService.transform('Are you sure you want to remove this category?'),
      buttons: [
        {
          text: this.translateService.transform('Yes'),
          role: 'Yes',
          handler: () => {
            this.loading = true;

            this.categoryService.delete(category).subscribe(res => {
              if (res.operation != 'success') {
                this.alertCtrl.create({
                  message: this.authService.errorMessage(res.message)
                }).then(alert => alert.present());
              } else {
                /*this.alertCtrl.create({
                  message: this.authService.errorMessage(res.message)
                }).then(alert => alert.present());*/

                this.loadData();
              }
            },
              err => this.loading = false,
              () => this.loading = false
            );
          }
        },
        {
          text: this.translateService.transform('No'),
          role: 'Cancel',
        }
      ]
    }).then(alert => alert.present());
  }

  /**
   * remove item
   * @param data
   */
  async removeItem(data) {
    // event.preventDefault();
    this.alertCtrl.create({
      message: this.translateService.transform('Are you sure you want to remove this item?'),
      buttons: [
        {
          text: this.translateService.transform('Yes'),
          role: 'Yes',
          handler: () => {
            this.loading = true;
            this.itemServices.delete(data).subscribe(res => {
              if (res.operation != 'success') {
                this.alertCtrl.create({
                  message: this.authService.errorMessage(res.message)
                }).then(alert => alert.present());
              } else {
                this.loadData();
              }
            },
              err => this.loading = false,
              () => this.loading = false
            );
          }
        },
        {
          text: this.translateService.transform('No'),
          role: 'Cancel',
        }
      ]
    }).then(alert => alert.present());
  }

  /**
   * load more data on scroll to bottom
   * @param event
   */
  doInfinite(event = null) {

    if (this.currentPage >= this.totalPages) {

      if (event && event.target)
        event.target.complete();

      return null;
    }

    this.loadMore = true;

    this.currentPage++;

    // const expand = 'pagination,allItems,allItems.extraOptions,allItems.itemImage,allItems.currency';
    const expand = 'allItems,allItems.item,allItems.item.extraOptions,allItems.item.itemImage,allItems.item.currency,pagination';

    this.categoryService.list(this.currentPage, this.search, this.perPageCount, expand).subscribe((response) => {

      this.loadMore = false;

      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'), 10);
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'), 10);
      this.categories = this.categories.concat(response.body);

      if (event && event.target) {
        event.target.complete();
      }

    }, () => {
      this.loadMore = false;
    });
  }

  /**
   * load more data on scroll to bottom
   * @param event
   */
  ItemsDoInfinite(event = null) {

    if (this.itemCurrentPage >= this.itemTotalPages) {

      if (event && event.target) {
        event.target.complete();
      }
      
      return null;
    }

    this.loading = true;

    this.currentPage++;

    const expand = 'extraOptions,itemImage,currency&type=all';

    this.itemServices.list(this.currentPage, this.search, this.perPageCount, expand).subscribe((response) => {

      this.loading = false;

      this.itemPerPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'), 10);
      this.itemCurrentPage = parseInt(response.headers.get('X-Pagination-Current-Page'), 10);
      this.itemTotalPages = parseInt(response.headers.get('X-Pagination-Page-Count'), 10);
      this.itemTotalCount = parseInt(response.headers.get('X-Pagination-Total-Count'), 10);

      this.items = this.items.concat(response.body);

      if (event && event.target) {
        event.target.complete();
      }

    }, () => {
      this.loading = false;
    });
  }

  showCategoryOption(event) {

  }

  /**
   * catalog action buttons /options
   * @param event
   */
  async openCatalogOptions(event) {

    event.preventDefault();
    event.stopPropagation();

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const popover = await this.popoverCtrl.create({
      component: CatalogActionsComponent,
      event: event,
      translucent: true
    });
    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await popover.present();
  }

  /**
   * catalog action buttons /options
   * @param event
   */
  async updateInventory(event) {

    event.preventDefault();
    event.stopPropagation();

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: ItemInventoryPage,
      cssClass: 'popup-modal',
    });
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await modal.present();
  }

  /**
   * add updte event
   * @param event
   * @param item
   * @param category_id
   */
  async updateAddUpdateItem(event = null, category_id = null, item = null) {

    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: ItemFormPage,
      cssClass: 'popup-modal',
      componentProps: {
        category_id: (category_id) ? category_id : null,
        item_id: (item && item.item_id) ? item.item_id : null
      }
    });
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }

      if(e.data && e.data.refresh) {
        this.loadData(true);
      }
    });
    await modal.present();
  }

  /**
   * update item
   * @param event
   */
  async updateEvent(event, item) {

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: ItemFormPage,
      cssClass: 'popup-modal',
      componentProps: {
        item_id: item.item_uuid
      }
    });
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }

      if (e.data && e.data.refresh) {
        this.loadData();
      }
    });
    await modal.present();
  }

  /**
   * update item
   * @param event
   */
  async openCategoryForm(category_id = null) {

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: CategoryFormPage,
      componentProps: {
        category_id
      },
      cssClass: 'popup-modal'
    });
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }

      if (e.data && e.data.refresh) {
        this.loadData();
      }
    });
    await modal.present();
  }

  /**
   * hide category image on image error
   * @param category
   */
  onImageError(category) {
    category.category_image = null;
  }

  /**
   * toggle visiblity for uncategorized items
   * @param event
   */
  showHideUncategorized(event) {

    event.preventDefault();
    event.stopPropagation();

    this.hideUnCategorizedItems = !this.hideUnCategorizedItems;
  }

  showHideContainer(event, category) {

    event.preventDefault();
    event.stopPropagation();

    if (this.hiddenContainer.includes(category.category_id)) {
      this.hiddenContainer = this.hiddenContainer.filter(value => value != category.category_id);
    } else {
      this.hiddenContainer.push(category.category_id);
    }
  }

  checkToHideContainer(category) {
    // if (this.hideAllCat) {
    //   return true;
    // }
    return (this.hiddenContainer.includes(category.category_id));
  }

  /**
   * load more category item
   * @param category
   */
  loadMoreCategoryItems(category) {
    let page = ++category.pagination.currentPage;
    this.loadingMore = category.category_id;
    const expand = 'item,item.currency,item.extraOptions,item.itemImage,currency';
    const query = '&category_id=' + category.category_id;
    this.categoryService.itemList(page, null, category.pagination.pagePage, expand, query).subscribe((response) => {
      this.loadingMore = null;

      this.categories.map(cat => {
        if (cat.category_id == category.category_id) {
          if (response.body && response.body.length > 0) {
            response.body.map(items => cat.allItems.push(items));
          }
          cat.pagination.currentPage = cat.pagination.currentPage++;
        }
      });
    }, () => {
      this.loading = false;
    });
  }

  edit(event, category) {
    event.preventDefault();
    event.stopPropagation();
    this.openCategoryForm(category.category_id);
  }

  hideAll(e) {
    if (!this.hideAllCat) {
      this.categories.map(res => {
        if (!this.hiddenContainer.includes(res.category_id)) {
          this.hiddenContainer.push(res.category_id);
        }
      });
    } else {
      this.hiddenContainer = [];
    }

    this.hideAllCat = !this.hideAllCat;
    this.hideUnCategorizedItems = this.hideAllCat;

    if (this.hideAllCat) {
      if (this.categories.length < 6) {
        if (this.itemCurrentPage < this.itemTotalPages) {
          this.doInfinite(null);
        }
      }
    }
  }

  addNewTab(event) {
    this.updateAddUpdateItem(event, null, null);
  }
}
