import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MashkorPageRoutingModule } from './mashkor-routing.module';

import { MashkorPage } from './mashkor.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MashkorPageRoutingModule
  ],
  declarations: [MashkorPage]
})
export class MashkorPageModule {}
