import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class AreaService {

  public endpoint: string = "areas";

  constructor(
    public _authhttp: AuthHttpService,
    public auth: AuthService
  ) { }

  /**
   * list area
   * @param page 
   * @param query 
   * @returns 
   */
  list(page = null, query = ''): Observable<any> {
    let url = `${this.endpoint}?${query}`;

    if(page) 
      url += `&page=${page}`;

    return this._authhttp.getRaw(url);
  }

  /**
   * load delivery areas
   * @param page 
   * @param query 
   * @returns 
   */
  listDeliveryAreas(page = null, query = ''): Observable<any> {

    let url = `${this.endpoint}/delivery-areas?${query}`;

    if(page) 
      url += `&page=${page}`;

    return this._authhttp.getRaw(url);
  }

  /**
   * Get city by location
   * @param latitude 
   * @param longitude 
   * @returns 
   */
  getCityByLocation(latitude, longitude): Observable<any> {
    let url = `${this.endpoint}/city-by-location?latitude=${latitude}&longitude=${longitude}`;
    return this._authhttp.get(url);
  }

  /**
   * Get areas
   * @param city_id 
   * @param keyword 
   * @param page 
   * @returns 
   */
  getAreas(city_id, keyword = '', page = 1): Observable<any> {
    const url = `${this.endpoint}/city-areas/${city_id}?keyword=${keyword}&page=${page}`;
    return this._authhttp.getRaw(url);
  }

  /**
   * Get country cities
   * @param country_id 
   * @param keyword 
   * @param page 
   * @returns 
   */
  getCountryCities(country_id, keyword = '', page = 1): Observable<any> {
    const url = `${this.endpoint}/country-cities/${country_id}?keyword=${keyword}&page=${page}`;
    return this._authhttp.getRaw(url);
  }

  /**
   * Get state cities
   * @param state_id 
   * @param keyword 
   * @param page 
   * @returns 
   */
  getStateCities(state_id, keyword = '', page = 1): Observable<any> {
    const url = `${this.endpoint}/state-cities/${state_id}?keyword=${keyword}&page=${page}`;
    return this._authhttp.getRaw(url);
  }

  /**
   * Get country states
   * @param country_id 
   * @param keyword 
   * @returns 
   */
  getCountryStates(country_id, keyword = ''): Observable<any> {
    const url = `${this.endpoint}/country-states/${country_id}?keyword=${keyword}`;
    return this._authhttp.get(url);
  }
}
