import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DeliveryZoneCitiesPageRoutingModule } from './delivery-zone-cities-routing.module';

import { DeliveryZoneCitiesPage } from './delivery-zone-cities.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { DeliveryZoneTaxModule } from 'src/app/components/delivery-zone-tax/delivery-zone-tax.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    DeliveryZoneTaxModule,
    DeliveryZoneCitiesPageRoutingModule,
    ComponentsModule,
    TranslateModule.forChild()
  ],
  declarations: [DeliveryZoneCitiesPage]
})
export class DeliveryZoneCitiesPageModule {}
