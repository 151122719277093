import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CategoryFormPageRoutingModule } from './category-form-routing.module';

import { CategoryFormPage } from './category-form.page';
import {ComponentsModule} from "../../../../components/components.module";
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModalModule } from 'src/app/components/loading-modal/loading-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CategoryFormPageRoutingModule,
    ComponentsModule,
    ReactiveFormsModule,
    LoadingModalModule,
    TranslateModule.forChild()
  ],
  declarations: [CategoryFormPage]
})
export class CategoryFormPageModule {}
