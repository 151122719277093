import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ItemFormPageRoutingModule } from './item-form-routing.module';

import { ItemFormPage } from './item-form.page';
import {ComponentsModule} from '../../../../components/components.module';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ImageUploadModule} from 'src/app/components/image-upload/image-upload.module';
import {ItemOptionsModule} from "../../../../components/item-options/item-options.module";
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModalModule } from 'src/app/components/loading-modal/loading-modal.module';
import { ItemVariantsModule } from 'src/app/modals/item-variants/item-variants.module';
import { ItemVideoPageModule } from '../item-video/item-video.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        ItemFormPageRoutingModule,
        ComponentsModule,
        CKEditorModule,
        ImageUploadModule,
        ItemOptionsModule,
        ItemVariantsModule,
        ItemVideoPageModule,
        LoadingModalModule,
        TranslateModule.forChild(),
    ],
  declarations: [ItemFormPage]
})
export class ItemFormPageModule {}
