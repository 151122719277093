import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { StorePageFormPageRoutingModule } from './store-page-form-routing.module';

import { StorePageFormPage } from './store-page-form.page';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CKEditorModule,
    IonicModule,
    StorePageFormPageRoutingModule
  ],
  declarations: [StorePageFormPage]
})
export class StorePageFormPageModule {}
