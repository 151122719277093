import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, Validator } from '@angular/forms';
import { AlertController, ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
//components
import { CountryModalDropdownComponent } from 'src/app/components/country-modal-dropdown/country-modal-dropdown.component';
// models
import { Restaurant } from 'src/app/model/restaurant';
import { AwsService } from 'src/app/services/aws.service';
// services
import { StoreService } from 'src/app/services/logged-in/store.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { AuthService } from '../../../../services/auth.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { RestaurantBillingAddressService } from 'src/app/services/logged-in/restaurant-billing-address.service';
import { EventService } from 'src/app/services/event.service';


@Component({
  selector: 'app-create-tap-account',
  templateUrl: './create-tap-account.page.html',
  styleUrls: ['./create-tap-account.page.scss'],
})
export class CreateTapAccountPage implements OnInit {

  public store: Restaurant;

  public loading = false;
  public saving = false;
  
  public topForm: FormGroup;
  public addressForm: FormGroup;
  public businessDetailForm: FormGroup; 
  public documentsForm: FormGroup; 

  public borderLimit = false;

  //'Kuwait', 'Oman', 'kuwait', 'oman'
  public countriesForBusinessType = [
    'Qatar', 'qatar'
  ];

  public segment = 'individual';

  public countryList = [];

  public step: number = 1;

  public bucketUrl; 

  constructor(
    public storeService: StoreService,
    public translateService: TranslateLabelService,
    public fb: FormBuilder,
    public alertCtrl: AlertController,
    public modelCtrl: ModalController,
    public popoverController: PopoverController,
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    public authService: AuthService,
    public eventService: EventService,
    public awsService: AwsService,
    //public countryService: CountryService,
    public restaurantBillingAddressService: RestaurantBillingAddressService,
    public analytics: AnalyticsService
  ) { }

  ngOnInit() { 
  }

  ionViewDidEnter() {

    this.step = 1;

    this.bucketUrl = this.awsService.cloudinaryUrl + 'c_scale,h_160,w_160/restaurants/';

		this.analytics.page('Create Tap account page');

    //if (!this.store) {
      this.loadData();
    //} else {
    //  this.initForm();
    //}

    /*this.restaurantBillingAddressService.list(1, null).subscribe(res => {
      const address = res.length > 0 ? res[0]: null;
      this.initAddressForm(address);
    });*/
  }

  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Create Tap account page'
    });
  }

  back() {
    this.step--;
  }

  next() {
    switch (this.step) {
      case 1:
        this.step = 2;
        break;
      case 2:
        this.saveAddress();
        break;
      case 3:
        this.saveBusinessDetail();
        break;  
      case 4: 
        this.saveDocs();
        break;  
      case 5: 
        this.submitForReview();  
    } 
    //todo: last step to review all info and submit? to add in tap queue?
  }

  initAddressForm(storeBillingAddress = null) {
    
    this.addressForm = this.fb.group({
      rba_uuid: [storeBillingAddress?.rba_uuid],
      country_id: [storeBillingAddress?.country_id, Validators.required],
      countryName: [this.translateService.langContent(storeBillingAddress?.country?.country_name, storeBillingAddress?.country?.country_name_ar), Validators.required],
      recipient_name: [storeBillingAddress?.recipient_name, Validators.required],
      address_1: [storeBillingAddress?.address_1, Validators.required],
      address_2: [storeBillingAddress?.address_2],
      po_box: [storeBillingAddress?.po_box],
      district: [storeBillingAddress?.district],
      city: [storeBillingAddress?.city, Validators.required],
      state: [storeBillingAddress?.state],
      zip_code: [storeBillingAddress?.zip_code],
    });
  }

  saveAddress() {

    if (!this.addressForm || this.addressForm.invalid) {
      return false;
    }
 
    this.saving = true;
    
    let action; 

    if(this.addressForm.value.rba_uuid) {
      action = this.restaurantBillingAddressService.update(this.addressForm.value, this.addressForm.value.rba_uuid);
    } else {
      action = this.restaurantBillingAddressService.create(this.addressForm.value)
    }

    action.subscribe(res => {

      if(res.operation == 'success') {
        this.step = 3;

        this.store.storeBillingAddress = this.addressForm.value;
      }
      else
      {
        this.alertCtrl.create({
          message: this.authService.errorMessage(res.message)
        }).then(alert => {
          alert.present();
        });
      }

      // this.navCtrl.navigateForward(['settings/payment-methods']);

    },
      error => this.saving = false,
      () => this.saving = false,
    );
  }

  initBusinessDetailForm() {

    let phone = null; 

    if(this.store.owner_number) {
      phone = this.store.owner_number.replace("+" + this.store.owner_phone_country_code, "").replace(" ", "");
    } 

    /*if(this.store.business_type == "corp") {
      this.segment = "corporate";
    } else {  
      this.segment = "individual";
    }*/

    this.businessDetailForm = this.fb.group({
      owner_name_title: [this.store.owner_name_title, Validators.required],
      owner_middle_name: [this.store.owner_middle_name, Validators.required],
      owner_nationality: [this.store.owner_nationality, Validators.required],
      owner_date_of_birth: [this.store.owner_date_of_birth, Validators.required],
      tax_number: [this.store.tax_number],

      swift_code: [this.store.swift_code],
      account_number: [this.store.account_number, Validators.required],
      license_type: [this.store.license_type, Validators.required],

      first_name: [this.store.owner_first_name || '', Validators.required],
      last_name: [this.store.owner_last_name || '', Validators.required],
      email_address: [this.store.owner_email || '', Validators.required],
      phone: [phone, Validators.required],
      phone_country_code: [this.store.owner_phone_country_code || '', Validators.required],
      business_name: [this.store.company_name || '', Validators.required],
      vendor_section: [this.store.vendor_sector || '', Validators.required],
      business_type: [{
        value: 'corp', 
        /*this.store.business_type ||  disable: (
          this.authService.currency_pref == 'SAR' || 
          this.authService.currency_pref == 'BHD'
        )*/
      }, Validators.required],
      license_number: [this.store.license_number, Validators.required],
      iban: [this.store.iban || '', Validators.required],
    });

    // set on page load
    /*if (this.store.business_type == 'corp') {
      this.topForm.controls.authorized_signatory.setValidators([Validators.required]);
    } else {
      this.topForm.controls.authorized_signatory.clearValidators();
    }

    this.topForm.controls.business_type.valueChanges.subscribe(res => {

      if (res == 'corp') { 
        this.topForm.controls.authorized_signatory.setValidators([Validators.required]);
      } else if (res == 'ind') { 
        this.topForm.controls.authorized_signatory.clearValidators();
      }

      this.topForm.controls.authorized_signatory.updateValueAndValidity();
      this.topForm.markAsDirty();
    });*/

    //Qatar - Kuwait and Oman users can create individual tap account only

    //if not Qatar - Kuwait and Oman business_type == corp

    if(this.countriesForBusinessType.indexOf(this.authService.store.country.country_name) == -1) {
      this.businessDetailForm.controls.business_type.setValue('corp');
      this.businessDetailForm.controls.business_type.updateValueAndValidity();
    }
  }

  initDocumentsForm() {
    this.documentsForm = this.fb.group({
      front_side_national_id: [this.store.identification_file_front_side, Validators.required],
      back_side_national_id: [this.store.identification_file_back_side, Validators.required],
      license_copy: [this.store.commercial_license_file, Validators.required],
      iban_certificate: [this.store.iban_certificate_file, Validators.required],
      authorized_signatory: [this.store.authorized_signature_file, Validators.required],
    });
  }

  initForm() {

    let phone = null; 

    if(this.store.owner_number) {
      phone = this.store.owner_number.replace("+" + this.store.owner_phone_country_code, "").replace(" ", "");
    } 

    if(this.store.business_type == "corp") {
      this.segment = "corporate";
    } else {  
      this.segment = "individual";
    }

    this.topForm = this.fb.group({
      owner_name_title: [this.store.owner_name_title, Validators.required],
      owner_middle_name: [this.store.owner_middle_name, Validators.required],
      owner_nationality: [this.store.owner_nationality, Validators.required],
      owner_date_of_birth: [this.store.owner_date_of_birth, Validators.required],
      tax_number: [this.store.tax_number],

      swift_code: [this.store.swift_code],
      account_number: [this.store.account_number, Validators.required],
      license_type: [this.store.license_type, Validators.required],

      first_name: [this.store.owner_first_name || '', Validators.required],
      last_name: [this.store.owner_last_name || '', Validators.required],
      email_address: [this.store.owner_email || '', Validators.required],
      phone: [phone, Validators.required],
      phone_country_code: [this.store.owner_phone_country_code || '', Validators.required],
      business_name: [this.store.company_name || '', Validators.required],
      vendor_section: [this.store.vendor_sector || '', Validators.required],
      business_type: [{
        value: this.store.business_type || 'corp', 
        /*disable: (
          this.authService.currency_pref == 'SAR' || 
          this.authService.currency_pref == 'BHD'
        )*/
      }, Validators.required],
      license_number: [this.store.license_number, Validators.required],
      iban: [this.store.iban || '', Validators.required],
      // files
      front_side_national_id: [this.store.identification_file_front_side, Validators.required],
      back_side_national_id: [this.store.identification_file_back_side, Validators.required],
      license_copy: [this.store.commercial_license_file, Validators.required],
      iban_certificate: [this.store.iban_certificate_file, Validators.required],
      authorized_signatory: [this.store.authorized_signature_file, Validators.required],
    });

    // set on page load
    /*if (this.store.business_type == 'corp') {
      this.topForm.controls.authorized_signatory.setValidators([Validators.required]);
    } else {
      this.topForm.controls.authorized_signatory.clearValidators();
    }

    this.topForm.controls.business_type.valueChanges.subscribe(res => {

      if (res == 'corp') { 
        this.topForm.controls.authorized_signatory.setValidators([Validators.required]);
      } else if (res == 'ind') { 
        this.topForm.controls.authorized_signatory.clearValidators();
      }

      this.topForm.controls.authorized_signatory.updateValueAndValidity();
      this.topForm.markAsDirty();
    });*/

    //Qatar - Kuwait and Oman users can create individual tap account only

    //if not Qatar - Kuwait and Oman business_type == corp

    if(this.countriesForBusinessType.indexOf(this.authService.store.country.country_name) == -1) {
      this.topForm.controls.business_type.setValue('corp');
      this.topForm.controls.business_type.updateValueAndValidity();
    }
  }

  /**
   * open popup to select country
   * @param ev 
   */
  async selectNationality(ev: any) {
    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const popover = await this.modelCtrl.create({
      component: CountryModalDropdownComponent,
      cssClass: 'country-custom-modal',
      //event: ev,
      //translucent: true,
      // mode: "ios",
      showBackdrop: false
    }); 
    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await popover.present();

    const { data } = await popover.onDidDismiss();

    if (data && data) {

      this.businessDetailForm.controls.owner_nationality.setValue(data.iso);
      this.businessDetailForm.controls.owner_nationality.markAsDirty();

      //this.businessDetailForm.controls.nationality.setValue(this.translateService.langContent(data.country_name, data.country_name_ar));
      //this.businessDetailForm.controls.country.markAsDirty();

      this.businessDetailForm.updateValueAndValidity();
    }
  }

  /**
   * open popup to select country
   * @param ev 
   */
  async presentPopover(ev: any) {

    ev.preventDefault();
    ev.stopPropagation();

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const popover = await this.modelCtrl.create({
      component: CountryModalDropdownComponent,
      cssClass: 'country-custom-modal',
      //event: ev,
      //translucent: true,
      // mode: "ios",
      showBackdrop: false
    });
    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await popover.present();

    const { data } = await popover.onDidDismiss();
 
    if (data) {

      this.addressForm.controls.country_id.setValue(data.country_id);
      this.addressForm.controls.country_id.markAsDirty();

      this.addressForm.controls.countryName.setValue(this.translateService.langContent(data.country_name, data.country_name_ar));
      this.addressForm.controls.countryName.markAsDirty();

      this.addressForm.updateValueAndValidity();
    }
  }

  /**
   * load store detail
   */
  loadData() {
    this.loading = true;

    const expand = 'tapRequirements,currency,countryByOwnerCountryCode,storeBillingAddress,storeBillingAddress.country';

    this.storeService.detail(expand).subscribe(response => {
      
      this.store = response;

      if(!this.store.tapRequirements) {
        
        this.alertCtrl.create({
          header: this.translateService.transform("Sorry..."),
          message: this.translateService.transform("Tap not available in store's selected country!"),
          buttons: [
            this.translateService.transform("Okay")
          ]
        }).then(a => {
          a.present();
        });

        this.navCtrl.back();

        return false;
      }

      /*if(this.store.business_type != "corp") {

        this.alertCtrl.create({
          header: this.translateService.transform("Sorry..."),
          message: this.translateService.transform("Unfortunately, right now we are not accepting any businesses without a license in Kuwait. If you have business licence please change store business type to corporate and try again!"),
          buttons: [
            this.translateService.transform("Okay")
          ]
        }).then(a => {
          a.present();
        });

        this.navCtrl.back();

        return false;
      }*/

      this.initBusinessDetailForm();
      this.initDocumentsForm();

      this.initAddressForm(response.storeBillingAddress)
    },
    err => this.loading = false,
    () => this.loading = false);
  }
  
  setPhoneNumber(event) {

    this.businessDetailForm.controls.phone.setValue(event.phone_number);
    this.businessDetailForm.controls.phone_country_code.setValue(event.country_code);
    
    this.businessDetailForm.controls.phone.markAsDirty();
    this.businessDetailForm.controls.phone_country_code.markAsDirty();

    this.businessDetailForm.controls.phone.updateValueAndValidity();
    this.businessDetailForm.controls.phone_country_code.updateValueAndValidity();
  }

  updateModel() {
    this.store.owner_first_name = this.topForm.value.first_name;
    this.store.owner_last_name = this.topForm.value.last_name;
    this.store.owner_email = this.topForm.value.email_address;
    this.store.owner_number = this.topForm.value.phone;
    this.store.owner_phone_country_code = this.topForm.value.phone_country_code;
    this.store.company_name = this.topForm.value.business_name;
    this.store.vendor_sector = this.topForm.value.vendor_section;
    this.store.business_type = this.topForm.value.business_type;
    this.store.identification_file_front_side = this.topForm.value.front_side_national_id;
    this.store.identification_file_back_side = this.topForm.value.back_side_national_id;
    this.store.license_number = this.topForm.value.license_number;
    this.store.commercial_license_file = this.topForm.value.license_copy;
    this.store.authorized_signature_file = this.topForm.value.authorized_signatory;
    this.store.iban_certificate_file = this.topForm.value.iban_certificate;
    this.store.iban = this.topForm.value.iban;

    this.store.owner_date_of_birth = format(parseISO(this.topForm.controls.owner_date_of_birth.value), 
      'yyyy-MM-dd');//, { timeZone: '+3:30' }

    this.store.owner_name_title = this.topForm.value.owner_name_title;
    this.store.owner_middle_name = this.topForm.value.owner_middle_name;
    this.store.owner_nationality = this.topForm.value.owner_nationality;
    this.store.tax_number = this.topForm.value.tax_number;
    this.store.swift_code = this.topForm.value.swift_code;
    this.store.account_number = this.topForm.value.account_number;
    this.store.license_type = this.topForm.value.license_type;
  }

  updateModelFromDocumentsForm() {
    this.store.identification_file_front_side = this.documentsForm.value.front_side_national_id;
    this.store.identification_file_back_side = this.documentsForm.value.back_side_national_id;
    this.store.commercial_license_file = this.documentsForm.value.license_copy;
    this.store.authorized_signature_file = this.documentsForm.value.authorized_signatory;
    this.store.iban_certificate_file = this.documentsForm.value.iban_certificate;
  }

  updateModelFromBusinessDetail() {
    this.store.owner_first_name = this.businessDetailForm.value.first_name;
    this.store.owner_last_name = this.businessDetailForm.value.last_name;
    this.store.owner_email = this.businessDetailForm.value.email_address;
    this.store.owner_number = this.businessDetailForm.value.phone;
    this.store.owner_phone_country_code = this.businessDetailForm.value.phone_country_code;
    this.store.company_name = this.businessDetailForm.value.business_name;
    this.store.vendor_sector = this.businessDetailForm.value.vendor_section;
    this.store.business_type = this.businessDetailForm.value.business_type;
    this.store.license_number = this.businessDetailForm.value.license_number;
    this.store.iban = this.businessDetailForm.value.iban;

    this.store.owner_date_of_birth = format(parseISO(this.businessDetailForm.controls.owner_date_of_birth.value), 
      'yyyy-MM-dd');//, { timeZone: '+3:30' }

    this.store.owner_name_title = this.businessDetailForm.value.owner_name_title;
    this.store.owner_middle_name = this.businessDetailForm.value.owner_middle_name;
    this.store.owner_nationality = this.businessDetailForm.value.owner_nationality;
    this.store.tax_number = this.businessDetailForm.value.tax_number;
    this.store.swift_code = this.businessDetailForm.value.swift_code;
    this.store.account_number = this.businessDetailForm.value.account_number;
    this.store.license_type = this.businessDetailForm.value.license_type;
  }

  /**
   * save form
   */
  saveBusinessDetail () {

    if (!this.businessDetailForm || this.businessDetailForm.invalid) {
      return false;
    }

    this.updateModelFromBusinessDetail();

    this.saving = true;
    
    this.storeService.updateBusinessDetails(this.store).subscribe(res => {

      if(res.operation == 'success') {

        /*this.toastCtrl.create({
          message: this.authService.errorMessage(res.message),
          duration: 3000
        }).then(toast => {
          toast.present();
        });*/
        
        this.step++; //move to upload document step 
        //this.navCtrl.navigateForward
      }
      else
      {
        this.alertCtrl.create({
          message: this.authService.errorMessage(res.message)
        }).then(alert => {
          alert.present();
        });
      }

      // this.navCtrl.navigateForward(['settings/payment-methods']);

    },
      error => this.saving = false,
      () => this.saving = false,
    );
  }

  /**
   * save documents
   */
  saveDocs() {

    if (!this.documentsForm || this.documentsForm.invalid) {
      return false;
    }

    this.updateModelFromDocumentsForm();

    this.saving = true;
    
    this.storeService.uploadDocs(this.store).subscribe(res => {

      if(res.operation == 'success') {

        /*this.toastCtrl.create({
          message: this.authService.errorMessage(res.message),
          duration: 3000
        }).then(toast => {
          toast.present();
        });*/
        
        this.loadData();

        //goto next step 
        this.step++;
      }
      else
      {
        this.alertCtrl.create({
          message: this.authService.errorMessage(res.message)
        }).then(alert => {
          alert.present();
        });
      }

      // this.navCtrl.navigateForward(['settings/payment-methods']);

    },
      error => this.saving = false,
      () => this.saving = false,
    );
  }

  /**
   * submit for review
   */
  submitForReview() {

    this.storeService.createTapQueue(this.store).subscribe(res => {

      if(res.operation == 'success') {

        this.toastCtrl.create({
          message: this.authService.errorMessage(res.message),
          duration: 3000
        }).then(toast => {
          toast.present();
        });
        
        this.eventService.tapQueueCreated$.next({});

        this.navCtrl.navigateForward(['settings/payment-methods']);

        //this.close('success');
        //this.navCtrl.navigateForward
      }
      else
      {
        this.alertCtrl.create({
          message: this.authService.errorMessage(res.message)
        }).then(alert => {
          alert.present();
        });
      }
    });
  }

  /**
   * save form
   */
  save() {

    if (!this.topForm || this.topForm.invalid) {
      return false;
    }

    this.updateModel();

    this.saving = true;
    
    this.storeService.createTapAccount(this.store).subscribe(res => {

      if(res.operation == 'success') {

        this.toastCtrl.create({
          message: this.authService.errorMessage(res.message),
          duration: 3000
        }).then(toast => {
          toast.present();
        });
        
        this.close('success');
        //this.navCtrl.navigateForward
      }
      else
      {
        this.alertCtrl.create({
          message: this.authService.errorMessage(res.message)
        }).then(alert => {
          alert.present();
        });
      }

      // this.navCtrl.navigateForward(['settings/payment-methods']);

    },
      error => this.saving = false,
      () => this.saving = false,
    );
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  /**
   * set business type
   * @param value 
   */
   setBusinessType(value) {
    this.businessDetailForm.controls.business_type.setValue(value);
    this.businessDetailForm.controls.business_type.updateValueAndValidity();
  }

  /**
   * set vendor type
   * @param value 
   */
  setVendorSection(value) {
    this.businessDetailForm.controls.vendor_section.setValue(value);
    this.businessDetailForm.controls.vendor_section.updateValueAndValidity();
  }

  /**
   * close page
   */
  close(data = '') {
    this.modelCtrl.getTop().then(o => {
      if (o) {
        o.dismiss(data);
      }
    });
  }
}
