import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrderRefundPageRoutingModule } from './order-refund-routing.module';

import { OrderRefundPage } from './order-refund.page';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModalModule } from 'src/app/components/loading-modal/loading-modal.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    PipesModule,
    OrderRefundPageRoutingModule,
    LoadingModalModule,
    TranslateModule.forChild()
  ],
  declarations: [OrderRefundPage]
})
export class OrderRefundPageModule {}
