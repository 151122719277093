import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CookieMessageComponent } from './cookie-message.component';
import { CommonModule } from '@angular/common';


@NgModule({
    declarations: [CookieMessageComponent],
    imports: [
        TranslateModule.forChild(),
        IonicModule,
        CommonModule
    ],
    exports: [CookieMessageComponent]
})
export class CookieMessageModule { }