import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import {ItemOptionsComponent} from './item-options.component';
import {ItemOptionsExtraModule} from "../item-options-extra/item-options-extra.module";
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    ItemOptionsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ItemOptionsExtraModule,
    TranslateModule.forChild()
  ],
  exports: [
    ItemOptionsComponent,
  ]
})
export class ItemOptionsModule { }

