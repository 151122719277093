import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class DeliveryAreaService {

  public endpoint: string = "area-delivery-zone";

  constructor(
    public _authhttp: AuthHttpService,
    public auth: AuthService
  ) { }

  /**
   * list delivery areas
   * @param page
   * @param query
   * @returns
   */
  list(page = null, query = ''): Observable<any> {
    let url = `${this.endpoint}?${query}`;

    if(page)
      url += `&page=${page}`;

    return this._authhttp.getRaw(url);
  }

  /**
   * save delivery zones
   * @param delivery_zone_id
   * @param areas
   * @returns
   */
  save(store_uuid,delivery_zone_id, areas): Observable<any> {
    return this._authhttp.patch(`${this.endpoint}/save`, {
      store_uuid,
      delivery_zone_id,
      areas
    });
  }

  /**
   * save delivery cities
   * @param delivery_zone_id 
   * @param states 
   * @param selectedCities 
   * @returns 
   */
  saveCities(delivery_zone_id, states, selectedCities) {
    return this._authhttp.patch(`${this.endpoint}/save-cities`, {
      delivery_zone_id,
      states,
      selectedCities
    });
  }
}
