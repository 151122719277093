import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// services
import { AuthService } from 'src/app/services/auth.service';
import { AuthHttpService } from 'src/app/services/logged-in/authhttp.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  /**
   * country list
   * @param page
   * @param query
   */
  list(page, query = null): Observable<any> {
    let url = `country?page=${page}`;
    if (query) {
      url += `&keyword=${query}`;
    }
    return this.http.getRaw(url);
  }
}
