import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
//models
import { BusinessLocation } from 'src/app/interfaces/business_location';
import { countries } from 'src/app/interfaces/countries';
import { DeliveryZone } from 'src/app/model/DeliveryZone';
//services
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/logged-in/settings.service';
import { BusinessLocationService } from 'src/app/services/logged-in/business-location.service';
import { DeliveryZoneService } from 'src/app/services/logged-in/delivery-zone.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { CountryService } from 'src/app/services/logged-in/country.service';
//components
import { LocationPickerComponent } from "../../../../../components/location-picker/location-picker.component";
import { EventService } from 'src/app/services/event.service';


@Component({
  selector: 'app-delivery-zone-form',
  templateUrl: './delivery-zone-form.page.html',
  styleUrls: ['./delivery-zone-form.page.scss'],
})
export class DeliveryZoneFormPage implements OnInit {

  public zone_id: string;
  public country_id: string;
  public business_location_id: string;
  public business_location: BusinessLocation;
  public deliveryZone: DeliveryZone;
  public delivery_form: FormGroup;
  public countryListData = countries;
  public loading: boolean = false;
  public saving: boolean = false;
  public deleting: boolean = false;
  public borderLimit: boolean = false;

  public country;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public authService: AuthService,
    public deliveryZoneService: DeliveryZoneService,
    public countryService: CountryService,
    public businessLocationService: BusinessLocationService,
    public translateService: TranslateLabelService,
    public eventService: EventService,
    private settingServices: SettingsService
  ) { }

  async ngOnInit() {

    if (!this.zone_id) {
      this.zone_id = this.activatedRoute.snapshot.paramMap.get('id');
    }

    if (!this.business_location_id) {
      this.business_location_id = this.activatedRoute.snapshot.params.business_location_id;
    }

    if (!this.country_id) {
      this.country_id = this.activatedRoute.snapshot.params.country_id;
    }

    /*const state = window.history.state;

    if(state && state.business_location) {
      this.business_location = state.business_location;
    } else {
      this.loadBusinessDetail();
    }*/

    if (this.zone_id) {
      this.loadData();
    } else {
      this.deliveryZone = new DeliveryZone();
      this.deliveryZone.store_uuid = this.authService.store_id;
      this.deliveryZone.business_location_id = this.business_location_id;

      if (this.country_id) {
        this.loadCountry();
      } else {
        this.initForm();
      }
    }

    this.loadCountries();
  }

  loadCountries() {
    this.countryService.list(-1).subscribe(response => {
      this.countryListData = response.body;
    });
  }

  /**
   * load country detail
   */
  loadCountry() {
    this.settingServices.get_country(this.country_id).subscribe(response => {
      if(response) {
        this.deliveryZone.country = response;
        this.deliveryZone.country_id = this.country_id;
      } else {
        this.deliveryZone.country_id = null;
      }

      this.initForm();

    }, err => {
      this.deliveryZone.country = null;
      this.deliveryZone.country_id = null;
      this.initForm();
    });
  }

  loadBusinessDetail() {

    if(!this.business_location_id) {
      return null;
    }
    
    this.businessLocationService.view(this.business_location_id).subscribe(response => {
      this.business_location = response;
    });
  }

  loadData() {
    this.loading = true;

    this.deliveryZoneService.view(this.zone_id).subscribe(response => {
      this.deliveryZone = response;
      this.loading = false;
      this.initForm();
    });
  }

  initForm() {
    this.delivery_form = this.formBuilder.group({
      country_id: [this.deliveryZone.country_id , Validators.required],
      country: [(this.deliveryZone.country) ? (this.translateService.langContent(   this.deliveryZone.country.country_name,    this.deliveryZone.country.country_name_ar)      ) : '', Validators.required],
      delivery_time: [this.deliveryZone.delivery_time, Validators.required],
      time_unit: [this.deliveryZone.time_unit ? this.deliveryZone.time_unit : 'min', Validators.required],
      delivery_fee: [this.deliveryZone.delivery_fee, Validators.required],
      min_charge: [this.deliveryZone.min_charge, Validators.required],
      deliver_whole_country: [this.deliveryZone.deliver_whole_country]
      // delivery_zone_tax
    });
  }

  /**
   * update model data from form values
   */
  updateModelFromFormValues() {
    this.deliveryZone.country_id = this.delivery_form.value.country_id;
    this.deliveryZone.delivery_time = this.delivery_form.value.delivery_time;
    this.deliveryZone.time_unit = this.delivery_form.value.time_unit;
    this.deliveryZone.delivery_fee = this.delivery_form.value.delivery_fee;
    this.deliveryZone.min_charge = this.delivery_form.value.min_charge;
    this.deliveryZone.deliver_whole_country = this.delivery_form.value.deliver_whole_country;
  }

  /**
   * save delivery zone detail
   * @param form
   */
  save() {

    if (!this.delivery_form || !this.delivery_form.valid) {
      return false;
    }

    this.updateModelFromFormValues();

    this.saving = true;

    let action;

    if (this.deliveryZone.delivery_zone_id) {
      action = this.deliveryZoneService.update(this.deliveryZone);
    } else {
      action = this.deliveryZoneService.add(this.deliveryZone);
    }

    action.subscribe(async response => {

      this.saving = false;

      if (response.operation == 'success') {
        
        //this.router.navigate(['settings/delivery-zone/' + this.business_location_id]);

        this.deliveryZone = Object.assign(this.deliveryZone, response.model);
          
        if(!this.deliveryZone.delivery_zone_id) {
          this.deliveryZone.delivery_zone_id = response.model.delivery_zone_id;
        }

        this.eventService.onboardStepCompleted$.next({
          step: "shipping-added"
        });

        this.dismiss({
          zone: this.deliveryZone,
          refresh: true
        });

      } else {

        this.authService.showAlert(
          this.translateService.transform('Error'),
          this.translateService.errorMessage(response.message),
          this.delivery_form.value
        );  
      }
    });
  }

  /**
   * delete delivery zone
   */
  delete() {

    this.deleting = true;

    if (!this.deliveryZone) {
      this.deliveryZone = new DeliveryZone;
      this.deliveryZone.delivery_zone_id = this.zone_id;
    }

    this.deliveryZoneService.delete(this.deliveryZone).subscribe(async response => {

      this.eventService.deliveryZoneDeleted$.next({
        delivery_zone_id: this.deliveryZone.delivery_zone_id
      }); 

      if (response.operation == 'success') {
        this.dismiss({
          delete: true
        });
      }
      else {
        this.deleting = false;

        this.authService.showAlert(
          this.translateService.transform('Error'),
          this.translateService.errorMessage(response.message),
          this.delivery_form.value
        );
      }
    });
  }

  /**
   * close overlay
   */
  dismiss(data = {}) {
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }


  /**
   * close popup if any
   * @param event
   */
   close(event) {
    this.modalCtrl.getTop().then(o => {
      if(o) {
        event.preventDefault();
        event.stopPropagation();
        o.dismiss();
      }
    })
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }


  /**
   * open popup to select country
   * @returns
   */
  async selectCountry() {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: LocationPickerComponent,
      cssClass : 'country-modal'
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if (data && data) {

      this.delivery_form.controls.country_id.setValue(data.country_id);
      this.delivery_form.controls.country_id.markAsDirty();

      let countryName = this.translateService.langContent(data.country_name, data.country_name_ar);
      this.delivery_form.controls.country.setValue(countryName);
      this.delivery_form.controls.country.markAsDirty();

      this.delivery_form.updateValueAndValidity();
    }
  }
}
