import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@capacitor/storage';
//services
import { AuthService } from 'src/app/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  hasUpdates: boolean = true

  constructor(
    public authService: AuthService,
    public router: Router,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isLogged) {
      // this.authService.router.navigate(['/']);
      //return true;
    }

    /**
     * new router changes don't wait for startup service
     * https://github.com/angular/angular/issues/14615
     */
    return new Promise((resolve) => {

      Storage.get({ key: 'loggedInAgent' }).then((ret) => {
        
        const data = JSON.parse(ret.value);

        if (data && data.token) {
          this.authService.isLogged = true;
          this.authService._accessToken = data.token;
          resolve(false);
          this.authService.router.navigate(['/']);
        } else {
          resolve(true);
          // this.authService.router.navigate(['login']);
        }

      }).catch((r) => {
        console.error(r);
        this.authService.eventService.errorStorage$.next({ error: r });
      });
    });
  }
}
