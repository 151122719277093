import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ItemInventoryPageRoutingModule } from './item-inventory-routing.module';

import { ItemInventoryPage } from './item-inventory.page';
import {ComponentsModule} from '../../../../components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import {ItemListPageModule} from '../item-list/item-list.module';
import { LoadingModalModule } from 'src/app/components/loading-modal/loading-modal.module';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      ItemInventoryPageRoutingModule,
      ComponentsModule,
      LoadingModalModule,
      TranslateModule.forChild(),
      ItemListPageModule,
    ],
  declarations: [ItemInventoryPage]
})
export class ItemInventoryPageModule {}
