import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class AddonService {

  public endpoint: string = "addons";

  constructor(
    public _authhttp: AuthHttpService
  ) { }
 
  /**
   * list addons
   * @param page 
   * @param query 
   * @returns 
   */
   list(page = null, query = ''): Observable<any> {
    let url = `${this.endpoint}?${query}`;

    if(page) 
      url += `&page=${page}`;

    return this._authhttp.getRaw(url);
  }

  /**
   * return addon detail
   * @param addon_uuid 
   * @returns 
   */
  view(addon_uuid): Observable<any> {
    let url = `${this.endpoint}/${addon_uuid}?expand=paymentMethods,formatedPrice`;
    return this._authhttp.get(url);
  }

  /**
   * confirm addon purchase
   * @param params 
   * @returns 
   */
  confirm(params) : Observable<any> {
    let url = `${this.endpoint}/confirm`;
    return this._authhttp.post(url, params);
  }
}
