import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
//services
import { AuthService } from 'src/app/services/auth.service';
import { StripeService } from 'src/app/services/logged-in/payment/stripe.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


declare var Stripe;

//todo: test for android app 

@Component({
  selector: 'app-stripe-form',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.scss'],
})
export class StripeFormComponent implements OnInit {

  @Input() invoice;

  @Input() plan;

  public stripe;

  public loading: boolean = false;

  private elements;

  public amount; 
  public currency_code;
  
  private cancel_url;
  private success_url;

  constructor(
    public stripeService: StripeService,
    public authService: AuthService,
    public alertCtrl: AlertController,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {

    this.initialize();
  }

  // Fetches a payment intent and captures the client secret
  async initialize() {

    const params = {
      invoice_uuid: this.invoice?.invoice_uuid,
      plan_id: this.plan?.plan_id
    };

    this.stripeService.getInitParams(params).subscribe(async resp => {

      if(resp.operation == "success") {

        this.amount = resp.amount;
        this.currency_code = resp.currency_code;

        this.success_url = resp.success_url;
        this.cancel_url = resp.cancel_url;

        this.stripe = Stripe(resp.stripePublishableKey, {
          locale: this.translateService.currentLang
        });

        this.elements = this.stripe.elements({ clientSecret: resp.clientSecret });
    
        const linkAuthenticationElement = this.elements.create("linkAuthentication");
        linkAuthenticationElement.mount("#link-authentication-element");
    
        const paymentElementOptions = {
          layout: "tabs",
        };
    
        const paymentElement = this.elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");

      } else {
        //this.showMessage(resp.message + "");
        
        /*const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Alert'),
          message: this.translateService.transform(resp.message),
          buttons: [this.translateService.transform('OK')]
        });

        return await alert.present();*/
      }
    });
     
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.loading = true;

    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: this.success_url,
        receipt_email: this.authService.store.restaurant_email 
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.showMessage(error.message);
    } else {
      this.showMessage("An unexpected error occurred.");
    }

    this.loading = false;
  }

  // ------- UI helpers -------

  async showMessage(messageText) {
    /*const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(() => {
      messageContainer.classList.add("hidden");
      messageText.textContent = "";
    }, 4000);*/

    const alert = await this.alertCtrl.create({
      header: this.translateService.transform('Alert'),
      message: this.translateService.transform(messageText),
      buttons: [this.translateService.transform('OK')]
    });

    return await alert.present();
  }
}
