import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-delivery-zone-tax',
  templateUrl: './delivery-zone-tax.component.html',
  styleUrls: ['./delivery-zone-tax.component.scss'],
})
export class DeliveryZoneTaxComponent implements OnInit {

  public tax;

  constructor(
    public translateService: TranslateLabelService,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
    if(!this.tax) {
      this.tax = 0;
    }
  }

  save() {
    this.dismiss();
  }

  dismiss(data = {tax: this.tax}) {
    this.modalCtrl.getTop().then(o => {
      if(o) {
        o.dismiss(data);
      }
    });
  }
}
