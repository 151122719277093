import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExtraOption } from 'src/app/model/extra_option';
import { AuthService } from 'src/app/services/auth.service';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';
//models

@Component({
  selector: 'app-item-options-extra',
  templateUrl: './item-options-extra.component.html',
  styleUrls: ['./item-options-extra.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ItemOptionsExtraComponent),
      multi: true
    }
  ]
})
export class ItemOptionsExtraComponent implements ControlValueAccessor, OnInit {

  @Output() removeOptionExtra = new EventEmitter<any>();

  // Default value the form element should have
  // (In case an image has already been uploaded for it)
  extraOption: ExtraOption = new ExtraOption();

  @Input() index;
  @Input() total;
  @Input() type;// item type

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  private propagateChange = (_: any) => { };

  constructor(
    public translateService: TranslateLabelService,
    public authService: AuthService
  ) { }

  ngOnInit() { }
  /**
   * Getter for Value
   */
  get value() {
    return this.extraOption;
  }
  
  /**
   * Setter for Value
   */
  set value(val) {
    this.extraOption = val;
    // Notify of changes
    this.propagateChange(this.extraOption);
  }

  /**
   * ControlValueAccessor interface methods
   * - They allow this component to be used as a form element (with validation and ngModel)
   */

  /**
   * Called on form Init / Update
   * @param {*} obj
   */
  writeValue(obj: any) {
    if (obj) {
      this.value = obj;
    }
  }

  /**
   * Propogate change on change, notify outside world of changes
   * @param {any} fn
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /**
   * Called on touch/ element blur
   */
  registerOnTouched() { }

  removeExtra(param) {
    this.removeOptionExtra.emit(param);
  }

  remove(event) {
    if (this.extraOption.stock_qty > 0) {
      this.extraOption.stock_qty = parseInt(this.extraOption.stock_qty, 10) - 1;
    }
  }

  add(event) {
    if (!this.extraOption.stock_qty) {
      this.extraOption.stock_qty = 1;
    }
    this.extraOption.stock_qty = parseInt(this.extraOption.stock_qty, 10) + 1;
  }

  countChange(event) {
    // if (!Number(event.target.value) && event.target.value != '0') {
    //   event.target.value = event.target.value.replace(/[^0-9]*/g, '');
    // }
  }

}
