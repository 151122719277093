import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ItemOptionsFormPageRoutingModule } from './item-options-form-routing.module';

import { ItemOptionsFormPage } from './item-options-form.page';
import {TranslateModule} from "@ngx-translate/core";
import {ItemOptionsExtraModule} from "../../../../../components/item-options-extra/item-options-extra.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule.forChild(),
        ItemOptionsFormPageRoutingModule,
        ItemOptionsExtraModule
    ],
  declarations: [ItemOptionsFormPage]
})
export class ItemOptionsFormPageModule {}
