import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';
//models
import { Order } from "../../model/order";


@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private endpoint = 'order';

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  /**
   * return orders by status
   * @param status
   * @param page
   * @param perPage
   * @param params
   */
  list(status: string = '', page: number = 1, perPage = 20, params = null): Observable<any> {
    let url = `${this.endpoint}/${status}?expand=restaurant,businessLocation,itemImage,totalOrderItems,currency&page=${page}&per-page=${perPage}
      `;

    if (params) {
      url += params;
    }

    return this.http.getRaw(url);
  }

  /**
   * return total active orderr
   * @param status
   * @returns
   */
  getTotalPendingCount(status: string = ''): Observable<any> {
    let url = `${this.endpoint}/total-pending?`;
    return this.http.get(url);
  }

  /**
   * return order detail
   * @param order_uuid
   * @returns
   */
  view(order_uuid: string): Observable<any> {
    const url = `${this.endpoint}/detail?order_uuid=${order_uuid}&expand=deliveryZone,deliveryZone.businessLocation,refundedTotal,orderItems.refundedQty,currency,businessLocation,pickupLocation,orderItems,orderItems.image,orderItems.itemImage,orderItems.currency,orderItems.orderItemExtraOptions,orderItems.itemVariantImage,orderItems.item,orderItems.variant,orderItems.orderItemExtraOptions,orderItems.currency,voucher,bankDiscount,refunds,payment,paymentMethod,area.city,state,restaurant,customer`;
    return this.http.get(url);
  }

  /**
   * return stats
   * @returns
   */
  stats(customer_id = null, query = ''): Observable<any> {
    let url = `${this.endpoint}/stats?query=${query}`;

    if (customer_id) {
      url += '?customer_id=' + customer_id;
    }

    return this.http.get(url);
  }

  /**
   * ability to delete order
   * @param order_uuid
   * @returns
   */
  delete(order_uuid): Observable<any> {
    const url = `${this.endpoint}/delete/${order_uuid}`;
    return this.http.delete(url);
  }

  /**
   * ability to delete order
   * @param order_uuid
   * @returns
   */
  softDelete(order_uuid): Observable<any> {
    const url = `${this.endpoint}/soft-delete/${order_uuid}/${this.authService.store_id}`;
    return this.http.delete(url);
  }

  /**
   * initiate refund
   * @param order_uuid
   * @param params
   * @returns
   */
  refund(order_uuid, params): Observable<any> {
    const url = `${this.endpoint}/refund/${order_uuid}`;
    return this.http.patch(url, params);
  }

  /**
   * update order status
   * @param order_uuid
   * @param order_status
   * @returns
   */
  updateStatus(order_uuid: string, order_status: string): Observable<any> {
    const url = `${this.endpoint}/update-order-status/${order_uuid}/${this.authService.store_id}`;
    return this.http.patch(url, {
      order_status: order_status
    });
  }

  /**
   * cancel armada delivery
   * @param order_uuid 
   * @returns 
   */
  cancelDelivery(order_uuid: string) : Observable<any>{
    const url = `${this.endpoint}/cancel-delivery/${order_uuid}`;
    return this.http.post(url, {
    });
  }

  /**
   * request driver for order pickup
   * @param order_uuid
   * @param mashkor_branch_id
   * @returns
   */
  reqestDriverFromMashkor(order_uuid: string, mashkor_branch_id: string) : Observable<any>{
    const url = `${this.endpoint}/request-driver-from-mashkor/${order_uuid}/${this.authService.store_id}`;
    return this.http.post(url, {
      mashkor_branch_id: mashkor_branch_id
    });
  }

  /**
   * create aramex shipment
   * @param order_uuid 
   * @returns 
   */
  createAramexShipment(order_uuid: string) : Observable<any>{
    const url = `${this.endpoint}/create-shipment-aramex/${order_uuid}/${this.authService.store_id}`;
    return this.http.post(url, { 
    });
  }

  scheduleAramexPickup(order_uuid: string, params) : Observable<any>{
    const url = `${this.endpoint}/schedule-pickup-aramex/${order_uuid}/${this.authService.store_id}`;
    return this.http.post(url, params);
  }
 
  /**
   * request driver for order pickup
   * @param order_uuid
   * @param armada_api_key
   * @returns
   */
  reqestDriverFromArmada(order_uuid: string, armada_api_key: string): Observable<any> {
    const url = `${this.endpoint}/request-driver-from-armada/${order_uuid}/${this.authService.store_id}`;
    return this.http.post(url, {
      armada_api_key: armada_api_key
    });
  }

  /**
   * export order report
   * @returns
   */
  exportReport(from = null, to = null): Observable<any> {
    let url = `${this.endpoint}/orders-report`;

    if (from && to) {
      url += '?from=' + from + '&to=' + to;
    }

    return this.http.excelget(url, `Orders-report.xlsx`);
  }

  /**
   * create order
   * @param order
   */
  create(order: Order): Observable<any> {
    const url = `${this.endpoint}/create/${this.authService.store_id}`;
    return this.http.post(url, order);
  }

  /**
   * update order
   * @param order
   */
  update(order: Order) : Observable<any>{
    const url = `${this.endpoint}/${order.order_uuid}/${this.authService.store_id}`;
    return this.http.patch(url, order);
  }

  /**
   * Download Invoice
   * @param order
   */
  downloadInvoice(order: Order): Observable<any> {
    const url = `${this.endpoint}/download-invoice/${order.order_uuid}`;
    return this.http.pdfget(url, 'Invoice #' + order.order_uuid + '.pdf');
  }

  /**
   * download today's invoices 
   * @returns 
   */
  downloadTodayInvoices(): Observable<any> {
    const url = `${this.endpoint}/download-today-invoices`;
    return this.http.pdfget(url, 'Invoices.pdf');
  }

  /**
   * update payment status from tap
   * @param order_uuid 
   * @returns 
   */
  requestPaymentStatusFromTap(order_uuid: string): Observable<any> {
    const url = `${this.endpoint}/request-payment-status-from-tap/${order_uuid}/${this.authService.store_id}`;
    return this.http.post(url, { });
  }
}

