import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { EventService } from 'src/app/services/event.service';
//services
import { OrdersService } from 'src/app/services/logged-in/orders.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-order-refund',
  templateUrl: './order-refund.page.html',
  styleUrls: ['./order-refund.page.scss'],
})
export class OrderRefundPage implements OnInit {

  public itemsToRefund = {};

  public order;

  public form: FormGroup;

  public borderLimit: boolean = false;

  public submitting: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public eventService : EventService,
    public orderService: OrdersService,
    public translateService: TranslateLabelService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      refund_amount: [0.000, [Validators.required]],
      reason: [null, [Validators.required]]
    });
  }

  /**
   * close refund form
   * @param event
   * @param data
   */
  close(event, data = {}) {
    
    this.modalCtrl.getTop().then(o => {
      if(o) {
        
        event.preventDefault();
        event.stopPropagation();

        o.dismiss(data);
      }
    })
  }



  /**
   * return total refund amount
   * @returns
   */
  // refundTotal() {

  //   let totalRefund = 0;

  //   for(let orderItem of this.order.orderItems) {
  //     if(this.itemsToRefund[orderItem.order_item_id] > 0) {
  //       const perUnitPrice = orderItem.item_price / orderItem.qty;

  //       totalRefund += perUnitPrice * this.itemsToRefund[orderItem.order_item_id];
  //     }
  //   }

  //   return totalRefund;
  // }

  remove($event, orderItem) {

    if(this.itemsToRefund[orderItem.order_item_id] == 0) {
      return null;
    }

    if(!this.itemsToRefund[orderItem.order_item_id]) {
      this.itemsToRefund[orderItem.order_item_id] = 0;
    } else {
      this.itemsToRefund[orderItem.order_item_id]--;
    }

    this.form.controls.refund_amount.setValue(this.order.total_price);

  }

  /**
   * increase item qty 
   * @param $event 
   * @param orderItem 
   * @returns 
   */
  add($event, orderItem) {

    // also count previous refund count

    if(this.itemsToRefund[orderItem.order_item_id] == (orderItem.qty - orderItem.refundedQty)) {
      return null;
    }

    if(!this.itemsToRefund[orderItem.order_item_id]) {
      this.itemsToRefund[orderItem.order_item_id] = 1;
    } else {
      this.itemsToRefund[orderItem.order_item_id]++;
    }

    this.form.controls.refund_amount.setValue(this.order.total_price);
  }

  extraOptionsToText(orderItem) {

    let value = [];

    //if (orderItem.orderItemExtraOptions.length > 0)
    //  value.push(' · ');

    for (let extraOption of orderItem.orderItemExtraOptions) {
      value.push(this.translateService.langContent(extraOption['extra_option_name'], extraOption['extra_option_name_ar']));
    }

    return value.join(' / ');
  }

  /**
   * submmit refund request
   */
  submit(event) {
    this.submitting = true;

    let params = this.form.value;
    params.itemsToRefund = this.itemsToRefund;

    this.orderService.refund(this.order.order_uuid, params).subscribe(async response => {

      this.submitting = false;

      if(response.operation == 'success') {
        this.close(event, {
          refresh: true
        });
      } else {

        this.authService.showAlert(
          this.translateService.transform('Error refunding order'),
          this.translateService.errorMessage(response.message),
        );
      }
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  onItemImageError(itemImage) {
    itemImage = null;
  }
}
