import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// services
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { CategoryService } from "src/app/services/category.service";


@Component({
  selector: 'app-item-category-picker',
  templateUrl: './item-category-picker.component.html',
  styleUrls: ['./item-category-picker.component.scss'],
})
export class ItemCategoryPickerComponent implements OnInit {

  public categories;
  public selectedCategories;
  public perPageCount = 20;
  public currentPage = 0;
  public totalPages = 0;
  public totalCount = 0;
  public search = null;
  public loading = false;
  public loadingMore = false;

  public borderLimit = false;

  constructor(
    public modalCtrl: ModalController,
    public categoryService: CategoryService,
    public translateService: TranslateLabelService
  ) {
  }

  ngOnInit() {
    this.loadCountry(1);
  }

  selected(data = {}) {

    const category = this.categories ? this.categories.filter(e => e.isChecked === true) : null;

    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(category);
      }
    });
  }

  dismiss() {
    this.selected({});
  }

  loadCountry(page) {
    this.loading = true;

    this.categoryService.list(page, this.search).subscribe(res => {

      this.perPageCount = parseInt(res.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(res.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);
      this.categories = res.body;
      this.selectedCategoriesSelection();
    },
      err => {
        this.loading = false;
      },
      () => this.loading = false,
    );
  }

  loadMoreCountries(event) {

    if(this.currentPage >= this.totalPages) {
      if(event && event.target)
        event.target.complete();
      return null;
    }
    
    this.loadingMore = true;
    this.currentPage += 1;
    this.categoryService.list(this.currentPage).subscribe(res => {
      this.perPageCount = parseInt(res.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(res.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);
      this.categories.push(...res.body);
      
      if(event && event.target)
        event.target.complete();
    },
      err => this.loadingMore = false,
      () => this.loadingMore = false
    );
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  selectedCategoriesSelection() {
    if (this.categories && this.selectedCategories) {
      this.categories.map((cat, i) => {
        if (this.selectedCategories.some(x => x.category_id == cat.category_id)) {
          cat.isChecked = true;
        }
      });
    }
  }
}
