import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { SunmiDevice } from 'plugn-device';
import mixpanel from 'mixpanel-browser';
//services
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { OrdersService } from 'src/app/services/logged-in/orders.service';
import { RoleGuard } from 'src/app/services/role-guard.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
//pages
import { OrderInvoicePage } from '../order-invoice/order-invoice.page';
import { OrderRefundPage } from '../order-refund/order-refund.page';
//component
import { OrderOptionComponent } from 'src/app/components/order-option/order-option.component';
import { OrderStatusActionsComponent } from 'src/app/components/order-status-actions/order-status-actions.component';
import { SchedulePickupPage } from '../aramex/schedule-pickup/schedule-pickup.page';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { AnalyticsService } from 'src/app/services/analytics.service';


@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.page.html',
  styleUrls: ['./order-view.page.scss'],
})
export class OrderViewPage implements OnInit {

  public restaurantShippingMethods = [];

  public model;
  public txt;

  public order_uuid;

  public loading: boolean = false;
  public saving: boolean = false;

  public refundAmount;

  public difference;

  public timer;

  creatingAramexShipment: boolean = false;

  public reqestingDriverFromArmada: boolean = false;

  public reqestingDriverFromMashkor: boolean = false;

  public cancellingDriverFromArmada: boolean = false;
  
  public borderLimit: boolean = false;
  public checkingPaymentStatus = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    public toastCrl: ToastController,
    public popoverCtrl: PopoverController,
    public orderService: OrdersService,
    public roleGuard: RoleGuard,
    public authService: AuthService,
    public platform: Platform,
    public translateService: TranslateLabelService,
    public eventService: EventService,
    public storeService: StoreService,
    public analytics: AnalyticsService,
    public _orderservcies: OrdersService
  ) { }

  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Order View page'
    });
  }

  async ngOnInit() {

    this.analytics.page('Order View page');

    if (!this.order_uuid)
      this.order_uuid = this.activatedRoute.snapshot.paramMap.get('id');

    this.loading = true;

    this.loadData();

    this.loadShippingMethods();
  }

  async ionViewWillEnter() {
    if (await this.isSunmiDevice()) {
      this.txt = this.translateService.transform('Print invoice');
    } else {
      this.txt = this.translateService.transform('View invoice');
    }
  }

  async isSunmiDevice() {
    const sunmiModel = (await SunmiDevice.getPrinterModal()).value;
    return sunmiModel != null && sunmiModel != "";
  }

  loadShippingMethods() {
    this.storeService.viewShippingMethods().subscribe(resp => {
      this.restaurantShippingMethods = resp;
    });
  }

  loadData() {
    this._orderservcies.view(this.order_uuid).subscribe(response => {

      this.loading = false;

      this.model = response;

      this.refundAmount = 0;

      if (this.model.refunds) {
        this.model.refunds.forEach(refund => {
          this.refundAmount += refund.refund_amount;
        });
      }

      if(!this.model.estimated_time_of_arrival) {
        return null;
      }

      const deliveryTime = this.toDate(this.model.estimated_time_of_arrival).getTime();

      if (this.model.order_mode == 1) {

        this.difference = Math.abs(deliveryTime - (new Date()).getTime()) / 3600;

        this.timer = setInterval(() => {
          this.difference = Math.abs(deliveryTime - (new Date()).getTime()) / 3600;

          //no need after 1 hour
          if (this.difference > 1) {
            this.clearTimer();
          }
        }, 1000);//every second
      }

    });

    
  }

  openItemPage(item) {
    this.router.navigateByUrl('/item-form/' + item.item_uuid);
  }

  /**
   * close popup if any
   * @param event
   */
  close(event) {

    this.modalCtrl.getTop().then(o => {
      if (o) {

        event.preventDefault();
        event.stopPropagation();

        o.dismiss();
      }
    })
  }

    /**
   * close popup
   * @param refresh
   */
  dismiss(refresh = false, event = null) {

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss({ refresh });
      }
    });
  }


  /**
   * open invoice model
   */
  async viewInvoice(ev) {

    if(this.model.orderItems.length <= 0)
      return true;

    if (await this.isSunmiDevice()) {
      const params = {
        order: this.model,
        printTwoInvoices: false
      };
      this.eventService.printInvoice$.next(params);
    } else {
      ev.preventDefault();
      ev.stopPropagation();

      //to fix print issue in safari browser

      /*
      this.modalCtrl.dismiss({ 'from': 'native-back-btn' }).then(o => {
        this.router.navigateByUrl('/order-invoice/' + this.order_uuid);
      });*/

      window.history.pushState({
        navigationId: window.history.state.navigationId
      }, null, window.location.pathname);

      const modal = await this.modalCtrl.create({
        component: OrderInvoicePage,
        cssClass: 'popup-modal',
        componentProps: {
          order: this.model,
          order_uuid: this.order_uuid
        }
      });
      modal.present();
      modal.onDidDismiss().then(e => {

        if (!e.data || e.data.from != 'native-back-btn') {
          window['history-back-from'] = 'onDidDismiss';
          window.history.back();
        }
      });
  }

    // SunmiDevice.getPrinterVersion().then(async result => {

    //   if(result['value'] && this.platform.is('capacitor')){
    //     const params = {
    //       order: this.model,
    //       printTwoInvoices : false
    //     };
    //     this.eventService.printInvoice$.next(params);
    //   }

    //   else {
    //       ev.preventDefault();
    //         ev.stopPropagation();

    //         //to fix print issue in safari browser

    //         /*
    //         this.modalCtrl.dismiss({ 'from': 'native-back-btn' }).then(o => {

    //           this.router.navigateByUrl('/order-invoice/' + this.order_uuid);
    //         });*/

    //         window.history.pushState({
    //           navigationId: window.history.state.navigationId
    //         }, null, window.location.pathname);

    //         const modal = await this.modalCtrl.create({
    //           component: OrderInvoicePage,
    //           cssClass: 'popup-modal',
    //           componentProps: {
    //             order: this.model,
    //             order_uuid: this.order_uuid
    //           }
    //         });
    //         modal.present();
    //         modal.onDidDismiss().then(e => {

    //           if (!e.data || e.data.from != 'native-back-btn') {
    //             window['history-back-from'] = 'onDidDismiss';
    //             window.history.back();
    //           }
    //         });

    //       }
    //     });
  }

  /**
   * Make date readable by Safari
   * @param date
   */
  toDate(date) {
    if (date) {
      return new Date(date.replace(/-/g, '/'));
    }
  }

  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  async changeOrderStatus(status) {
    if (status == 5) {

      const alert = await this.alertCtrl.create({
        header: this.translateService.transform('Confirm'),
        message: this.translateService.transform("Are you sure you want to mark it as cancelled?"),
        buttons: [
          {
            text: this.translateService.transform('Cancel'),
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: this.translateService.transform('Okay'),
            handler: () => {
              this.saveOrderStatus(status);
            }
          }
        ]
      });
      alert.present();

    } else {
      this.saveOrderStatus(status);
    }
  }

  async cancelDriverFromArmada() {
    
    const alert = await this.alertCtrl.create({
      header: this.translateService.transform('Confirm'),
      message: this.translateService.transform("Are you sure you want to cancel a driver from Armada?"),
      buttons: [
        {
          text: this.translateService.transform('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translateService.transform('Okay'),
          handler: () => {

            this.cancellingDriverFromArmada = true;

            this._orderservcies.cancelDelivery(this.order_uuid).subscribe(response => {
                       
              if(response.operation == 'success')
              {
                this.toastCrl.create({
                  message: this.authService.errorMessage(response.message),
                  duration: 3000,
                }).then(alert => alert.present());

                this.model.mashkor_order_number = response.mashkor_order_number;
                this.model.mashkor_order_status = response.mashkor_order_status;

              }
              else 
              {
                this.authService.showAlert(
                  response.apiResponse? response.apiResponse : this.translateService.transform('Error'),
                  this.translateService.errorMessage(response.message),
                  this.model
                );  
              }
              
              this.cancellingDriverFromArmada = false;
            });
          }
        }
      ]
    });
    alert.present();
  }

  async scheduleAramexPickup() {
   
    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const popover = await this.modalCtrl.create({
      component: SchedulePickupPage,
      componentProps: {
        order: this.model,
        order_uuid: this.order_uuid
      },
      animated: true
    });
    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await popover.present();

    const { data } = await popover.onDidDismiss();

    if (!data) {
      return false;
    }

    if (data.action == 'refresh') {
      this.loadData(); 
    }

    if (data.ID) {
      this.model.aramex_pickup_id = data.ID;
    }
  }

  async createAramexShipment() {

    const alert = await this.alertCtrl.create({
      header: this.translateService.transform('Confirm'),
      message: this.translateService.transform("Are you sure you want to create shipment in Aramex?"),
      buttons: [
        {
          text: this.translateService.transform('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translateService.transform('Okay'),
          handler: () => {

            this.creatingAramexShipment = true;

            this._orderservcies.createAramexShipment(this.order_uuid).subscribe(response => {
                      
              if(response.operation == 'success')
              {
                this.alertCtrl.create({
                  header: this.translateService.transform('Success'),
                  message: this.authService.errorMessage(response.message),
                }).then(alert => alert.present());

              }
              else 
              {
                this.authService.showAlert(
                  response.apiResponse? response.apiResponse : this.translateService.transform('Error'),
                  this.translateService.errorMessage(response.message),
                  this.model
                );  
              }
              
              this.creatingAramexShipment = false;
            });
          }
        }
      ]
    });
    alert.present();
  }

  async reqestDriverFromMashkor() {

    const alert = await this.alertCtrl.create({
      header: this.translateService.transform('Confirm'),
      message: this.translateService.transform("Are you sure you want to request a driver from Mashkor?"),
      buttons: [
        {
          text: this.translateService.transform('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translateService.transform('Okay'),
          handler: () => {

            this.reqestingDriverFromMashkor = true;

            this._orderservcies.reqestDriverFromMashkor(this.order_uuid, this.model.businessLocation.mashkor_branch_id).subscribe(response => {
                      
              if(response.operation == 'success')
              {
                this.toastCrl.create({
                  message: this.authService.errorMessage(response.message),
                  duration: 3000,
                }).then(alert => alert.present());

                this.model.mashkor_order_number = response.mashkor_order_number;
                this.model.mashkor_order_status = response.mashkor_order_status;

              }
              else 
              {
                this.authService.showAlert(
                  response.apiResponse? response.apiResponse : this.translateService.transform('Error'),
                  this.translateService.errorMessage(response.message),
                  this.model
                );  
              }
              
              this.reqestingDriverFromMashkor = false;
            });
          }
        }
      ]
    });
    alert.present();
  }

  async reqestDriverFromArmada() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.transform('Confirm'),
      message: this.translateService.transform("Are you sure you want to request a driver from Armada?"),
      buttons: [
        {
          text: this.translateService.transform('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translateService.transform('Okay'),
          handler: () => {
            this.reqestingDriverFromArmada = true;

            this._orderservcies.reqestDriverFromArmada(this.order_uuid, this.model.businessLocation.armada_api_key).subscribe(response => {
                      
              if(response.operation == 'success')
              {
                this.toastCrl.create({
                  message: this.authService.errorMessage(response.message),
                  duration: 3000,
                }).then(alert => alert.present());

                this.model.armada_tracking_link = response.armada_tracking_link;
                this.model.armada_qr_code_link = response.armada_qr_code_link;
                this.model.armada_delivery_code = response.armada_delivery_code;

              }
              else 
              {
                this.authService.showAlert(
                  response.apiResponse? response.apiResponse : this.translateService.transform('Error'),
                  this.translateService.errorMessage(response.message),
                  this.model
                );  
              }
              
              this.reqestingDriverFromArmada = false;
            });
          }
        }
      ]
    });
    alert.present();
  }

  /**
   * save order status
   * @param status
   */
  saveOrderStatus(status) {
    this.saving = true;

    this._orderservcies.updateStatus(this.order_uuid, status).subscribe(async response => {

      if (response.operation == 'success') {

        const message = await this.toastCrl.create({
          message: response.message,
          duration: 3000
        });
        message.present();

        this.loadData();

        this.saving = false;
        // this.navCtrl.pop();

        this.eventService.updatedOrder$.next({});

      } else {
        this.authService.showAlert(
          this.translateService.transform('Error'),
          this.translateService.errorMessage(response.message),
          this.model
        );  
      }
    },
      err => this.saving = false,
      () => this.saving = true
    );
  }

  getMashkorOrderStatus(status) {

    switch (status) {
      case 0:
        return this.translateService.transform("New");
        break;
      case 1:
        return this.translateService.transform("Confirmed");
        break;
      case 2:
        return this.translateService.transform("Assigned");
        break;
      case 3:
        return this.translateService.transform("Pickup Started");
        break;
      case 4:
        return this.translateService.transform("Picked Up");
        break;
      case 5:
        return this.translateService.transform("In Delivery");
        break;
      case 6:
        return this.translateService.transform("Arrived Destination");
        break;
      case 10:
        return this.translateService.transform("Delivered");
        break;
      case 11:
        return this.translateService.transform("Canceled");
        break;
    }
  }

  dimenstionToText(orderItem) {

    let value = [];

    if(orderItem.weight > 0) {
      value.push(orderItem.weight+ ' kg');
    }

    if(orderItem.width > 0 || orderItem.length > 0 || orderItem.height > 0) {
      value.push(orderItem.width + ' cm (W) X ' + orderItem.length + ' cm (L) X ' + orderItem.height + ' cm (H)');
    }
 
    if (value.length > 0) {
      return ' · ' + value.join(' / ');
    }

    return null;
  }

  extraOptionsToText(orderItem) {

    let value = [];
 
    for (let extraOption of orderItem.orderItemExtraOptions) {
      value.push(this.translateService.langContent(extraOption['extra_option_name'], extraOption['extra_option_name_ar']));
    }

    if (value.length > 0)
      return ' · ' + value.join(' / ');

    return null;
  }

  getVoucherDiscount(voucher) {
    return voucher.discount_type == 1 ?
      (this.model.subtotal * (this.model.voucher.discount_amount / 100)) : this.model.voucher.discount_amount;
  }


  getBankDiscount(bankDiscount) {
    return bankDiscount.discount_type == 1 ?
      this.model.subtotal * (this.model.bankDiscount.discount_amount / 100) : this.model.bankDiscount.discount_amount;
  }

  onItemVariantImageError(orderItem) {
    
    if(orderItem.itemVariantImage) {
      orderItem.itemVariantImage = null;
    }
  }

  onItemImageError(orderItem) {
 
    if(orderItem.itemImage)
      orderItem.itemImage = null;
  
    //if(orderItem.image)   
    //orderItem.image = null;
  }

  onImageError(orderItem) {

    if(orderItem.image)
      orderItem.image = null;
  
    //if(orderItem.image)   
    //orderItem.image = null;
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  /**
   * order option from header
   * @param event
   * @returns
   */
  async openOrderAction(event) {
    event.preventDefault();
    event.stopPropagation();

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const popover = await this.popoverCtrl.create({
      component: OrderOptionComponent,
      componentProps: {
        order: this.model
      },
      animated: true,
      event: event,
      translucent: true
    });
    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await popover.present();

    const { data } = await popover.onDidDismiss();

    if (!data) {
      return false;
    }

    if (data.action == 'delete') {
      this.deleteOrder();
    } else if (data.action == 'view-invoice') {
      this.viewInvoice(event)
    } else if (data.action == 'refund') {
      this.refund(event);
    }
  }

  /**
   * open refund page
   */
  async refund(event) {

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: OrderRefundPage,
      cssClass: 'popup-modal',
      componentProps: {
        order: this.model,
        order_uuid: this.order_uuid
      }
    });


    modal.present();

    modal.onDidDismiss().then(e => {
      
      if (e.data && e.data.refresh) {
        window.history.back();
        this.eventService.refundOrder$.next({});
      }

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }

      //todo: refresh detail on refund

      this.loadData();

    });
  }

  /**
   * delete order
   */
  async deleteOrder() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.transform('Confirm'),
      message: this.translateService.transform("Are you sure you want to delete this order?"),
      buttons: [
        {
          text: this.translateService.transform('Cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.eventService.deletedOrder$.next({});
          }
        }, {
          text: this.translateService.transform('Okay'),
          handler: () => {

            this.orderService.softDelete(this.model.order_uuid).subscribe(() => {
              this.dismiss(true);
              this.eventService.deletedOrder$.next(this.model.order_uuid);
            });
          }
        }
      ]
    });
    alert.present();
  }

  openUpdateOrderPage(event) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        o.dismiss();
      }

      setTimeout(() => {
        this.router.navigate(['/order-form/' + this.order_uuid]);
      }, 400);
    });
  }

  /**
   * order action based on order status
   * @param event
   * @param order
   */
  async orderAction(event, order) {

    if (order.orderItems.length == 0)
      return false;

    event.preventDefault();
    event.stopPropagation();

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const popover = await this.popoverCtrl.create({
      component: OrderStatusActionsComponent,
      componentProps: {
        order: order
      },
      animated: true,
      event: event,
      translucent: true
    });
    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });    
    await popover.present();

    const { data } = await popover.onDidDismiss();

    // if(data && data.refresh) {
    //   //todo: update stats and listing?
    //   //  this.loadData(1, true);
    //   this.refresh.emit();
    // }
  }

  /**
   * check if method enabled in store
   * @param code
   * @returns
   */
  isShippingMethodEnabled(code) {
    for (let shippingMethod of this.restaurantShippingMethods) {
      if (shippingMethod.code == code) {//restaurantShippingMethod.
        return true;
      }
    }
  }
 
  copyPaymentLink(event) {
    event.preventDefault();
    event.stopPropagation();
 
    let link = this.authService.store.restaurant_domain + '/order/' + this.order_uuid;

    // Copy the text inside the text field
    navigator.clipboard.writeText(link);
 
/*
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);*/
  }

  copy(event, val) {
    event.preventDefault();
    event.stopPropagation();

    // Copy the text inside the text field
    navigator.clipboard.writeText(val);
  }

  updatePaymentStatus(model) {
    this.checkingPaymentStatus = true;
    this.orderService.requestPaymentStatusFromTap(this.model.order_uuid).subscribe(() => {
      this.eventService.updatedOrder$.next({});
      this.loadData();
    },
      error => {},
      () => { this.checkingPaymentStatus = false; }
    );
  }
}
