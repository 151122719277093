import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { AddBusinessLocationPageRoutingModule } from './add-business-location-routing.module';

import { AddBusinessLocationPage } from './add-business-location.page';
import {ComponentsModule} from "../../../../../components/components.module";
import { LocationPickerModule } from 'src/app/components/location-picker/location-picker.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddBusinessLocationPageRoutingModule,
    ComponentsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    LocationPickerModule
  ],
  declarations: [AddBusinessLocationPage]
})
export class AddBusinessLocationPageModule {}
