import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {catchError, first, map, retryWhen} from 'rxjs/operators';
// services
import { AuthService } from 'src/app/services/auth.service';
import { AuthHttpService } from 'src/app/services/logged-in/authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class AgentService {

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  /**
   * create agent
   * @param param
   */
  create(param: any): Observable<any> {
    return this.http.post(`staff/create`, {
        ...param, store_uuid: this.authService.store_id
    });
  }

  /**
   * list agents
   * @param page
   */
  list(page: number = 1, keyword, pageSize = 20): Observable<any> {
    if (keyword) {
      return this.http.getRaw(`staff?page=${page}&keyword=${keyword}&per-page=${pageSize}&expand=agent`);
    } else  {
      return this.http.getRaw(`staff?page=${page}&per-page=${pageSize}&expand=agent`);
    }
  }

  /**
   * view detail page
   * @param assignmentID
   */
  view(assignmentID: number): Observable<any> {
    return this.http.get(`staff/detail?assignment_id=${assignmentID}&expand=agent,businessLocation`);
  }

  /**
   * update request
   * @param params
   * @param assignmentID
   */
  update(params: any, assignmentID): Observable<any> {
    return this.http.patch(`staff/${assignmentID}`, params);
  }
}
