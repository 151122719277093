import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// services
import { AuthService } from 'src/app/services/auth.service';
import { AuthHttpService } from 'src/app/services/logged-in/authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class RestaurantBillingAddressService {

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  list(page: number = 1, keyword, pageSize = 20): Observable<any> {
    let url = `restaurant-billing-addresses?page=${page}&per-page=${pageSize}`;
    if (keyword) {
      url = `restaurant-billing-addresses?page=${page}&keyword=${keyword}&per-page=${pageSize}`;
    }
    return this.http.getRaw(url);
  }

  /**
   * detail API
   * @param ID
   */
  view(ID): Observable<any> {
    return this.http.get(`restaurant-billing-addresses/detail?rba_uuid=${ID}`);
  }

  /**
   * create api
   * @param data
   */
  create(data: any): Observable<any> {

    const param = {
      ...data, store_uuid : this.authService.store_id
    };
    return this.http.post(`restaurant-billing-addresses/create`, param);
  }

  /**
   * update API
   * @param data
   * @param ID
   */
  update(data: any, ID): Observable<any> {

    const param = {
      ...data
    };
    return this.http.patch(`restaurant-billing-addresses/${ID}/${this.authService.store_id}`, param);
  }

  /**
   * update API
   * @param ID
   */
  remove(ID): Observable<any> {
    return this.http.delete(`restaurant-billing-addresses/${ID}/${this.authService.store_id}`);
  }
}
