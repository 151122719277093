import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
//services
import { AuthService } from 'src/app/services/auth.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { WoyouConsts, BarCodeType, SunmiDevice } from 'plugn-device';
import { OrdersService } from 'src/app/services/logged-in/orders.service';
import { EventService } from 'src/app/services/event.service';
import { Storage } from '@capacitor/storage';


@Component({
  selector: 'app-order-alert',
  templateUrl: './order-alert.component.html',
  styleUrls: ['./order-alert.component.scss'],
})
export class OrderAlertComponent implements OnInit {

  public order;
  public txt;

  constructor(
    public translateService: TranslateLabelService,
    public router: Router,
    public authService : AuthService,
    public datePipe : DatePipe,
    public alertCtrl : AlertController,
    public _orderservcies : OrdersService,
    public platform : Platform,
    public eventService : EventService,
    public modalCtrl: ModalController
  ) { }


  ngOnInit() { }

  ionViewWillEnter(){

    //Check if sunmi
    SunmiDevice.getPrinterVersion().then(result => {

      if(result['value'] && this.platform.is('capacitor')){
      this.txt = this.translateService.transform('Accept & Print');
      }
      else
        this.txt = this.translateService.transform('View Invoice');

    });

  }


  async checkIfSunmi(){

      //Check if Sunmi
      SunmiDevice.getPrinterVersion().then(result => {
        if(result['value'] && this.platform.is('capacitor')){
          this.acceptOrder();
          //Print two invoices one for kitchen and one for the driver
          const params = {
            order: this.order,
            printTwoInvoices : true
          };
          this.eventService.printInvoice$.next(params);
          this.dismiss();
        } else {
          this.dismiss();
          this.router.navigate(['order-invoice/' + this.order.order_uuid]);
        }
      });

  }

  async acceptOrder() {

    this._orderservcies.updateStatus(this.order.order_uuid, '10').subscribe(async response => {

      if (response.operation != 'success') {

        this.authService.showAlert(
          this.translateService.transform('Error'),
          this.translateService.errorMessage(response.message)
        ); 
      } else {
        this.markAsBeningPrepared();
      }
    },

    );
  }


  async markAsBeningPrepared() {

    this._orderservcies.updateStatus(this.order.order_uuid, '2').subscribe(async response => {

      if (response.operation != 'success') {

        this.authService.showAlert(
          this.translateService.transform('Error marking order as prepared'),
          this.translateService.errorMessage(response.message)
        ); 
      }
    },

    );
  }

  /**
   * silent new order alert sound
   */
  silent() {
    this.authService.audioPlayer.pause();
    this.authService.stopStartBell();
    // this.dismiss();
  }

  /**
   * enable bell by use interaction
   */
  startBell() {
    this.authService.setStartBell();
    this.authService.audioPlayer.muted = false;

    this.authService.ringTheBell();

    // setTimeout(() => {
    //   this.authService.audioPlayer.pause();
    // }, 1000);

    // this.dismiss();
  }

  /**
   * dismiss popup
   */
  dismiss() {
    this.modalCtrl.getTop().then(o => {
      if(o) {
        o.dismiss();
      }
    })
  }

  /**
   * hide image on load error
   * @param itemImage
   */
   onItemImageError(itemImage) {
    itemImage = null;
  }

  /**
   * @param value 
   */
  changeNotification(value) {

    this.authService.setToSilenceMode = value;

    if (this.authService.setToSilenceMode) {
      this.authService.audioPlayer.pause();
      this.authService.stopStartBell();

      Storage.set({ 'key': 'setToSilenceMode', value: "true" });
    } else {
      Storage.set({ 'key': 'setToSilenceMode', value: "false" });
    }
  }
}
