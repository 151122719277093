export const environment = {
  envName: 'dev',
  production: false,
  serviceWorker: true,
  recaptchaSiteKey: "6LcEKx8pAAAAALOnvDZ1LvfX6atn39bBw1GHC3ea",
  googleAPIKey: 'AIzaSyCFeQ-wuP5iWVRTwMn5nZZeOE8yjGESFa8',
  secretKey: 'MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDck6gKpUlNFvrZWloSkLOGqq0Fp1e36ePySnin+k0u8vNcx19zX2ZemarAxH',
  apiEndpoint: 'https://agent.dev.plugn.io/v1/',
  storeApiEndpoint: 'https://api.dev.plugn.io/v2/',
  cookieDomain: 'dash.staging.plugn.io',
  tempBucketUrl: 'https://plugn-public-anyone-can-upload-24hr-expiry.s3.amazonaws.com/',
  permanentBucketUrl: 'https://plugn-uploads-dev-server.s3.amazonaws.com/',
  cloudinaryUrl: 'https://res.cloudinary.com/plugn/image/upload/',
  oneSignalAppId: 'e861decd-d541-4a08-92fa-8fc2dcb15aea',
  oneSignalSafariAppId: 'web.onesignal.auto.2c53d929-118c-4db5-ba77-650d97dbe49e',
  mixpanelKey: '98888fdeb1dd2290b1ff29f587e863b9'
};

