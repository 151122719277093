import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// services
import { BusinessLocationService } from 'src/app/services/logged-in/business-location.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-business-location-picker',
  templateUrl: './business-location-picker.component.html',
  styleUrls: ['./business-location-picker.component.scss'],
})
export class BusinessLocationPickerComponent implements OnInit {

  public locations;
  public perPageCount = 20;
  public currentPage = 0;
  public totalPages = 0;
  public totalCount = 0;
  public search: string = null;
  public loading = false;
  public loadingMore = false;

  public borderLimit = false;

  constructor(
    public modalCtrl: ModalController,
    public businessLocationService: BusinessLocationService,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {
    this.loadLocation();
  }

  selected(data = {}) {
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }

  dismiss(event) {
    event.preventDefault();
    event.stopPropagation();
    
    this.selected({});
  }

  loadLocation() {
    this.loading = true;

    this.businessLocationService.list(this.currentPage).subscribe(res => {

      this.perPageCount = parseInt(res.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(res.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);
      this.locations = res.body;
    },
      err => {
        this.loading = false;
      },
      () => this.loading = false,
    );
  }

  loadMoreLocations(event) {

    if(this.currentPage >= this.totalPages) {
      if(event && event.target)
        event.target.complete();
      return null;
    }
    
    this.loadingMore = true;

    this.currentPage += 1;

    this.businessLocationService.list(this.currentPage).subscribe(res => {
      this.perPageCount = parseInt(res.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(res.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);
      this.locations.push(...res.body);

      if(event && event.target)
        event.target.complete();
    },
      err => this.loadingMore = false,
      () => this.loadingMore = false
    );
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
