import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
// services
import { AuthService } from 'src/app/services/auth.service';
import { AuthHttpService } from 'src/app/services/logged-in/authhttp.service';
//models
import { Category } from '../model/Category';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  /**
   * list store categories
   * @param page
   * @param keyword
   * @param pageSize
   * @returns
   */
  list(page: number = 1, keyword = null, pageSize = 20, expand = ''): Observable<any> {
    let url = `category?expand=${expand}&page=${page}&per-page=${pageSize}`;

    if (keyword) {
      url += '&keyword=' + keyword;
    }

    return this.http.getRaw(url);
  }

  /**
   * list category items
   * @param page
   * @param keyword
   * @param pageSize
   * @param expand
   * @param query
   */
  itemList(page: number = 1, keyword = null, pageSize = 20, expand = '', query = null): Observable<any> {
    let url = `category/item-list?expand=${expand}&page=${page}&per-page=${pageSize}&${query}`;

    if (keyword) {
      url += '&keyword=' + keyword;
    }

    return this.http.getRaw(url);
  }

  listAll(pageSize = 100): Observable<any> {
    const url = `category?per-page=${pageSize}`;
    return this.http.get(url);
  }

  /**
   * category detail page
   * @param categoryID
   */
  view(categoryID): Observable<any> {
    const url = `category/detail?category_id=${categoryID}`;
    return this.http.get(url);
  }

  /**
   * create category
   * @param categoryData
   */
  create(categoryData: any): Observable<any> {

    const param = {
      ...categoryData,
      store_uuid: this.authService.store_id
    };
    return this.http.post(`category/create`, param);
  }

  /**
   * update category
   * @param categoryData
   * @param categoryID
   */
  update(categoryData: any, categoryID): Observable<any> {

    const param = {
      ...categoryData
    };
    const url = `category/${categoryID}/${this.authService.store_id}`;
    return this.http.patch(url, param);
  }

  /**
   * Update item
   * @param uuids
   */
  updateSortNumber(uuids: any): Observable<any> {
    return this.http.post(
      'category/update-position',
      {
        items: uuids
      }
    );
  }

  /**
   * remove category
   * @param category 
   * @returns 
   */
  delete(category: Category): Observable<any> {
    return this.http.delete('category/' + category.category_id);
  }
}
