import { Injectable } from '@angular/core';
//services
import { AuthHttpService } from './authhttp.service';
import { AuthService } from "../auth.service";
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(
    private http: AuthHttpService,
    private authService: AuthService
  ) { }

  /**
   * update language preference
   * @param language_pref 
   * @returns 
   */
  updateLanguagePref(language_pref): Observable<any> {
    return this.http.patch(`agent/language-pref`, {
      'language_pref': language_pref
    });
  }

  get_userinfo(): Observable<any> {
    return this.http.get(`agent`);
  }

  getStoreProfile(): Observable<any> {
    const url = `agent/store-profile?expand=agent,restaurant`;
    return this.http.get(url);
  }

  /**
   * Update email address
   * @param email string
   */
   updateEmail(email: string): Observable<any> {
    let url = 'agent/update-email';
    return this.http.post(url, { email });
  }

  /**
   * update profile
   * @param params
   */
  updateProfile(params): Observable<any> {
    return this.http.put(`agent/update`, params);
  }

  /**
   * update profile
   * @param params
   */
  updatePassword(params): Observable<any> {
    return this.http.post(`agent/change-password`, params);
  }

  deleteProfile() : Observable<any> {
    return this.http.delete(`agent`);
  }

  /**
   * list stores assigned to user
   * @returns
   */
  listStores(): Observable<any> {
    return this.http.get(`agent/stores?expand=restaurant`);
  }
}

