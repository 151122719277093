import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
//services
import { EventService } from 'src/app/services/event.service';
import { AreaService } from 'src/app/services/logged-in/area.service';
import { DeliveryAreaService } from 'src/app/services/logged-in/delivery-area.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-delivery-zone-areas',
  templateUrl: './delivery-zone-areas.page.html',
  styleUrls: ['./delivery-zone-areas.page.scss'],
})
export class DeliveryZoneAreasPage implements OnInit {

  public loader: boolean = false;

  public areas = [];

  public delivery_zone_id;

  public city_id;

  public deliveryZones = {};

  public business_location_id;

  saving: boolean = false;

  constructor(
    private navCtrl: NavController,
    public alertCtrl: AlertController,
    public modelCtrl: ModalController,
    public activatedRoute: ActivatedRoute,
    public deliveryAreaService: DeliveryAreaService,
    public areaService: AreaService,
    public eventService: EventService,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {
    this.delivery_zone_id = this.activatedRoute.snapshot.paramMap.get('delivery_zone_id');
    this.city_id = this.activatedRoute.snapshot.paramMap.get('city_id');
    this.business_location_id = this.activatedRoute.snapshot.paramMap.get('business_location_id');

    this.loadData();

    this.eventService.deliveryZoneDeleted$.subscribe((data: any) => {
      if(data.delivery_zone_id == this.delivery_zone_id) {
         this.modelCtrl.dismiss(); 
      }
    }); 
  }

  loadData(sildent = false) {

    if(!sildent)
      this.loader = true;

    /*this.cityService.list().subscribe(response => {
      this.loader = false;

      this.cities = response;
    });*/

    const query = '&city_id=' + this.city_id;

    this.areaService.list(null, query).subscribe(response => {

      this.areas = response.body;

      this.listAreaDeliveryZone();
    })
  }

  listAreaDeliveryZone() {

    const query = '&delivery_zone_id=' + this.delivery_zone_id + '&city_id=' + this.city_id;

    this.deliveryAreaService.list(null, query).subscribe(response => {

      this.deliveryZones = {};

      for(let deliveryZone of response.body) {
        this.deliveryZones[deliveryZone.area_id] = true;
      }

      this.loader = false;
    });
  }

  clearAll() {
    this.deliveryZones = {};

    for(let deliveryZone of this.areas) {
      this.deliveryZones[deliveryZone.area_id] = false;
    }
  }

  selectAll() {
    this.deliveryZones = {};

    for(let deliveryZone of this.areas) {
      this.deliveryZones[deliveryZone.area_id] = true;
    }
  }

  /**
   * save selected areas in delivery zone
   */
  save() {
    this.saving = true;
    let selectedAreas = [];

    for(let area_id in this.deliveryZones) {
      if(this.deliveryZones[area_id]) {
        selectedAreas.push(area_id);
      }
    }

    // this.deliveryAreaService.save(this.delivery_zone_id, this.city_id, selectedAreas).subscribe(async response => {
    //
    //   this.saving = false;
    //
    //   if(response.operation == 'success') {
    //
    //     this.eventService.deliveryAreasUpdated$.next({
    //       delivery_zone_id: this.delivery_zone_id,
    //       city_id: this.city_id
    //     });
    //
    //     //todo: update count in city list
    //     //this.router.navigate(['settings/delivery-zone/delivery-zone-cities/' + business_location_id +'/'+ this.delivery_zone_id]);
    //     this.navCtrl.back();
    //
    //   } else {
    //     const alert = await this.alertCtrl.create({
    //       header: this.translateService.transform('Error'),
    //       message: this.translateService.errorMessage(response.message),
    //       buttons: [
    //         {
    //           text: this.translateService.transform('Okay')
    //         }
    //       ]
    //     });
    //     alert.present();
    //   }
    // });
  }
}
