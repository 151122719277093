import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
//services
import { AnalyticsService } from 'src/app/services/analytics.service';
import { PaymentMethodService } from 'src/app/services/logged-in/payment-method.service';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-tabby',
  templateUrl: './tabby.page.html',
  styleUrls: ['./tabby.page.scss'],
})
export class TabbyPage implements OnInit {

  public borderLimit = false;

  public form: FormGroup;

  public loading: boolean = false;
  
  public saving: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public translateService: TranslateLabelService,
    public storeServices: StoreService,
    public analytics: AnalyticsService,
    public paymentServices: PaymentMethodService
  ) { }

  ngOnInit() {
    this.analytics.page('Tabby config page');

    this.loadData();
  }
  
  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Tabby config page'
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  loadData() {
    this.storeServices.getSettings('Tabby').subscribe(data => {
      this.initForm(data);
    });
  }

  initForm(data) {
    this.form = this.formBuilder.group({
      payment_tabby_secret_key: [data.payment_tabby_secret_key, Validators.required],
      payment_tabby_public_key: [data.payment_tabby_public_key, Validators.required],

      payment_tabby_capture_on: [data.payment_tabby_capture_on || "order_placed"],
      payment_tabby_order_status: [data.payment_tabby_order_status || "1"],//pending status
      payment_tabby_capture_status: [data.payment_tabby_capture_status || "1"],
      
      payment_tabby_promo: [data.payment_tabby_promo || true],
      payment_tabby_promo_theme: [data.payment_tabby_promo_theme],
      
      payment_tabby_cc_installments_status: [data.payment_tabby_cc_installments_status || true],
      payment_tabby_installments_status: [data.payment_tabby_installments_status || true],
      payment_tabby_paylater_status: [data.payment_tabby_paylater_status || true],
      
      payment_tabby_promo_limit: [data.payment_tabby_promo_limit],
      payment_tabby_promo_min_price: [data.payment_tabby_promo_min_price || 0],
      payment_tabby_cancel_status_id: [data.payment_tabby_paylater_status || 5],
      payment_tabby_debug: [data.payment_tabby_paylater_status || true],
    });
  }

  /**
   * save detail
   */
   save() {

    if(!this.form || !this.form.valid) {
      return false;
    }

    this.saving = true;

    this.paymentServices.setConfig("Tabby", this.form.value).subscribe(async response => {

      this.saving = false;

      if(response.operation == 'success') {

        this.dismiss(this.form.value);

        /*this.router.navigate(['/']);
       
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Success'),
          message: response.message,
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();*/

      } else {
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }
    });
  }

  dismiss(data = {}) {
    this.modalCtrl.dismiss(data);
  }
}
