import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
//InfiniteScrollCustomEvent, 
// services
import { AreaService } from "../../services/logged-in/area.service";
import { AuthService } from "../../services/auth.service";
import { TranslateLabelService } from "../../services/translate-label.service";
import { Geolocation } from '@capacitor/geolocation';
import { DeliveryZoneService } from 'src/app/services/logged-in/delivery-zone.service';


@Component({
  selector: 'app-area-location-picker',
  templateUrl: './area-location-picker.component.html',
  styleUrls: ['./area-location-picker.component.scss'],
})
export class AreaLocationPickerComponent implements OnInit {

  public currency_code;
  public currency_rate;

  public areas;
  public perPageCount = 20;
  public currentPage = 0;
  public totalPages = 0;
  public totalCount = 0;
  public search: string = null;
  public loading = false;
  public loadingMore = false;

  public borderLimit = false;

  public country: any;
  public state: any;
  public city: any;
  public area: any;
  public query: string = '';
  public detectingLocation: boolean = false;

  public countries: any;
  public states: any;
  public cities: any;

  constructor(
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public deliveryZoneService: DeliveryZoneService,
    public areaService: AreaService,
    public authService: AuthService,
    public translateService: TranslateLabelService,

  ) { }

  ngOnInit() {
    this.loadCountries();
  }

  onSearch(event: any) {

    this.query = event.target.value;
    if (!this.country) {
      this.loadCountries();
    } else if (this.country && this.country.iso != 'KW' && !this.state) {
      this.loadStates(this.country.country_id);
    } else if ((this.state || this.country.iso == 'KW') && !this.city) {
      this.loadKuwaitCities();
    } else if (this.city && !this.area) {
      this.loadAreas(this.city.city_id);
    }
  }

  /**
   * goToShippingToPageModule
   */
  onCountrySelected(selectedCountry) {

    this.query = '';

    this.currentPage = 1;
    this.totalPages = null;

    this.country = selectedCountry;

    if (this.country.iso == "KW") {
      this.loadKuwaitCities();
    } else {
      this.loadStates(selectedCountry['country_id']);
    }
  }

  onStateSelected(selectedState) {

    this.query = '';

    this.currentPage = 1;
    this.totalPages = null;

    this.state = selectedState;

    this.loadStateCities(selectedState.state_id);
  }

  onCitySelected(selectedCity) {

    this.query = '';

    this.currentPage = 1;
    this.totalPages = null;

    this.city = selectedCity;

    if (this.country.iso == 'KW') {
      this.loadAreas(selectedCity.city_id);
    }
    else {
      this.dismiss({
        city: selectedCity,
        state: this.state,
        country: this.country
      });
    }
  }

  //only for kuwait 
  onAreaSelected(selectedArea) {

    this.query = '';

    if (this.country.iso == "KW") {
      this.city = null;
    }

    this.dismiss({
      area: selectedArea,
      country: this.country,
      city: this.city
    });
  }

  back() {

    if (this.city) {
      this.city = null;
      this.area = null;
    } else if (this.state) {
      this.state = null;
      this.city = null;
      this.area = null;
    } else if (this.country) {
      this.country = null;
    } else {
      this.dismiss();
    }
  }

  dismiss(data = {}) {
    this.modalCtrl.dismiss(data);
  }

  detectLocation() {

    const locationOptions = { enableHighAccuracy: false, maximumAge: Infinity, timeout: 60000 };

    this.detectingLocation = true;

    Geolocation.getCurrentPosition(locationOptions).then(async (resp) => {

      if (resp && resp.coords) {

        // we have permission

        this.areaService.getCityByLocation(resp.coords.latitude, resp.coords.longitude).subscribe(res => {

          this.detectingLocation = false;

          //set location and dismiss 

          if (res.operation == "success") {
            this.dismiss({
              area: res.area,
              city: res.city,
              state: res.state,
              country: res.country
            });
          }
        });

      } else {
        //getLocationByIp();

        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error1'),
          message: this.translateService.transform('We could not get your location'),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();

        this.detectingLocation = false;
      }
    }).catch(async (error) => {

      //console.error(error);

      const alert = await this.alertCtrl.create({
        header: this.translateService.transform('Error2'),
        message: this.translateService.transform('Please provide location permission in your browser'),
        buttons: [this.translateService.transform('Okay')]
      });

      await alert.present();
 
      this.detectingLocation = false;
    });
  }

  loadAreas(city_id, ev = null) {

    this.loading = true;

    this.areaService.getAreas(city_id, this.query, this.currentPage).subscribe(res => {

      if (this.currentPage == 1) {
        this.areas = res.body;  
      } else {
        this.areas = this.areas.concat(res.body);
      }

      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'));

      this.loading = false;

      if (ev) {
        ev.target.complete();
      }
    }, () => {  

      if (ev) {
        ev.target.complete();
      }

      this.loading = false;
    });
  }

  loadKuwaitCities(ev = null) {

    this.loading = true;
 
    this.areaService.getCountryCities(this.country.country_id, this.query, this.currentPage).subscribe(res => {

      if (this.currentPage == 1) {
        this.cities = res.body;
      } else {
        this.cities = this.cities.concat(res.body);
      }

      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'));

      this.loading = false;

      if (ev) {
        ev.target.complete();
      }
    }, () => {

      if (ev) {
        ev.target.complete();
      }

      this.loading = false;
    });
  }

  loadStateCities(state_id, ev = null) {

    this.loading = true; 

    this.areaService.getStateCities(state_id, this.query, this.currentPage).subscribe(res => {

      if (this.currentPage == 1) {
        this.cities = res.body;
      } else {
        this.cities = this.cities.concat(res.body);
      }

      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'));

      this.loading = false;

      if (ev) {
        ev.target.complete();
      }

      //todo: if no city in state in db 

      if (this.cities.length == 0) {

        /*this.dismiss({
          state: this.state,
          country: this.country
        });*/
      }

    }, () => {

      if (ev) {
        ev.target.complete();
      }

      this.loading = false;
    });
  }

  loadStates(country_id, ev = null) {
    this.loading = true;

    this.areaService.getCountryStates(country_id, this.query).subscribe(res => {

      this.loading = false;

      if (ev) {
        ev.target.complete();
      }

      this.states = res;

    }, () => {

      this.loading = false;

      if (ev) {
        ev.target.complete();
      }
    });
  }

  loadCountries() {
    this.loading = true;

    this.deliveryZoneService.listOfCountriesAvailableForDelivery(this.query).subscribe(res => {
      //getCountries(query.value).then(res => {

      this.countries = res;

      this.loading = false;

      if (this.countries.length == 1) {
        this.onCountrySelected(this.countries[0])
      }

    }, () => {
      this.loading = false;
    });
  }

  ionInfiniteArea(ev:  any) {

    if (this.currentPage == this.totalPages) {
      ev.target.complete();
      return false;
    }

    this.currentPage++;

    this.loadAreas(this.city.city_id, ev);
  };

  ionInfiniteCity(ev:  any) {

    if (this.currentPage == this.totalPages) {
      ev.target.complete();
      return false;
    }

    this.currentPage++;

    if (this.country.iso == "KW") {
      this.loadKuwaitCities(ev);
    } else {
      this.loadStateCities(this.state.state_id, ev);
    }
  }

  selected(data = {}) {
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
