import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-custom-item',
  templateUrl: './custom-item.component.html',
  styleUrls: ['./custom-item.component.scss'],
})
export class CustomItemComponent implements OnInit {

  public form: FormGroup;

  public saving: boolean = false;

  public borderLimit: boolean = false;

  public currency;

  constructor(
    public modalCtrl: ModalController,
    public formBuilder: FormBuilder,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      item_name: [null, Validators.required],
      item_price: [null, Validators.required],
      qty: [null, Validators.required],
      weight: [0],
      length: [0],
      height: [0],
      width: [0],
      shipping: [0]
    });
  }

  save() {

    this.dismiss(this.form.value);
  }

  dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        o.dismiss(data);
      }
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
