import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from 'src/app/model/customer';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  public _endpoint = 'customer';

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  /**
   * customer order list
   * @param status
   * @param page
   * @param perPage
   * @param customerID
   */
  orderList(status = '', page, perPage, customerID = ''): Observable<any> {
    const url = `order/${status}?page=${page}&per-page=${perPage}
      &customer_id=${customerID}`;
    return this.http.getRaw(url);
  }

  /**
   * return customer detail
   * @param customer_id
   * @returns
   */
  view(customer_id): Observable<any> {
    const url = `customer/detail?customer_id=${customer_id}`;
    return this.http.get(url);
  }

  /**
   * list customer
   * @param page
   * @param keyword
   * @param perPage
   */
  list(page: number = 1, keyword: string, perPage: number = 1) {
    if (keyword) {
      return this.http.getRaw(`customer?expand=totalOrders&page=${page}&per-page=${perPage}&keyword=${keyword}`);
    } else {
      return this.http.getRaw(`customer?expand=totalOrders&page=${page}&per-page=${perPage}`);
    }
  }

  /**
   * Download excel containing canidates info 
   * @returns {Observable<any>}
   */
  exportCustomers(from = null, to = null): Observable<any> {
    let url = `${this._endpoint}/export-to-excel`;

    if(from && to) {
      url += '?from=' + from + '&to=' + to;
    }
    
    return this.http.excelget(url, `Customers.xlsx`);
  }

  /**
   * Add customer
   * @param customer
   */
   add(customer: Customer): Observable<any> {
    const url = this._endpoint;
    return this.http.post(url, customer);
  }

  /**
   * Update customer
   * @param customer_id
   * @param customer
   */
  update(customer_id: string, customer: Customer): Observable<any> {
    const url = this._endpoint + '/' + customer_id;
    return this.http.patch(url, customer);
  }
}
