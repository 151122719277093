import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
//services
import { AuthService } from '../services/auth.service';
import { TranslateLabelService } from '../services/translate-label.service';


@Pipe({
    name: 'price',
    pure: false
})
export class PricePipe implements PipeTransform {

    constructor(
        private translateService: TranslateLabelService,
        private currencyPipe: CurrencyPipe,
        public authService: AuthService
    ) { }

    transform(value: number, currency_code = this.authService.currency_pref, currency_rate = 1): any {

        value = value * currency_rate;

        return this.currencyPipe.transform(value, currency_code, "symbol", "1.3", this.translateService.currentLang)
            ?.replace(currency_code, currency_code + ' ');
    }
} 