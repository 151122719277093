import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//models
import { Restaurant } from 'src/app/model/restaurant';
import { environment } from 'src/environments/environment';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';
import { StoreKyc } from 'src/app/model/store-kyc';


@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  /**
   * list stores
   * @param expand
   * @returns
   */
  list(expand = ''): Observable<any> {
    const url = `store?expand=role,currency,country,${expand}`;
    return this.http.get(url);
  }

  /**
   * upgrade store
   * @returns 
   */
  upgrade(): Observable<any> {
    const url = `store/upgrade`;
    return this.http.post(url, {});
  }

  /**
   * update bank account in tap
   * @param store 
   * @returns 
   */
  updateBankAccount(store: Restaurant): Observable<any> {
    const url = `store/update-bank-account`;
    return this.http.post(url, {
      iban: store.iban
    });
  }

  /**
   * get store info
   * @returns
   */
  detail(expand = ''): Observable<any> {
    const url = `store?expand=role,currency,currencies,country,activeSubscription,${expand}`;
    return this.http.get(url);
  }

  /**
   * create store
   * @param param
   */
  create(param: any): Observable<any> {
    const params = {
      ...param,
      utm_uuid: this.authService.utm_uuid,
      mobile: param.mobile,
      mobile_country_code: param.mobile_country_code,
      email_notification: param.email_notification ? 1: 0,
      schedule_order: param.schedule_order ? 1: 0,
      is_sandbox: param.is_sandbox ? 1: 0,
      accept_order_247: param.accept_order_247? 1: 0,
      enable_gift_message: param.enable_gift_message ? 1: 0,
      is_public: param.is_public ? 1: 0,
    };
    return this.http.post(`store/create`, params);
  }

  /**
   * update store
   * @param param
   */
  update(param: any): Observable<any> {
    const params = {
      ...param,
      mobile: param.mobile,
      mobile_country_code: param.mobile_country_code,
      email_notification: param.email_notification ? 1: 0,
      schedule_order: param.schedule_order ? 1: 0,
      is_sandbox: param.is_sandbox ? 1: 0,
      enable_guest_checkout: param.enable_guest_checkout? 1: 0,
      accept_order_247: param.accept_order_247? 1: 0,
      enable_gift_message: param.enable_gift_message ? 1: 0,
      is_public: param.is_public ? 1: 0,
    };
    return this.http.post(`store`, params);
  }

  /**
   * update email settings
   * @param param 
   * @returns 
   */
  updateEmailSettings(param: any): Observable<any> {
    const params = {
      host: param.host,
      username: param.username,
      password: param.password,
      port: param.port,
      encryption: param.encryption
    };
    return this.http.post(`store/update-email-settings`, params);
  }

  /**
   * update store settings
   * @param param 
   * @returns 
   */
  updateStoreSettings(params: any): Observable<any> { 
    return this.http.post(`store/update-store-settings`, params);
  }

  /**
   * return settings for given module name
   * @param code 
   * @returns 
   */
  getSettings(code: string): Observable<any> {
    return this.http.get(`store/settings/${code}`);
  }

  deactivateStore() : Observable<any> {
    return this.http.post(`store/deactivate`, {});
  }

  deleteStore() : Observable<any> {
    return this.http.post(`store/remove-store`, {});
  }

  /**
   * connect domain
   * @param store
   * @param purchase
   * @returns
   */
   connectDomain(store: Restaurant, purchase = null): Observable<any> {
    const params = {
      domain: store.restaurant_domain,
      purchase: purchase
    };
    return this.http.post(`store/connect-domain`, params);
  }

  /**
   * disable specific payment method
   * @param store
   * @param payment_method_id
   * @returns
   */
  disablePaymentMethod(store: Restaurant, payment_method_id): Observable<any> {
    const url = `store/disable-payment-method/${store.restaurant_uuid}/${payment_method_id}`;
    return this.http.post(url, {});
  }

  /**
   * enable specific payment method
   * @param store
   * @param payment_method_id
   * @returns
   */
  enablePaymentMethod(store: Restaurant, payment_method_id): Observable<any> {
    const url = `store/enable-payment-method/${store.restaurant_uuid}/${payment_method_id}`;
    return this.http.post(url, {});
  }

  /**
   * list enabled payment method in store
   * @returns
   */
  viewShippingMethods(): Observable<any> {
    const url = `store/view-shipping-methods?expand=shippingMethod`;
    return this.http.get(url);
  }

  /**
   * list enabled payment method in store
   * @param store
   * @returns
   */
  viewPaymentMethods(store: Restaurant): Observable<any> {
    const url = `store/view-payment-methods/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * update delivery integration
   * @param store
   * @returns
   */
  updateDeliveryIntegration(store: Restaurant): Observable<any> {
    const url = `store/update-delivery-integration/${store.restaurant_uuid}`;
    const params = {
      armada_api_key: store.armada_api_key,
      mashkor_branch_id: store.mashkor_branch_id
    };
    return this.http.post(url, params);
  }

  /**
   * update analytic integrations
   * @param store
   * @returns
   */
  updateAnalyticsIntegration(store: Restaurant): Observable<any> {
    const url = `store/update-analytics-integration/${store.restaurant_uuid}`;
    const params = {
      google_analytics_id: store.google_analytics_id,
      facebook_pixil_id: store.facebook_pixil_id,
      snapchat_pixil_id: store.snapchat_pixil_id,
      google_tag_id: store.google_tag_id,
      google_tag_manager_id: store.google_tag_manager_id,
      tiktok_pixel_id: store.tiktok_pixel_id
    };
    return this.http.post(url, params);
  }

  /**
   * add entry in queue to call business, merchant, developer api 
   * @param store 
   * @returns 
   */
  createTapQueue(store: Restaurant) : Observable<any> {
    const url = `store/create-tap-queue/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * @param store
   * @returns
   */
  createTapAccount(store: Restaurant) : Observable<any> {
    const url = `store/create-tap-account/${store.restaurant_uuid}`;
    return this.http.post(url, {
      owner_first_name: store.owner_first_name,
      owner_last_name: store.owner_last_name,
      owner_email: store.owner_email,
      owner_number: store.owner_number,
      owner_phone_country_code: store.owner_phone_country_code,
      company_name: store.company_name,
      vendor_sector: store.vendor_sector,
      business_type: store.business_type,
      identification_file_front_side: store.identification_file_front_side,
      identification_file_back_side: store.identification_file_back_side,
      license_number: store.license_number,
      commercial_license_file: store.commercial_license_file,
      iban_certificate_file: store.iban_certificate_file,
      authorized_signature_file: store.authorized_signature_file,
      iban: store.iban,

      owner_date_of_birth: store.owner_date_of_birth,
      owner_name_title: store.owner_name_title,
      owner_middle_name: store.owner_middle_name,
      owner_nationality: store.owner_nationality,
      tax_number: store.tax_number,
      swift_code: store.swift_code,
      account_number: store.account_number,
      license_type: store.license_type
    });
  }

  updateBusinessDetails(store: Restaurant) : Observable<any> {
    const url = `store/update-business-details/${store.restaurant_uuid}`;
    return this.http.post(url, {
      owner_first_name: store.owner_first_name,
      owner_last_name: store.owner_last_name,
      owner_email: store.owner_email,
      owner_number: store.owner_number,
      owner_phone_country_code: store.owner_phone_country_code,
      company_name: store.company_name,
      vendor_sector: store.vendor_sector,
      business_type: store.business_type,
      license_number: store.license_number,
      iban: store.iban,
      owner_date_of_birth: store.owner_date_of_birth,
      owner_name_title: store.owner_name_title,
      owner_middle_name: store.owner_middle_name,
      owner_nationality: store.owner_nationality,
      tax_number: store.tax_number,
      swift_code: store.swift_code,
      account_number: store.account_number,
      bank_account_name: store.bank_account_name,
      license_type: store.license_type
    });
  }
  
  /**
   * save kyc for tap 
   * @param storeKyc 
   * @returns 
   */
  updateKyc(storeKyc: StoreKyc) {
    const url = `store/update-kyc/${storeKyc.restaurant_uuid}`;
    return this.http.patch(url, storeKyc);
  }

  /**
   * @param store
   * @returns
   */
  uploadDocs(store: Restaurant) : Observable<any> {
    const url = `store/upload-docs/${store.restaurant_uuid}`;
    return this.http.post(url, {
      identification_file_front_side: store.identification_file_front_side,
      identification_file_back_side: store.identification_file_back_side,
      commercial_license_file: store.commercial_license_file,
      iban_certificate_file: store.iban_certificate_file,
      authorized_signature_file: store.authorized_signature_file,
      
      commercial_registration: store.commercial_registration,
      establishment_card: store.establishment_card,
      work_permit: store.work_permit,
      residence_permit: store.residence_permit,
      tax_document: store.tax_document
    });
  }

  /**
   * disable online payment
   * @param store
   * @returns
   */
  disableOnlinePaymentMethod(store: Restaurant): Observable<any> {
    const url = `store/disable-online-payment/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * enable online payment
   * @param store
   * @returns
   */
  enableOnlinePaymentMethod(store: Restaurant): Observable<any> {
    const url = `store/enable-online-payment/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * disable free checkout for store
   * @param store
   * @returns
   */
  disableFreeCheckout(store: Restaurant): Observable<any> {
    const url = `store/disable-free-checkout/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * enable free checkout for store
   * @param store
   * @returns
   */
  enableFreeCheckout(store: Restaurant): Observable<any> {
    const url = `store/enable-free-checkout/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * disable Moysar for store
   * @param store
   * @returns
   */
  disableMoyasar(store: Restaurant): Observable<any> {
    const url = `store/disable-moyasar/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * save apple domain association file for apple pay 
   * @param content 
   * @returns 
   */
  uploadAppleDomainAssociation(content): Observable<any> {
    const url = `store/upload-apple-domain-association`;
    return this.http.post(url, { "content" : content });
  }
  
  enableTabby(store: Restaurant): Observable<any> {
    const url = `store/enable-tabby/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  disableTabby(store: Restaurant): Observable<any> {
    const url = `store/disable-tabby/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * enable Moysar for store
   * @param store
   * @returns
   */
  enableMoyasar(store: Restaurant): Observable<any> {
    const url = `store/enable-moyasar/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  disableStripe(store: Restaurant): Observable<any> {
    const url = `store/disable-stripe/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  enableUPayment(store: Restaurant): Observable<any> {
    const url = `store/enable-upayment/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  disableUPayment(store: Restaurant): Observable<any> {
    const url = `store/disable-upayment/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * enable stripe for store
   * @param store
   * @returns
   */
  enableStripe(store: Restaurant): Observable<any> {
    const url = `store/enable-stripe/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * disable cash on delivery for store
   * @param store
   * @returns
   */
  disableCod(store: Restaurant): Observable<any> {
    const url = `store/disable-cod/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }

  /**
   * enable cash on delivery for store
   * @param store
   * @returns
   */
  enableCod(store: Restaurant): Observable<any> {
    const url = `store/enable-cod/${store.restaurant_uuid}`;
    return this.http.post(url, {});
  }
  
  /**
   * try processing all again
   * @param store 
   * @returns 
   */
  processGatewayQueue(store: Restaurant): Observable<any> {
    const url = `store/process-gateway-queue/${store.restaurant_uuid}`;
    return this.http.patch(url, {});
  }

  /**
   * remove gateway requests
   * @param store 
   * @returns 
   */
  removeGatewayQueue(store: Restaurant): Observable<any> {
    const url = `store/remove-gateway-queue/${store.restaurant_uuid}`;
    return this.http.delete(url);
  }

  /**
   * get tap response
   * @returns 
   */
  testTap(): Observable<any> {
    const url = `store/test-tap`;
    return this.http.get(url);
  }

  /**
   * get store info
   * @returns
   */
  get_storeinfo(): Observable<any> {
    return this.http.get(`store`);
  }

  /**
   * list store categories
   * @returns
   */
  get_store_category(): Observable<any> {
    return this.http.get(`category`);
  }

  /**
   * list customers
   * @param customer_id
   * @param type
   */
  get_store_customers(): Observable<any> {
    return this.http.getRaw(`customer`);
  }

  /**
   * update layout details
   * @param store
   */
  updateLayout(params): Observable<any> {
    const url = `store/update-layout`;
    return this.http.post(url, params);
  }

  /**
   * update status
   * @param status
   */
  updateStatus(status): Observable<any> {
    const url = `store/update-status/${this.authService.store_id}/${status}`;
    return this.http.patch(url, {});
  }

  /**
   * download store sitemap
   * @returns 
   */
   downloadSitemap(): Observable<any> {
    const url = environment.apiEndpoint + 'sitemap'; 
    return this.http.download(url, 'application/xml', 'sitemap.xml');    
  }

  /**
   * list customers
   * @param customer_id
   * @param type
   */
  getStoreStatus(): Observable<any> {
    return this.http.get(`store/status`);
  }
}
