import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {AlertController, ModalController, NavController, Platform, ToastController} from '@ionic/angular';
// services
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/logged-in/settings.service';
import { AgentService } from 'src/app/services/agent.service';
import { BusinessLocationService } from 'src/app/services/logged-in/business-location.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
//components
import { BusinessLocationPickerComponent } from 'src/app/components/business-location-picker/business-location-picker.component';
import {StaffService} from "../../../../../services/logged-in/staff.service";
import {EventService} from "../../../../../services/event.service";
import { CustomValidator } from 'src/app/validators/custom.validator';


@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.page.html',
  styleUrls: ['./add-staff.page.scss'],
})
export class AddStaffPage implements OnInit {

  public staffForm: FormGroup;
  public staff;
  public saving = false;
  public deleting = false;
  public assignmentID = null;
  public businessLocations: any[];
  public borderLimit = false;

  constructor(
    public fb: FormBuilder,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public authService: AuthService,
    public platform: Platform,
    public toastCtrl: ToastController,
    public activatedRoute: ActivatedRoute,
    public translateService: TranslateLabelService,
    public businessLocationService: BusinessLocationService,
    public settingsService: SettingsService,
    public agentService: AgentService,
    public staffService: StaffService,
    public eventService: EventService
  ) {
    this.loadBusinessLocation();
  }

  ngOnInit() {
    // this.assignmentID = this.activatedRoute.snapshot.params.id;
    //
    // if (window.history.state && window.history.state.model) {
    //   this.staff = window.history.state.model;
    // }

    if (window.history.state.model) {
      this.initForm();
    } else if (this.assignmentID) {
      this.loadStaff();
    } else {
      this.initForm();
    }
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }


  initForm() {
    if (this.assignmentID) {

      let business_location;

      if (this.staff.businessLocation) {
        business_location = this.translateService.langContent(
          this.staff.businessLocation.business_location_name,
          this.staff.businessLocation.business_location_name_ar
        );
      }

      this.staffForm = this.fb.group({
        agent_name: [this.staff.agent_name ? this.staff.agent_name : this.staff.agent.agent_name ? this.staff.agent.agent_name : '', Validators.required],
        agent_email: [this.staff.assignment_agent_email, Validators.compose([Validators.required, CustomValidator.emailValidator])],
        role: [(this.staff.assignment_id) ? this.staff.role + '' : '', Validators.required],
        business_location: [business_location],
        business_location_id: [(this.staff.assignment_id) ? this.staff.business_location_id : ''],
      });
    } else {
      this.staffForm = this.fb.group({
        agent_name: ['', Validators.required],
        agent_email: ['', Validators.compose([Validators.required, CustomValidator.emailValidator])],
        role: ['', Validators.required],
        business_location: [''],
        business_location_id: ['']
      });
    }

    this.staffForm.get('role').valueChanges.subscribe(res => {
      if (res != 3) {
        this.staffForm.controls.business_location.setValue(null);
        this.staffForm.controls.business_location.clearValidators();
        this.staffForm.controls.business_location.updateValueAndValidity();
      } else {
        this.staffForm.controls.business_location.setValue(null);
        this.staffForm.controls.business_location.setValidators([Validators.required]);
        this.staffForm.controls.business_location.updateValueAndValidity();
      }

      this.staffForm.markAsDirty();
    });
  }

  /**
   * save form
   */
  save() {
    
    if (!this.staffForm || this.staffForm.invalid) {
      return false;
    }

    this.saving = true;

    let request;
    if (this.assignmentID) {
      request = this.agentService.update(this.staffForm.value, this.assignmentID);
    } else {
      request = this.agentService.create(this.staffForm.value);
    }

    request.subscribe(async res => {

      if (res.operation == 'success') {

        const toast = await this.toastCtrl.create({
          message: this.authService.errorMessage(res.message),
          duration: 2000
        });
        toast.present();

        this.dismiss({ refresh: true });
      } 
      else 
      {
        this.authService.showAlert(
          this.translateService.transform('Error saving staff'),
          this.translateService.errorMessage(res.message),
          this.staffForm.value
        );  
      }

    }, err => this.saving = false,
      () => this.saving = false
    );
  }

  loadBusinessLocation() {
    this.businessLocationService.list().subscribe(res => {
      this.businessLocations = res;
    });
  }

  loadStaff() {
    this.agentService.view(this.assignmentID).subscribe(res => {
      this.staff = res;
      this.initForm();
    });
  }

  /**
   * open popup to select store branches/ business locations
   */
  async selectBusinessLocation() {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: BusinessLocationPickerComponent,
      cssClass: 'short-modal'
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if (data && data.business_location_id) {
      this.staffForm.controls.business_location.setValue(
        this.translateService.langContent(
          data.business_location_name,
          data.business_location_name_ar
        )
      );
      this.staffForm.controls.business_location_id.setValue(data.business_location_id);
      this.staffForm.controls.business_location.markAsDirty();
      this.staffForm.controls.business_location_id.markAsDirty();
      this.staffForm.updateValueAndValidity();
    }
  }

  setRole(value) {
    this.staffForm.controls.role.setValue(value);
    this.staffForm.controls.role.updateValueAndValidity();
  }

  /**
   * Make date readable by Safari
   * @param date
   */
   toDate(date) { 
    if (date) {
      return new Date(date.replace(/-/g, '/'));
    }
  }

  /**
   * close page
   */
  dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        o.dismiss(data);
      } else {
        this.navCtrl.back();
      }
    });
  }

  /**
   * remove staff
   * @param event 
   */
  removeAgent(event) {
    this.alertCtrl.create({
      header: this.translateService.transform('Are you sure you want to delete this staff?'),
      buttons: [
        {
          text: this.translateService.transform('Yes'),
          handler: async () => {
            this.deleting = true;
            this.staffService.remove(this.staff.assignment_id).subscribe(async response => {
              if(response.operation == 'success') {
                this.eventService.agentReload$.next({});
                this.dismiss({ refresh: true });
              } else {
                this.authService.showAlert(
                  this.translateService.transform('Error removing staff'),
                  this.translateService.errorMessage(response.message),
                  this.staffForm.value
                );    
              }
            });
          }
        },
        {
          text: this.translateService.transform('No'),
        }
      ]
    }).then(alert => alert.present());
  }
}
