import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CreateTapAccountPage } from './create-tap-account.page';

const routes: Routes = [
  {
    path: '',
    component: CreateTapAccountPage
  },
  {
    path: 'create-tap-qatar',
    loadChildren: () => import('./create-tap-qatar/create-tap-qatar.module').then( m => m.CreateTapQatarPageModule)
  },
  {
    path: 'create-tap-uae',
    loadChildren: () => import('./create-tap-uae/create-tap-uae.module').then( m => m.CreateTapUaePageModule)
  },
  {
    path: 'create-tap-saudi-arabia',
    loadChildren: () => import('./create-tap-saudi-arabia/create-tap-saudi-arabia.module').then( m => m.CreateTapSaudiArabiaPageModule)
  },
  {
    path: 'create-tap-bahrain',
    loadChildren: () => import('./create-tap-bahrain/create-tap-bahrain.module').then( m => m.CreateTapBahrainPageModule)
  },
  {
    path: 'create-tap-oman',
    loadChildren: () => import('./create-tap-oman/create-tap-oman.module').then( m => m.CreateTapOmanPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreateTapAccountPageRoutingModule {}
