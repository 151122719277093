import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryZoneTaxModule } from '../delivery-zone-tax/delivery-zone-tax.module';
import { DeliveryZoneComponent } from './delivery-zone.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    DeliveryZoneComponent
  ],
  imports: [
    CommonModule,
    DeliveryZoneTaxModule,
    FormsModule,
    IonicModule, 
    TranslateModule.forChild()
  ],
  exports: [
    DeliveryZoneComponent
  ]
})
export class DeliveryZoneModule { }
