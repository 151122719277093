import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { Geolocation } from '@capacitor/geolocation';
import { Network } from '@capacitor/network';
import { environment } from 'src/environments/environment';
// services
import { TranslateLabelService } from 'src/app/services/translate-label.service';


declare var google;

@Component({
  selector: 'app-map-location-picker',
  templateUrl: './map-location-picker.component.html',
  styleUrls: ['./map-location-picker.component.scss'],
})
export class MapLocationPickerComponent implements OnInit {

  @Input() location;
  @Input() latitude;
  @Input() longitude;

  borderLimit = false;

  public map: any;
  public marker;
  private mapsLoaded: boolean = false;
  private networkHandler = null;

  @Output() mapReady = new EventEmitter();

  @ViewChild('searchBar', { static: true }) searchBar: IonSearchbar;
  @ViewChild('mapContainer', { static: true }) mapContainer;

  constructor(
    public modalCtrl: ModalController,
    public translateService: TranslateLabelService,
    //private element: ElementRef
  ) { }

  ngOnInit() {
    this.init().then((res) => {
      this.mapReady.emit();
    }, (err) => {
      console.error(err);
    });
  }

  private init(): Promise<any> {

    return new Promise((resolve, reject) => {

      this.loadSDK().then((res) => {

        this.initMap().then(async (res) => {

          // add marker

          this.addMarker(this.latitude, this.longitude);

          const options = {
            // componentRestrictions: { country: ['kw', 'sa', 'bh'] },
            fields: ['formatted_address', 'geometry', 'name'],
            origin: this.map.getCenter(),
            strictBounds: false,
            types: ['establishment'],
          };

          //this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(card);

          const input = await this.searchBar.getInputElement();

          const autocomplete = new google.maps.places.Autocomplete(input, options);

          // Bind the map's bounds (viewport) property to the autocomplete object,
          // so that the autocomplete requests use the current map bounds for the
          // bounds option in the request.
          autocomplete.bindTo('bounds', this.map);

          /*const infowindow = new google.maps.InfoWindow();
          const infowindowContent = document.getElementById('infowindow-content');
          infowindow.setContent(infowindowContent);*/

          // todo: test on place change settinglocation

          autocomplete.addListener('place_changed', () => {
            // infowindow.close();
            // marker.setVisible(false);
            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
              // User entered the name of a Place that was not suggested and
              // pressed the Enter key, or the Place Details request failed.
              // window.alert("No details available for input: '" + place.name + "'");
              return;
            }

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
              this.map.fitBounds(place.geometry.viewport);
            } else {
              this.map.setCenter(place.geometry.location);
              this.map.setZoom(17);
            }

            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();

            this.marker.setPosition(new google.maps.LatLng(this.latitude, this.longitude));
            // infowindowContent.children["place-name"].textContent = place.name;
            // infowindowContent.children["place-address"].textContent =
            //   place.formatted_address;
          });

          // todo: on drage set location
          this.map.addListener('dragend',  (e) => {

            this.latitude = this.map.center.lat();
            this.longitude = this.map.center.lng();

            this.marker.setPosition(new google.maps.LatLng(this.latitude, this.longitude));
          });

          resolve(true);
        }, (err) => {
          reject(err);
        });

      }, (err) => {

        reject(err);

      });

    });

  }

  private loadSDK(): Promise<any> {

    return new Promise((resolve, reject) => {

      if (!this.mapsLoaded) {

        Network.getStatus().then((status) => {

          if (status.connected) {

            this.injectSDK().then((res) => {
              resolve(true);
            }, (err) => {
              reject(err);
            });

          } else {

            if (this.networkHandler == null) {

              this.networkHandler = Network.addListener('networkStatusChange', (status) => {

                if (status.connected) {

                  this.networkHandler.remove();

                  this.init().then((res) => {
                    this.mapReady.emit();
                  }, (err) => {
                    console.error(err);
                  });

                }

              });

            }

            reject('Not online');
          }

        }, (err) => {

          // NOTE: navigator.onLine temporarily required until Network plugin has web implementation
          if (navigator.onLine) {

            this.injectSDK().then((res) => {
              resolve(true);
            }, (err) => {
              reject(err);
            });

          } else {
            reject('Not online');
          }

        });

      } else {
        reject('SDK already loaded');
      }

    });
  }

  private injectSDK(): Promise<any> {

    return new Promise((resolve, reject) => {

      window['mapInit'] = () => {
        this.mapsLoaded = true;
        resolve(true);
      }

      const body = <HTMLDivElement>document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.async = false;
      script.defer = true;

      if (environment.googleAPIKey) {
        script.src = 'https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=' + environment.googleAPIKey + '&callback=mapInit';
      } else {
        script.src = 'https://maps.googleapis.com/maps/api/js?libraries=places,geometry&callback=mapInit';
      }

      //script.addEventListener('load', window['mapInit']);
      body.appendChild(script);
    });

  }

  private initMap(): Promise<any> {

    return new Promise((resolve, reject) => {

      if(this.latitude && this.latitude) {
        let latLng = new google.maps.LatLng(this.latitude, this.longitude);

        let mapOptions = {
          center: latLng,
          zoom: 17,
          mapTypeId: 'terrain',
          zoomControl: true, // a way to quickly hide all controls
          disableDefaultUI: true, // a way to quickly hide all controls
        };

        this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);

        resolve(true);

        return null;
      }

      Geolocation.getCurrentPosition().then((position) => {

        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;

        let latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

        let mapOptions = {
          center: latLng,
          zoom: 17,
          mapTypeId: 'terrain',
          zoomControl: true, // a way to quickly hide all controls
          disableDefaultUI: true, // a way to quickly hide all controls
        };

        this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);

        resolve(true);

      }, (err) => {

        let latLng;

        if (this.location == 'Kuwait,City') {
          latLng = new google.maps.LatLng('29.375859', '47.9774052');
        } else if (this.location == 'Manama') {
          latLng = new google.maps.LatLng('26.2235305', '50.5875935');
        } else if (this.location == 'Riyadh') {
          latLng = new google.maps.LatLng('24.7135517', '46.6752957');
        }

        this.latitude = latLng.coords.latitude;
        this.longitude = latLng.coords.longitude;

        let mapOptions = {
          center: latLng,
          zoom: 20,
          mapTypeId: 'terrain',
          zoomControl: true, // a way to quickly hide all controls
          disableDefaultUI: true, // a way to quickly hide all controls
        };

        this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);

        resolve(true);

        //reject('Could not initialise map');

      });

    });
  }

  setLocation() {

    const data = {
      latitude: this.latitude,
      longitude: this.longitude
    };

    this.dismiss(data);
  }

  dismiss(data = {}, event = null) {

    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }

  public addMarker(lat: number, lng: number) {

    let latLng = new google.maps.LatLng(lat, lng);

    this.marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: latLng,
      draggable: true,
    });

    this.marker.addListener('dragend', e => {

      this.latitude = this.marker.position.lat();
      this.longitude = this.marker.position.lng();

      this.map.setCenter(this.marker.position);
    });
  }

  logScrolling(event) {

  }
}
