import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
//models
import { ExtraOption } from 'src/app/model/extra_option';
import { Option } from 'src/app/model/item_option';


@Component({
  selector: 'app-item-options-form',
  templateUrl: './item-options-form.page.html',
  styleUrls: ['./item-options-form.page.scss'],
})
export class ItemOptionsFormPage implements OnInit {

  public step = 1;

  public option: Option;

  public type; //product type 
  
  //@Input() 
  constructor(
    public modalCtrl: ModalController
  ) {

    if(!this.option) {
      this.option = new Option;
      this.option.min_qty = 1;
      this.option.max_qty = 1;
    }
  }

  ionViewWillEnter() {
    if (this.option && !this.option.extraOptions) {
      this.option.extraOptions = [];
      this.addNewExtra();
    }
  }

  ngOnInit() {
  }

  close(step, event) {
    event.preventDefault();
    event.stopPropagation();

    if (step == 2) {
      this.step = 1;
      return;
    }
    this.dismiss();
  }

  save(param) {
    if (param == 1) {
      this.step = 2;
    } else {
      this.dismiss(this.option);
    }
  }

  remove(event, stage) {
    if (stage == 'min') {
      if (this.option.min_qty > 0) {
        this.option.min_qty = this.option.min_qty - 1;
      }
    } else {
      if (this.option.max_qty > 0) {
        this.option.max_qty = this.option.max_qty - 1;
      }
    }
  }

  add(event, stage) {
    if (stage == 'min') {
      if (!this.option.min_qty || this.option.min_qty.length == 0) {
        this.option.min_qty = 0;
      }

      this.option.min_qty = parseInt(this.option.min_qty, 10) + 1;
    } 
    else 
    {
      if (!this.option.max_qty || this.option.max_qty.length == 0) {
        this.option.max_qty = 0;
      }

      this.option.max_qty = parseInt(this.option.max_qty, 10) + 1;
    }
  }

  dismiss(data = null) {
    this.removeEmptyOptionExtra();
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }

  addNewExtra() {
    const extra = new ExtraOption();
    extra.extra_option_id = null;
    extra.extra_option_price = 0;

    this.option.extraOptions.push(extra);
  }

  countChange(event) {
    if (!Number(event.target.value) && event.target.value != '0') {
      event.target.value = event.target.value.replace(/[^0-9]*/g, '');
    }

    if(!event.target.value) {
      event.target.value = 0;
    }
  }

  removeOptionExtra(event) {
    if (this.option.extraOptions.length > 1) {
      this.option.extraOptions = this.option.extraOptions.filter((value, i) => i !== event.index);
    }
  }

  removeEmptyOptionExtra() {
    if (this.option.extraOptions.length > 1) {
      this.option.extraOptions = this.option.extraOptions.filter(value => (value && value.extra_option_name));
    }
  }

  validExtraOptions() {
    let valid = 0;
    this.option.extraOptions.forEach(extraOption => {

      if (extraOption.extra_option_name) {
        valid++;
      }

      if((extraOption.extra_option_price+'').length == 0) {
        valid--;
      }
    });

    return (this.option.extraOptions.length == valid);
  }
}
