import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from "@ionic/angular";
// services
import { EventService } from 'src/app/services/event.service';
import { CityService } from 'src/app/services/logged-in/city.service';
import { DeliveryZoneService } from 'src/app/services/logged-in/delivery-zone.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { StateService } from 'src/app/services/logged-in/state.service';
import { DeliveryAreaService } from "../../../../../services/logged-in/delivery-area.service";
import { AuthService } from 'src/app/services/auth.service';
//components
import { DeliveryZoneTaxComponent } from 'src/app/components/delivery-zone-tax/delivery-zone-tax.component';
//pages
import { DeliveryZoneFormPage } from "../delivery-zone-form/delivery-zone-form.page";

@Component({
  selector: 'app-delivery-zone-states',
  templateUrl: './delivery-zone-states.page.html',
  styleUrls: ['./delivery-zone-states.page.scss'],
})
export class DeliveryZoneStatesPage implements OnInit {

  public delivery_zone_id: any;

  public deliveryZone;
  public store_uuid;
  public zone;
  public selectedCity = null;
  public selectedState = null; 
  public business_location_id;

  public cities = [];
  public states = [];
  public saving = null;

  public loading: boolean = false;

  public pageCount = 0;
  public currentPage = 1;
  public totalCount = 0;

  public selectedCities = {};

  constructor(
    public route: ActivatedRoute,
    public cityService: CityService,
    public translateService: TranslateLabelService,
    public deliveryZoneService: DeliveryZoneService,
    public deliveryAreaService: DeliveryAreaService,
    public authService: AuthService,
    public eventService: EventService,
    public stateService: StateService,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {

    // this.delivery_zone_id = this.route.snapshot.paramMap.get('delivery_zone_id');
    // this.business_location_id = this.route.snapshot.paramMap.get('business_location_id');

    const state = window.history.state;

    if (state && state.deliveryZone) {
      this.deliveryZone = state.deliveryZone;
    }

    this.loadData();

    // this.eventService.deliveryAreasUpdated$.subscribe((data: any) => {
    //   if (data.delivery_zone_id == this.delivery_zone_id) {
    //     this.loadCities();
    //   }
    // });

    this.eventService.deliveryZoneDeleted$.subscribe((data: any) => {
      if(data.delivery_zone_id == this.deliveryZone.delivery_zone_id) {
         this.dismiss(); 
      }
    }); 
  }

  loadData() {
    this.loading = true;

    this.deliveryZoneService.view(this.delivery_zone_id).subscribe(response => {
      this.loading = false;

      this.deliveryZone = response;

      this.loadStates();
      this.listAreaDeliveryZone();
    });
  }

  listAreaDeliveryZone() {

    const query = '&delivery_zone_id=' + this.delivery_zone_id;

    this.deliveryAreaService.list(null, query).subscribe(response => {

      /*this.deliveryZones = {};

      for (const deliveryZone of response.body) {
        this.deliveryZones[deliveryZone.area_id] = true;
      }*/

      this.selectedCities = {};

      for (const deliveryZone of response.body) {
        this.selectedCities[deliveryZone.city_id + ''] = true;
      }
    });
  }

  /**
   * load states for delivery zone's country 
   */
  loadStates() {

    this.loading = true;

    const query = '&per-page=100&expand=deliveryToWholeState,totalDeliveryCities&delivery_zone_id=' + this.delivery_zone_id;

    this.stateService.list(this.deliveryZone.country_id, query).subscribe(response => {

      this.loading = false;

      this.states = response.body;

      if (this.states.length > 0) {
        this.selectedState = this.states[0];
        this.loadCities();
      }
    });
  }

  /**
   * load more on scroll to bottom
   * @param event
   */
  doInfinite(event) {

    if(this.currentPage >= this.pageCount) {
      if(event && event.target)
        event.target.complete();
      return null;
    }

    this.loading = true;

    this.currentPage++;

    const query = '&state_id=' + this.selectedState.state_id + '&delivery_zone_id=' + this.delivery_zone_id;

    this.cityService.list(this.currentPage, query).subscribe(response => {

      this.loading = false;

      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));
      this.pageCount = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));

      this.cities = this.cities.concat(response.body);
    },
      error => { },
      () => { 
        if(event && event.target)
          event.target.complete(); 
      }
    );
  }

  /**
   * load state cities 
   */
  loadCities() {

    this.loading = true;

    const query = '&state_id='+ this.selectedState.state_id + '&delivery_zone_id=' + this.delivery_zone_id;

    this.cityService.list(1, query).subscribe(response => {
      this.loading = false;

      this.cities = response.body;

      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));
      this.pageCount = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
    });
  }

  /**
   * close popup if any
   * @param event
   */
  close(event) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        event.preventDefault();
        event.stopPropagation();
        o.dismiss();
      }
    })
  }

  /**
   * save delivery zone's cities 
   * @param event 
   */
  save(event) {
   
    const states = this.states.filter(state => state.deliveryToWholeState)
      .map(state => state.state_id);
 
    this.deliveryAreaService.saveCities(this.delivery_zone_id, states, Object.keys(this.selectedCities)).subscribe(async response => {

      this.saving = false;

      if(response.operation == 'success') {
        this.eventService.deliveryAreasUpdated$.next({
          delivery_zone_id : this.delivery_zone_id
        });
        this.close(event);
      } else {
        this.authService.showAlert(
          this.translateService.transform('Error saving delivery cities'),
          this.translateService.errorMessage(response.message),
          response
        );  
      }
    });
  }

  /**
   * remove VAT value
   * @param zone
   */
  cancelVat(zone) {
    // this.deletingZone = zone.delivery_zone_id;
    this.deliveryZoneService.cancelOverride(zone).subscribe(async response => {
      if (response.operation == 'success') {
        this.loadData();
      } else {
        this.authService.showAlert(
          this.translateService.transform('Error canceling VAT'),
          this.translateService.errorMessage(response.message),
          zone
        );    
      }
    });
  }

  /**
   * override tax for city
   * @param zone
   */
  async updateVat(zone) {
    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: DeliveryZoneTaxComponent,
      cssClass: 'modal-delivery-zone-tax',
      componentProps: {
        tax: zone.delivery_zone_tax
      }
    });
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.tax <= 0) {
      return false;
    }

    const oldZone = Object.assign({}, zone);

    zone.delivery_zone_tax = data.tax;

    this.deliveryZoneService.update(zone).subscribe(async (response: any) => {
      if (response.operation != 'success') {
        zone = oldZone;

        this.authService.showAlert(
          this.translateService.transform('Error'),
          this.translateService.errorMessage(response.message),
          zone
        );  
      }
    });
  }

  dismiss(data = null) {
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }

  stateSelection(state) {
    this.selectedState = state;

    this.loadCities();
  }

  citySelection(city) {
    this.selectedCity = city.city_id;
  }

  /**
   * open popup to update/delete zone
   * @param zone
   */
  async editZone(zone) {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: DeliveryZoneFormPage,
      cssClass: 'popup-modal',
      componentProps: {
        business_location_id: this.business_location_id,
        zone_id: zone.delivery_zone_id
      }
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if (data && data.refresh) {
      zone = data.zone;
    }

    if (data && data.delete) {
      this.loadData();
    }
  }

  selectAll(event) {

    this.selectedState.deliveryToWholeState = event.detail['checked'];

    // add state as delivery zone area 

    if(this.selectedState.deliveryToWholeState) {
      
      this.deliveryZoneService.addStateToDeliveryArea(this.selectedState.state_id, this.delivery_zone_id).subscribe(async response => {
        if (response.operation != 'success') {
          this.authService.showAlert(
            this.translateService.transform('Error'),
            this.translateService.errorMessage(response.message),
            this.selectedState
          );    
        }
      });
    
    } else { // remove state as delivery zone area 

      this.deliveryZoneService.removeStateFromDeliveryArea(this.selectedState.state_id, this.delivery_zone_id).subscribe(async response => {
        if (response.operation != 'success') {
          this.authService.showAlert(
            this.translateService.transform('Error removing state'),
            this.translateService.errorMessage(response.message),
            this.selectedState
          );      
        }
      });
    }
  }

  selectCity(event, city) {
    this.selectedCities[city.city_id+ ''] = event.detail['checked'];
    /*
    Object.assign(this.selectedCities, {
      city.city_id: true
    });*/
  }
}
