import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//models
import { DeliveryZone } from 'src/app/model/DeliveryZone';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class DeliveryZoneService {

  public endpoint: string = "delivery-zone";

  constructor(
    public _authhttp: AuthHttpService,
    public auth: AuthService
  ) { }

  /**
   * list delivery zones by business location
   * @param business_location_id
   * @param page
   * @returns
   */
  list(business_location_id, page = 1): Observable<any> {
    let url = `${this.endpoint}?store_uuid=${this.auth.store_id}&business_location_id=${business_location_id}&page=${page}`;
    return this._authhttp.get(url);
  }

  /**
   * list delivery zones by business location with pagination
   * @param business_location_id
   * @param page
   * @returns
   */
  listWithPages(business_location_id, page = 1): Observable<any> {
    let url = `${this.endpoint}?store_uuid=${this.auth.store_id}&business_location_id=${business_location_id}&page=${page}`;
    return this._authhttp.getRaw(url);
  }

  /**
   * return delivery zone detail
   * @param business_location_id
   * @param delivery_zone_id
   * @returns
   */
  view(delivery_zone_id): Observable<any> {
    let url = `${this.endpoint}/detail?store_uuid=${this.auth.store_id}&delivery_zone_id=${delivery_zone_id}&expand=country,businessLocation,totalAreas` ;
    return this._authhttp.get(url);
  }

  /**
   * add delivery
   * @param model
   * @returns
   */
  add(model: DeliveryZone): Observable<any> {
    const url = this.endpoint + '/create';
    const params = {
      store_uuid: this.auth.store_id,
      country_id: model.country_id,
      business_location_id : model.business_location_id,
      delivery_time: model.delivery_time,
      time_unit: model.time_unit,
      delivery_fee: model.delivery_fee,
      min_charge: model.min_charge,
      delivery_zone_tax: model.delivery_zone_tax,
      deliver_whole_country: model.deliver_whole_country
    }
    return this._authhttp.post(url, params);
  }

  /**
   * update delivery zone
   * @param model 
   * @returns 
   */
  update(model: DeliveryZone): Observable<any> {
    const url = this.endpoint + '/' + model.delivery_zone_id + '/' + this.auth.store_id;
    const params = {
      store_uuid: this.auth.store_id,
      country_id: model.country_id,
      business_location_id : model.business_location_id,
      delivery_time: model.delivery_time,
      time_unit: model.time_unit,
      delivery_fee: model.delivery_fee,
      min_charge: model.min_charge,
      delivery_zone_tax: model.delivery_zone_tax,
      deliver_whole_country: model.deliver_whole_country
    }
    return this._authhttp.patch(url, params);
  }

  /**
   * delete delivery zone
   * @param model 
   * @returns 
   */
  delete(model: DeliveryZone): Observable<any> {
    const url = this.endpoint + '/' + model.delivery_zone_id + '/' + this.auth.store_id;
    return this._authhttp.delete(url);
  }

  /**
   * cancel tax override
   * @param model 
   * @returns 
   */
  cancelOverride(model: DeliveryZone): Observable<any> {
    const url = this.endpoint + '/cancel-override/' + model.delivery_zone_id + '/' + this.auth.store_id;
    return this._authhttp.delete(url);
  }
  
  /**
   * Get states
   */
  getStates(country_id, keyword = ''): Observable<any> {
    let url = this.endpoint + '/states/' + country_id + '?expand=&keyword=' + keyword;//areaDeliveryZone
    return this._authhttp.getRaw(url);
  }

  /**
   * Get cities
   */
  getCities(state_id, keyword = ''): Observable<any> {
    let url = this.endpoint + '/cities/' + state_id + '?expand=&keyword=' + keyword;//areaDeliveryZone,deliveryZone
    return this._authhttp.getRaw(url);
  }
   
  /**
   * Get delivery zone
   */
  getDetailByLocation(city_id, state_id, country_id): Observable<any> {
    let url = this.endpoint + '/detail-by-location?expand=&city_id=' + city_id + '&state_id=' + state_id + '&country_id=' + country_id;
    return this._authhttp.get(url);
  }
 
  /**
   * Get DeliveryAreaList
   */
  getDeliveryAreaList(countryId): Observable<any> {
    let url = this.endpoint + '/list-of-areas/' + this.auth.store.restaurant_uuid + '/' + countryId;
    return this._authhttp.get(url);
  }

  /**
   * Get listOfCountriesAvailableForDelivery
   */
  listOfCountriesAvailableForDelivery(): Observable<any> {
    let url = this.endpoint + '/list-of-countries/' + this.auth.store.restaurant_uuid+ '?expand=areaDeliveryZone';
    return this._authhttp.get(url);
  }

  /**
   * add state as delivery area 
   * @param state_id 
   * @param delivery_zone_id 
   * @returns 
   */
  addStateToDeliveryArea(state_id, delivery_zone_id): Observable<any> {
    let url = this.endpoint + '/add-state-to-delivery-area';
    return this._authhttp.post(url, {
      state_id: state_id,
      delivery_zone_id: delivery_zone_id
    });
  }

  /**
   * remove state from delivery area 
   * @param state_id 
   * @param delivery_zone_id 
   * @returns 
   */
  removeStateFromDeliveryArea(state_id, delivery_zone_id): Observable<any> {
    let url = this.endpoint + '/remove-state-from-delivery-area/' + state_id + '/' + delivery_zone_id;
    return this._authhttp.delete(url);
  }
}
