import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderShopPopupPageRoutingModule } from './header-shop-popup-routing.module';
import { HeaderShopPopupPage } from './header-shop-popup.page';
import { RouterModule } from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        IonicModule,
      HeaderShopPopupPageRoutingModule,
        TranslateModule
    ],
  declarations: [HeaderShopPopupPage]
})
export class HeaderShopPopupPageModule {}
