import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
//services
import { AuthService } from 'src/app/services/auth.service';
import { OrdersService } from 'src/app/services/logged-in/orders.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-schedule-pickup',
  templateUrl: './schedule-pickup.page.html',
  styleUrls: ['./schedule-pickup.page.scss'],
})
export class SchedulePickupPage implements OnInit {

  public order_uuid: string;

  public form: FormGroup;

  public scheduling: boolean = false;

  public minDate; 

  public borderLimit: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public authService: AuthService,
    public translateService: TranslateLabelService,
    public orderService: OrdersService
  ) { }

  ngOnInit() {
    if(!this.order_uuid) {
      this.order_uuid = this.activatedRoute.snapshot.params.id;
    }

    this.minDate = (new Date()).toISOString();

    this.initForm();
  }

  /**
   * initialize customer form 
   */
  initForm() {
    
    this.form = this.formBuilder.group({
      
      shipper_name: ['', Validators.required],
      shipper_company: ['', Validators.required],
      shipper_phone_prefix: ['', Validators.required],
      shipper_phone: ['', Validators.required],
      shipper_email: [''],
      shipper_address: [''],
      shipper_location: [''],

      pickup_vehicle: [''],
      product_group: ['', Validators.required],
      product_type: ['', Validators.required],
      payment_type: ['', Validators.required],
      no_shipments: ['', Validators.required],
      total_count: ['', Validators.required],
      
      status: ['', Validators.required],
      ready_time: [''],
      closing_time: [''],
      comment: [''],
      date: [''],
    });
  }

  setPhoneNumber(event) {

    this.form.controls.shipper_phone.setValue(event.phone_number);
    this.form.controls.shipper_phone_prefix.setValue(event.country_code);
    
    this.form.controls.shipper_phone.markAsDirty();
    this.form.controls.shipper_phone_prefix.markAsDirty();

    this.form.controls.shipper_phone.updateValueAndValidity();
    this.form.controls.shipper_phone_prefix.updateValueAndValidity();
  }

  /**
   * dismiss popup
   * @param data 
   */
  dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        o.dismiss(data);
      }
    });
  }

  save() {
    this.scheduling = true; 

    let params = this.form.value;
    params.ready_time = format(parseISO(this.form.controls.ready_time.value), 
      'yyyy-MM-dd HH:mm:ss');//, { timeZone: '+3:30' }
    params.closing_time = format(parseISO(this.form.controls.closing_time.value), 
      'yyyy-MM-dd HH:mm:ss');//, { timeZone: '+3:30' }
    params.date = format(parseISO(this.form.controls.date.value), 
      'yyyy-MM-dd HH:mm:ss');//, { timeZone: '+3:30' }

    this.orderService.scheduleAramexPickup(this.order_uuid, this.form.value).subscribe(resp => {

      if(resp.operation == "success") {
        
        this.dismiss(resp);

        this.alertCtrl.create({
          header: this.translateService.transform("Success"),
          message: this.authService.errorMessage(resp.message)
        }).then(alert => alert.present());

      } else {
        this.alertCtrl.create({
          message: this.authService.errorMessage(resp.message)
        }).then(alert => alert.present());
      }
    }, err => {

    }, () => {
      this.scheduling = false; 
    })
  }

  get internationalMethods() {
    return [
      { 'value': 'DPX', 'label': 'Value Express Parcels' },
      { 'value': 'EDX', 'label': 'Economy Document Express' },
      { 'value': 'EPX', 'label': 'Economy Parcel Express' },
      { 'value': 'GDX', 'label': 'Ground Document Express' },
      { 'value': 'GPX', 'label': 'Ground Parcel Express' },
      
      { 'value': 'IBD', 'label': 'International defered' },
      { 'value': 'PDX', 'label': 'Priority Document Express' },
      { 'value': 'PLX', 'label': 'Priority Letter Express (<.5 kg Docs)' },
      { 'value': 'PPX', 'label': 'Priority Parcel Express' },
    ];
  }

  get domesticMethods() {
    return [
      {'value': 'BLK', 'label': 'Special: Bulk Mail Delivery' },
      { 'value': 'BLT', 'label': 'Domestic - Bullet Delivery' },	
      { 'value': 'CDA', 'label': 'Special Delivery' },
      { 'value': 'CDS', 'label': 'Special: Credit Cards Delivery' },
      { 'value': 'CGO', 'label': 'Air Cargo (India)' },
      
      { 'value': 'COM', 'label': 'Special: Cheque Collection' },
      { 'value': 'DEC', 'label': 'Special: Invoice Delivery' },
      { 'value': 'EMD', 'label': 'Early Morning delivery' },
      { 'value': 'FIX', 'label': 'Special: Bank Branches Run' },
      { 'value': 'LGS', 'label': 'Logistic Shipment' },
      
      { 'value': 'OND', 'label': 'Overnight (Document)' },
      { 'value': 'ONP', 'label': 'Overnight (Parcel)' },
      { 'value': 'P24', 'label': 'Road Freight 24 hours service' },
      { 'value': 'P48', 'label': 'Road Freight 48 hours service' },
      { 'value': 'PEC', 'label': 'Economy Delivery' },
      
      { 'value': 'PEX', 'label': 'Road Express' },
      { 'value': 'SFC', 'label': 'Surface  Cargo (India)' },
      { 'value': 'SMD', 'label': 'Same Day (Document)' },
      { 'value': 'SMP', 'label': 'Same Day (Parcel)' },
      { 'value': 'SPD', 'label': 'Special: Legal Branches Mail Service' },
      
      { 'value': 'SPL', 'label': 'Special : Legal Notifications Delivery' },
    ];
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

}
