import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class BusinessLocationService {

  public endpoint: string = "business-location";

  constructor(
    public _authhttp: AuthHttpService,
    public auth: AuthService
  ) { }

  listPickupLocations() {
    let url = this.endpoint + '?store_uuid=' + this.auth.store_id + '&expand=totalDeliveryZoneCountry,country,deliveryZones.country&list_all=1&support_pick_up=1';
    return this._authhttp.get(url);
  }
  
  listBusinessLocations() {
    let url = this.endpoint + '?store_uuid=' + this.auth.store_id + '&expand=totalDeliveryZoneCountry,country,deliveryZones.country&list_all=1';
    return this._authhttp.get(url);
  }

  list(page = 1, onlyPickup: boolean = false): Observable<any> {
    let url = `${this.endpoint}?store_uuid=${this.auth.store_id}&page=${page}&expand=totalDeliveryZoneCountry,country,deliveryZones.country`;

    if(onlyPickup) {
      url += '&support_pick_up=1';
    }

    return this._authhttp.getRaw(url);
  }

  /**
   * View business location detail
   */
  view(business_location_id): Observable<any> {
    const url = this.endpoint + '/detail?store_uuid='+ this.auth.store_id +'&business_location_id=' + business_location_id
      + '&expand=totalDeliveryZoneCountry,country,deliveryZones,deliveryZones.country,deliveryZones.totalAreas';
    return this._authhttp.get(url);
  }

  /**
   * Add business location
   * @param params
   */
  add(params): Observable<any> {
    const url = this.endpoint + '/create';
    return this._authhttp.post(url, params);
  }

  /**
   * Update education detail
   * @param business_location_id
   * @param params
   */
  update(business_location_id: string, params): Observable<any> {
    const url = this.endpoint + '/' + business_location_id + '/' + this.auth.store_id;
    return this._authhttp.patch(url, params);
  }

  /**
   * Update education detail
   * @param business_location_id
   */
  delete(business_location_id: string): Observable<any> {
    const url = this.endpoint + '/' + business_location_id + '/' + this.auth.store_id;
    return this._authhttp.delete(url);
  }
}
