import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
// services
import { ItemsService } from 'src/app/services/logged-in/items.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
//models
import { Item } from 'src/app/model/Item';


@Component({
  selector: 'app-item-inventory',
  templateUrl: './item-inventory.page.html',
  styleUrls: ['./item-inventory.page.scss'],
})
export class ItemInventoryPage implements OnInit {

  public items = [];
  public columns;
  public perPageCount = 20;
  public currentPage = 0;
  public totalPages = 0;
  public totalCount = 0;
  public search: string = null;
  public loading = false;
  public borderLimit = false;

  constructor(
    public router: Router,
    public authService: AuthService,
    public itemServices: ItemsService,
    public toastCtrl: ToastController,
    public platform: Platform,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    public translateService: TranslateLabelService
  ) {
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  async ngOnInit() {
    // this.loadData();
  }

  ionViewWillEnter() {
    this.loadData();
  }

  loadData() {
    this.currentPage = 1;
    this.loading = true;

    const params = this.getParams();

    this.itemServices.list(this.currentPage, params).subscribe((response) => {
      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'), 10);
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'), 10);
      this.items = this.formatFieldValue(response.body);
    },
      err => this.loading = false,
      () => this.loading = false,
    );
  }

  /**
   * url query string
   * @returns
   */
  getParams() {
    let params = '&type=inventory&expand=currency,itemImage,itemImages,categoryItems,categoryItems.category,extraOptions';

    if (this.search && this.search.length > 0) {
      params += '&keyword=' + this.search;
    }

    if (this.perPageCount) {
      params += '&per-page=' + this.perPageCount;
    }

    return params;
  }

  formatFieldValue(items: any) {
    const mappedOrders = [];
    let i = 0;
    for (let item of items) {

      item = Object.assign({}, item);

      mappedOrders.push(item);

      mappedOrders[i].id = i + 1;

      mappedOrders[i].item_status = (item.item_status === 1) ? this.translateService.transform('Published') :
        this.translateService.transform('Unpublished');

      mappedOrders[i].sort_number = (item.sort_number === 1) ? this.translateService.transform('Paid') :
        this.translateService.transform('Pending');

      mappedOrders[i].stock = item.stock_qty;

      mappedOrders[i].sku = item.sku;

      if (item.itemImage) {
        mappedOrders[i].image = `https://res.cloudinary.com/plugn/image/upload/c_scale,h_60,w_60/restaurants/${this.authService.store_id}/items/${item.itemImage.product_file_name}`;
      }
      i++;
    }
    return mappedOrders;
  }

  filter() {
    this.currentPage = 1;
    this.loadData();
  }

  countChange(event) {
    if (event.type == 'pagination') {
      this.currentPage = event.page;
      this.loadData();
    } else if (event.type == 'quantity') {
      this.updateQuantity(event.data);
    }
  }

  /**
   * item
   * @param item
   */
  async updateQuantity(item: Item) {
    let loading = await this.loadingCtrl.create({});
    loading.present();
    this.itemServices.updateQuantity(item.item_uuid, item).subscribe(res => {
      this.toastCtrl.create({
        message: this.authService.errorMessage(res.message),
        duration: 3000
      }).then(toast => {
        toast.present();
      });

      if (res.operation == "success") {
        this.filter();
      }
    },
      error => loading.dismiss(),
      () => loading.dismiss()
    );
  }

  getImgUrl(item) {
    if (item.itemImage && item.itemImage.product_file_name)
      return `https://res.cloudinary.com/plugn/image/upload/c_scale,h_60,w_60/restaurants/${this.authService.store_id}/items/${item.itemImage.product_file_name}`;
    else
      return 'assets/icon/icon.png';
  }

  /**
   * load more inactive segment data on scroll to bottom
   * @param event
   */
  doInfiniteInactive(event) {

    if(this.currentPage >= this.totalPages) {
      if(event && event.target)
        event.target.complete();
      return null;
    }

    this.loading = true;

    this.currentPage++;

    const params = this.getParams();

    this.itemServices.list(this.currentPage, params).subscribe((response) => {
      this.loading = false;

      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'), 10);
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'), 10);
      this.items = this.items.concat(response.body);

      if(event && event.target)
        event.target.complete();

    }, () => {
      this.loading = false;
    });
  }

  changeQnty($event, item) {
    this.updateQuantity(item);
  }

  dismiss(refresh = false, event) {
    event.preventDefault();
    event.stopPropagation();

    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss({ refresh });
      }
    });
  }
}
