import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class AreaService {

  public endpoint: string = "areas";

  constructor(
    public _authhttp: AuthHttpService,
    public auth: AuthService
  ) { }

  /**
   * list area
   * @param page 
   * @param query 
   * @returns 
   */
  list(page = null, query = ''): Observable<any> {
    let url = `${this.endpoint}?${query}`;

    if(page) 
      url += `&page=${page}`;

    return this._authhttp.getRaw(url);
  }

  /**
   * load delivery areas
   * @param page 
   * @param query 
   * @returns 
   */
  listDeliveryAreas(page = null, query = ''): Observable<any> {

    let url = `${this.endpoint}/delivery-areas?${query}`;

    if(page) 
      url += `&page=${page}`;

    return this._authhttp.getRaw(url);
  }
}
