import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarModal, CalendarModalOptions, CalendarResult } from 'ion2-calendar';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
//models
import { Voucher } from 'src/app/model/Voucher';
// services
import { DiscountsService } from 'src/app/services/logged-in/discounts.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { EventService } from 'src/app/services/event.service';


@Component({
  selector: 'app-voucher-form',
  templateUrl: './voucher-form.page.html',
  styleUrls: ['./voucher-form.page.scss'],
})
export class VoucherFormPage implements OnInit {

  public voucher: Voucher = new Voucher();
  public voucherForm: FormGroup;
  public voucherID: number = null;
  public saving = false;
  public borderLimit: boolean = false;

  constructor(
    public fb: FormBuilder,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController,
    public authService: AuthService,
    public translateService: TranslateLabelService,
    public discountServices: DiscountsService,
    public eventService: EventService,
    public activatedRoute: ActivatedRoute,
    public alertCtrl: AlertController
  ) {

  }

  async ngOnInit() {
    if (this.voucher && this.voucher.voucher_id) {
      this.voucherID = this.voucher.voucher_id;
    } else {
      this.voucherID = this.activatedRoute.snapshot.params.id;
    }

    this.initForm();

    const state = window.history.state;

    // if (state && state.model) {
    //   this.voucher = state.model;
    //   this.initForm();
    // } else

    if (this.voucherID) {
      this.view();
    } else {
      this.initForm();
    }
  }

  initForm() {

    this.voucherForm = this.fb.group({
      code: [this.voucher.code || '', Validators.required],
      description: [(this.voucher.description) ? this.voucher.description : '', Validators.required],
      description_ar: [this.voucher.description_ar || '', Validators.required],
      discount_amount: [this.voucher.discount_amount || '', Validators.required],
      discount_type: [(this.voucher.discount_type) ? this.voucher.discount_type + "" : "1", Validators.required],
      limit_per_customer: [this.voucher.limit_per_customer != null ? this.voucher.limit_per_customer : '', Validators.required],
      max_redemption: [this.voucher.max_redemption != null ? this.voucher.max_redemption : '', Validators.required],
      minimum_order_amount: [this.voucher.minimum_order_amount != null ? this.voucher.minimum_order_amount : '', Validators.required],
      valid_from: [this.voucher.valid_from || ''],
      valid_until: [this.voucher.valid_until || ''],
      exclude_discounted_items: [this.voucher.exclude_discounted_items ],
      duration: [''],
      is_public: [this.voucher.is_public],
    });

    if (this.voucher && this.voucher.valid_from && this.voucher.valid_until) {
      this.voucherForm.controls.duration.setValue(
        this.translateService.transform('txt_duration', {
          from: this.voucher.valid_from.split(' ')[0],
          to: this.voucher.valid_until.split(' ')[0]
        })
      );
    }

    if (this.voucher && this.voucher.discount_type == '3') {
      this.voucherForm.controls.discount_amount.clearValidators();
      this.voucherForm.controls.discount_amount.updateValueAndValidity();
      this.voucherForm.markAsDirty();
    }

    // this.voucherForm.get('discount_type').valueChanges.subscribe(val => {
    //   if (val != 3) {
    //     this.voucherForm.controls.discount_amount.setValidators([Validators.required]);
    //   } else {
    //     this.voucherForm.controls.discount_amount.clearValidators();
    //   }
    //   this.voucherForm.controls.discount_amount.updateValueAndValidity();
    //   this.voucherForm.markAsDirty();
    // });

    if(this.voucherForm.controls.discount_type.value != 3 ){

      if(this.voucherForm.controls.discount_type.value == 1)
        this.voucherForm.controls.discount_amount.setValidators([,Validators.min(0),Validators.max(100), Validators.required]);
      else if(this.voucherForm.controls.discount_type.value == 2){
        this.voucherForm.controls.discount_amount.setValidators([Validators.min(0), Validators.required]);
      }

      this.voucherForm.controls.discount_amount.updateValueAndValidity();
      this.voucherForm.markAsDirty();
    }


    this.voucherForm.get('discount_type').valueChanges.subscribe(val => {
      if (val != 3) {
        this.voucherForm.controls.discount_amount.setValidators([Validators.required]);

      if(val == 1)
        this.voucherForm.controls.discount_amount.setValidators([,Validators.min(0),Validators.max(100), Validators.required]);
      else if(val == 2){
        this.voucherForm.controls.discount_amount.setValidators([Validators.min(0), Validators.required]);
      }


      } else {
        this.voucherForm.controls.discount_amount.clearValidators();
      }
      this.voucherForm.controls.discount_amount.updateValueAndValidity();
      this.voucherForm.markAsDirty();
    });
  }

  toggleExcludeDiscount(event) {
 
    if(event.detail.checked) {
      this.voucherForm.controls.exclude_discounted_items.setValue(1);
    } else {
      this.voucherForm.controls.exclude_discounted_items.setValue(0);
    }
    this.voucherForm.controls.exclude_discounted_items.updateValueAndValidity();
  }

  toggleIsPublic(event) {
 
    if(event.detail.checked) {
      this.voucherForm.controls.is_public.setValue(1);
    } else {
      this.voucherForm.controls.is_public.setValue(0);
    }
    
    this.voucherForm.controls.is_public.updateValueAndValidity();
  }

  view() {
    this.discountServices.view(this.voucherID).subscribe(res => {
      this.voucher = res;
      this.initForm();
    });
  }

  async save() {

    if (!this.voucherForm || this.voucherForm.invalid) {
      return false;
    }

    this.saving = true;

    let request;
    if (this.voucherID) {
      request = this.discountServices.update(this.voucherForm.value, this.voucherID);
    } else {
      request = this.discountServices.create(this.voucherForm.value);
    }
    request.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: this.authService.errorMessage(res.message),
        duration: 2000
      });
      toast.present();

      if (res.operation == 'success') {

        this.eventService.refreshVouchers$.next({});

        this.navCtrl.back();
      }
    }, err => this.saving = false,
      () => this.saving = false
    );
  }

  async openCalendar() {

    if (this.translateService.currentLang == 'ar') {
      moment.locale('ar');
    } else {
      moment.locale('en-US');
    }

    const options: CalendarModalOptions = {
      canBackwardsSelected: false,
      pickMode: 'range',
      title: '',
      doneLabel: this.translateService.transform('Save'),
      closeLabel: this.translateService.transform('Close'),
      clearLabel: this.translateService.transform('Clear'),
      defaultScrollTo: new Date(this.voucherForm.value.valid_from ? this.voucherForm.value.valid_until : new Date()),
      defaultDateRange: {
        from: new Date(this.voucherForm.controls.valid_from.value),
        to: new Date(this.voucherForm.controls.valid_until.value)
      },
      defaultEndDateToStartDate: true
    };

    window.history.pushState({
      navigationId: window.history.state.navigationId
    }, null, window.location.pathname);

    const myCalendar = await this.modalCtrl.create({
      component: CalendarModal,
      cssClass: 'modal-calender',
      componentProps: { options }
    });
    myCalendar.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    myCalendar.present();

    const event: any = await myCalendar.onDidDismiss();
    const date = event.data;

    if (date && date.from && date.to) {

      const from: CalendarResult = date.from;
      const to: CalendarResult = date.to;

      if (from.string) {
        this.voucherForm.controls.valid_from.setValue(from.years + '-' + from.months + '-' + from.date);
      }

      if (to.string) {
        this.voucherForm.controls.valid_until.setValue(to.years + '-' + to.months + '-' + to.date);
      }

      this.voucherForm.controls.duration.setValue(
        this.translateService.transform('txt_duration', {
          from: this.voucherForm.controls.valid_from.value,
          to: this.voucherForm.controls.valid_until.value
        })
      );

      this.voucherForm.updateValueAndValidity();
    }
  }

  /**
   * Make date readable by Safari
   * @param date
   */
  toDate(date) {
    if (date && Date.parse(date)) {
      return new Date(date.replace(/-/g, '/'));
    }
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  delete() {
    this.alertCtrl.create({
      header: this.translateService.transform('Remove Voucher?'),
      buttons: [
        {
          text: this.translateService.transform('Dismiss'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.translateService.transform('Yes'),
          handler: () => {
            this.discountServices.delete(this.voucherID).subscribe(res => {

              if (res.operation == 'success') {

                this.eventService.voucherDeleted$.next({
                  voucher_id: this.voucherID,
                  voucher_status: this.voucher.voucher_status
                });

                this.navCtrl.navigateBack('/discounts/voucher');

              } else {
                this.toastCtrl.create({
                  message: res.message,
                  duration: 3000,
                }).then(toast => toast.present());
              }
            });
          }
        }
      ]
    }).then(alert => alert.present());
  }

  close(event) {

    this.modalCtrl.getTop().then(o => {
      if (o) {

        event.preventDefault();
        event.stopPropagation();

        o.dismiss();
      }
    })
  }
}
