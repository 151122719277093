import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ArmadaPageRoutingModule } from './armada-routing.module';

import { ArmadaPage } from './armada.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ArmadaPageRoutingModule
  ],
  declarations: [ArmadaPage]
})
export class ArmadaPageModule {}
