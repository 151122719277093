import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';
//models
import { Option } from 'src/app/model/item_option';
import { ExtraOption } from 'src/app/model/extra_option';


@Component({
  selector: 'app-item-option',
  templateUrl: './item-options.component.html',
  styleUrls: ['./item-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ItemOptionsComponent),
      multi: true
    }
  ]
})
export class ItemOptionsComponent implements ControlValueAccessor, OnInit {

  @Output() removeOption = new EventEmitter<any>();
  @Output() addOption = new EventEmitter<any>();
  
  @Input() index;

  // Default value the form element should have
  // (In case an image has already been uploaded for it)
  option: Option = new Option();

  public type; //product type 

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  private propagateChange = (_: any) => { };

  constructor(
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() { 
  }

  /**
   * Getter for Value
   */
  get value() {
    return this.option;
  }
  /**
   * Setter for Value
   */
  set value(val) {
    this.option = val;
    // Notify of changes
    this.propagateChange(this.option);
  }

  /**
   * ControlValueAccessor interface methods
   * - They allow this component to be used as a form element (with validation and ngModel)
   */

  /**
   * Called on form Init / Update
   * @param {*} obj
   */
  writeValue(obj: any) {
    if (obj) {
      this.value = obj;
    }
  }

  /**
   * Propogate change on change, notify outside world of changes
   * @param {any} fn
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /**
   * Called on touch/ element blur
   */
  registerOnTouched() { }

  addOptionExtra() {
    const extra = new ExtraOption();
    extra.extra_option_id = null;
    this.option.extraOptions.push(extra);
  }

  removeoption(option) {
    this.removeOption.emit(option);
  }

  removeOptionExtra(event) {
    if (this.option.extraOptions.length > 1) {
      this.option.extraOptions = this.option.extraOptions.filter((value, i) => i !== event.index);
    }
  }

  addOptionMethod(event) {
    this.addOption.emit(event);
  }
}
