import { CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeAr from '@angular/common/locales/ar-KW';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';//@ionic-native/onesignal/ngx
import { FileChooser } from '@awesome-cordova-plugins/file-chooser/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CalendarModule } from 'ion2-calendar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { RouteReuseStrategy } from '@angular/router';
import { HeaderProfilePopupPageModule } from './modals/header-profile-popup/header-profile-popup.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SelectiveLoadingStrategy } from './util/SelectiveLoadingStrategy';
import { AuthService } from './services/auth.service';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { UpdateAlertModule } from './components/update-alert/update-alert.module';
import { LocationPickerModule } from './components/location-picker/location-picker.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ImageUploadModule } from './components/image-upload/image-upload.module';
import { MapLocationPickerModule } from './components/map-location-picker/map-location-picker.module';
import {BusinessLocationPickerModule} from './components/business-location-picker/business-location-picker.module';
import {HeaderShopPopupPageModule} from "./modals/header-shop-popup/header-shop-popup.module";
import { OrderAlertModule } from './components/order-alert/order-alert.module';
import { OrderViewPageModule } from './pages/logged-in/order/order-view/order-view.module';
import { OrderInvoicePageModule } from './pages/logged-in/order/order-invoice/order-invoice.module';
import { SentryErrorhandlerService } from './services/sentry.errorhandler.service';
import {ItemCategoryPickerModule} from "./components/item-category-picker/item-category-picker.module";
import { CustomersModule } from './modals/customers/customers.module';
import { DeliveryZoneTaxModule } from './components/delivery-zone-tax/delivery-zone-tax.module';
import { DeliveryZoneModule } from './components/delivery-zone/delivery-zone.module';
import {ItemFormPageModule} from "./pages/logged-in/item/item-form/item-form.module";
import { OrderRefundPageModule } from './pages/logged-in/order/order-refund/order-refund.module';
import {ItemOptionsFormPageModule} from "./pages/logged-in/item/item-form/item-options-form/item-options-form.module";
import { ItemsModule } from './modals/items/items.module';
import { CustomItemModule } from './modals/custom-item/custom-item.module';
import { CustomerFormPageModule } from './pages/logged-in/customer/customer-form/customer-form.module';
import { ItemOptionsModule } from './components/item-options/item-options.module';
import { OrderItemOptionsModule } from './modals/item-options/item-options.module';
import {CookieMessageModule} from "./components/cookie-message/cookie-message.module";
import { CurencyPickerModule } from './components/curency-picker/curency-picker.module';
import { LoadingModalModule } from './components/loading-modal/loading-modal.module';
import { ItemVariantFormPageModule } from './pages/logged-in/item/item-variant-form/item-variant-form.module';
import { ItemVariantsModule } from './modals/item-variants/item-variants.module';
import { TicketFormPageModule } from './pages/logged-in/support/ticket-form/ticket-form.module';
import { CampaignFormPageModule } from './pages/logged-in/marketing/campaign/campaign-form/campaign-form.module';
import { ConfirmPlanPageModule } from './pages/logged-in/settings/confirm-plan/confirm-plan.module';
import { ConfirmAddonPageModule } from './pages/logged-in/addon/confirm-addon/confirm-addon.module';
import { AreaLocationPickerModule } from './components/area-location-picker/area-location-picker.module';
import { AuthModule } from '@auth0/auth0-angular';
import { BankFormPageModule } from './pages/logged-in/settings/bank-form/bank-form.module';
import { OpeningHoursFormPageModule } from './pages/logged-in/settings/opening-hours/opening-hours-form/opening-hours-form.module';
import { AddBusinessLocationPageModule } from './pages/logged-in/settings/business-location/add-business-location/add-business-location.module';
import { DeliveryZoneAreasPageModule } from './pages/logged-in/settings/delivery-zone/delivery-zone-areas/delivery-zone-areas.module';
import { DeliveryZoneFormPageModule } from './pages/logged-in/settings/delivery-zone/delivery-zone-form/delivery-zone-form.module';
import { CategoryFormPageModule } from './pages/logged-in/category/category-form/category-form.module';
import { ItemInventoryPageModule } from './pages/logged-in/item/item-inventory/item-inventory.module';
import { VoucherFormPageModule } from './pages/logged-in/discount/voucher-form/voucher-form.module';
import { CreateTapAccountPageModule } from './pages/logged-in/settings/create-tap-account/create-tap-account.module';
import { AddStaffPageModule } from './pages/logged-in/settings/staff-management/add-staff/add-staff.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { DeliveryZoneCitiesPageModule } from './pages/logged-in/settings/delivery-zone/delivery-zone-cities/delivery-zone-cities.module';
import { MoyasarPageModule } from './pages/logged-in/settings/payment-method/moyasar/moyasar.module';
import { StripePageModule } from './pages/logged-in/settings/payment-method/stripe/stripe.module';
import {AppGuidanceModule} from "src/app/components/app-guidance/app-guidance.module";
import { ItemVideoPageModule } from './pages/logged-in/item/item-video/item-video.module';
import { DeliveryZoneStatesPageModule } from './pages/logged-in/settings/delivery-zone/delivery-zone-states/delivery-zone-states.module';
import { AramexPageModule } from './pages/logged-in/settings/shipping-method/aramex/aramex.module';
import { ArmadaPageModule } from './pages/logged-in/settings/shipping-method/armada/armada.module';
import { FedexPageModule } from './pages/logged-in/settings/shipping-method/fedex/fedex.module';
import { MashkorPageModule } from './pages/logged-in/settings/shipping-method/mashkor/mashkor.module';
import { ShippingToPageModule } from './pages/logged-in/order/shipping-to/shipping-to.module';
import { SchedulePickupPageModule } from './pages/logged-in/order/aramex/schedule-pickup/schedule-pickup.module';
import { StorePageFormPageModule } from './pages/logged-in/store-page/store-page-form/store-page-form.module';
import { ItemActionsModule } from './components/item-actions/item-actions.module';
import { UPaymentPageModule } from './pages/logged-in/settings/payment-method/upayment/upayment.module';
import { ApplePayPageModule } from './pages/logged-in/settings/payment-method/apple-pay/apple-pay.module';
import { TabbyPageModule } from './pages/logged-in/settings/payment-method/tabby/tabby.module';

export function startupServiceFactory(authService: AuthService) {
  return () => authService.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}


declare global {
  interface Window { analytics: any; }
}



@NgModule({
  declarations: [AppComponent],
  imports: [
    AuthModule.forRoot({
      domain: 'bawes.us.auth0.com',
      clientId: 'zBLi5rqikntjIFqS4iJY7RQx6445yf5w'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    CKEditorModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    CalendarModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    ComponentsModule,
    ImageUploadModule,
    LocationPickerModule,
    BusinessLocationPickerModule,
    MapLocationPickerModule,
    HeaderProfilePopupPageModule,
    HeaderShopPopupPageModule,
    UpdateAlertModule,
    OrderAlertModule,
    OrderViewPageModule,
    OrderRefundPageModule,
    OrderInvoicePageModule,
    ItemCategoryPickerModule,
    CustomersModule,
    DeliveryZoneModule,
    DeliveryZoneTaxModule,
    ItemFormPageModule,
    ItemsModule,
    CustomItemModule,
    CustomerFormPageModule,
    ItemOptionsFormPageModule,
    ItemOptionsModule,
    OrderItemOptionsModule,
    CookieMessageModule,
    CurencyPickerModule,
    ItemVariantFormPageModule,
    ItemVariantsModule,
    TicketFormPageModule,
    BankFormPageModule,
  //  LoadingModalModule
    CampaignFormPageModule,
    ConfirmPlanPageModule,
    ConfirmAddonPageModule,
    AreaLocationPickerModule,
    OpeningHoursFormPageModule,
    AddBusinessLocationPageModule,
    DeliveryZoneModule,
    DeliveryZoneTaxModule,
    DeliveryZoneAreasPageModule,
    DeliveryZoneFormPageModule,
    ItemFormPageModule,
    CategoryFormPageModule,
    ItemInventoryPageModule,
    VoucherFormPageModule,
    CreateTapAccountPageModule,
    AddStaffPageModule,
    DeliveryZoneCitiesPageModule,
    DeliveryZoneStatesPageModule,
    NgxMaterialTimepickerModule,
    MoyasarPageModule,
    TabbyPageModule,
    StripePageModule,
    UPaymentPageModule,
    AppGuidanceModule,
    ItemVideoPageModule,
    AramexPageModule,
    ArmadaPageModule,
    FedexPageModule,
    MashkorPageModule,
    ApplePayPageModule,
    ShippingToPageModule,
    SchedulePickupPageModule,
    StorePageFormPageModule,
    ItemActionsModule
  ],
  providers: [
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [AuthService],
      multi: true
    },
    File,
    FileChooser,
    FilePath,
   // Camera,
    SelectiveLoadingStrategy,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    OneSignal,
    SwUpdate,
    DatePipe,
    DecimalPipe, 
   { provide: ErrorHandler, useClass: SentryErrorhandlerService }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  static injector: Injector;

  constructor(public injector: Injector) {
    AppModule.injector = injector;
    registerLocaleData(localeAr, 'ar');
  }
}
