import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
//models
import { Item } from 'src/app/model/Item';
import { ItemsService } from 'src/app/services/logged-in/items.service';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-item-variants',
  templateUrl: './item-variants.component.html',
  styleUrls: ['./item-variants.component.scss'],
})
export class ItemVariantsComponent implements OnInit {

  public item: Item;

  public item_variant_uuid;

  public borderLimit = false;

  public loading = false; 

  constructor(
    public modalCtrl: ModalController,
    public itemService: ItemsService,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true; 

    this.itemService.view(this.item.item_uuid).subscribe(response => {
      this.item = response;

      this.loading = false; 
    });
  }

  onBackButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dismiss();
  }

  /**
   * dismiss popup
   * @param data 
   */
  dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        o.dismiss(data);
      }
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
  
  onVariantSelection(itemVariant) {

    this.item_variant_uuid = itemVariant.item_variant_uuid;

    let extraOptionSelection = [];

    itemVariant.itemVariantOptions.forEach(itemVariantOption => {
      extraOptionSelection.push(itemVariantOption.extraOption);
    }); 
    
    this.dismiss({
      item: this.item,
      orderItemExtraOptions: extraOptionSelection,
      item_variant_uuid: this.item_variant_uuid
    });
  }
}

