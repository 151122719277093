import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import mixpanel from 'mixpanel-browser';
//models
import { PaymentMethod } from 'src/app/model/payment_method';
import { Plan } from 'src/app/model/plan';
import { AuthService } from 'src/app/services/auth.service';
//services
import { PlanService } from 'src/app/services/logged-in/plan.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { MoyasarService } from 'src/app/services/logged-in/payment/moyasar.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { environment } from 'src/environments/environment';
import { ApplePayService } from 'src/app/services/logged-in/apple-pay.service';

declare var Moyasar;
declare var window;
declare var ApplePaySession;

const { render, ThemeMode, SupportedNetworks, Scope, Environment, Locale, ButtonType, Edges } =
  window.TapApplepaySDK

@Component({
  selector: 'app-confirm-plan',
  templateUrl: './confirm-plan.page.html',
  styleUrls: ['./confirm-plan.page.scss'],
})
export class ConfirmPlanPage implements OnInit {

  public plan_id;

  public plan: Plan;

  public paymentMethods: PaymentMethod[] = [];

  public loading: boolean = false;

  public submitting: boolean = false;

  public form: FormGroup;

  public moyasarForm;

  public borderLimit: boolean = false;

  public isSafari: boolean = false; 
  
  constructor(
    public platform: Platform,
    public formBuilder: FormBuilder,
    public applePayService: ApplePayService,
    public translateService: TranslateLabelService,
    public alertCtrl: AlertController,
    public popOverCtrl: ModalController,// PopoverController,
    public router: Router,
    public route: ActivatedRoute,
    public authService: AuthService,
    public planService: PlanService,
    public moyasarService: MoyasarService,
    public analytics: AnalyticsService
  ) { }

  ngOnInit() {

    this.analytics.page('Upgrade to Premium page');

    if (!this.plan_id)
      this.plan_id = this.route.snapshot.paramMap.get('id');

    this.loadData();

    //this.loadMoyasar();
  }

  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Upgrade to Premium page'
    });
  }

  beginApplePay() {

    const paymentRequest = {
        countryCode: 'US',
        currencyCode: 'USD',
        supportedNetworks: ['visa', 'masterCard', 'mada'],//'amex', 'discover'
        merchantCapabilities: ['supports3DS', 'supportsEMV', 'supportsCredit', 'supportsDebit'],
        total: {
            label: 'Total',
            amount: '10.00'
        }
    };

    const session = new ApplePaySession(3, paymentRequest);

    // Merchant validation
    session.onvalidatemerchant = (event) => { 
      this.applePayService.validateApplePayMerchant(event).subscribe(data => {
          session.completeMerchantValidation(data);
      }, error => {
          console.error('Error during merchant validation', error);
          session.abort();
      });
    };

    // Payment authorized
    session.onpaymentauthorized = (event) => {
      this.applePayService.processAppleToken(event).subscribe(data => {
          if (data.success) {
              session.completePayment(ApplePaySession.STATUS_SUCCESS);
          } else {
              session.completePayment(ApplePaySession.STATUS_FAILURE);
          }
        }, error => {
          console.error('Error processing payment', error);
          session.completePayment(ApplePaySession.STATUS_FAILURE);
        });
    };

    // Begin the Apple Pay session
    session.begin();
  }

  loadApplePay() {

    /*
    public_key
    merchant_id
    currency
    amount

    res.agent_number
    res.agent_phone_country_code
    res.agent_email
    */
    
    this.planService.getApplePayParams(this.plan_id, this.authService.currency_pref).subscribe(res => {

      if (!res.merchant_id) {
        return false;
      }
  
      const names = res.agent_name.split(" ");

      const firstName = names[0];
      const lastName = names.length > 2 ? names[2]: (names.length > 1? names[1]: "");
      const middleName = names.length > 2 ? names[1]: "";

      //below code need tap customer care to add our domain in apple pay 

      render(
        {
          publicKey: res.public_key,
          environment: environment.production? Environment.Production : Environment.Development,
          scope: Scope.TapToken,
          merchant: {
            domain: window.location.hostname,
            id: res.merchant_id
          },
          transaction: {
            currency: res.currency,
            amount: res.amount
          },
          acceptance: {
            supportedBrands: [SupportedNetworks.Mada, SupportedNetworks.Visa, SupportedNetworks.MasterCard],
            supportedCards: ['DEBIT', 'CREDIT'],
            supportedCardsWithAuthentications: ['3DS', 'EMV']
          },
          customer: {
            //id: 'cus_xxx',
            name: [
              {
                locale: this.authService.language.code,
                first: firstName,
                last: lastName,
                middle: middleName
              }
            ],
            contact: {
              email: res.agent_email,
              phone: {
                number: res.agent_number,
                countryCode: res.agent_phone_country_code
              }
            }
          },
          interface: {
            locale: this.translateService.currentLang == "ar"? Locale.AR: Locale.EN,
            theme: ThemeMode.DARK,
            type: ButtonType.BUY,
            edges: Edges.CURVED
          },
          onCancel: async () => {
            console.log('onCancel')
          },
          onError: async (error) => {
            console.log('onError', error)
          },
          onSuccess: async (data) => {
            console.log('onSuccess', data)

            //set source in form 
            this.form.controls.source.setValue(data);
            this.form.controls.source.updateValueAndValidity();

            const payment_method = this.paymentMethods.find(pm => pm.payment_method_code == "apple-pay");
            
            this.form.controls.payment_method_id.setValue(payment_method.payment_method_id);
            this.form.controls.payment_method_id.updateValueAndValidity();

            this.makePayment();
          },
          onReady: async () => {
            console.log('onReady')
          }
        },
        'apple-pay-button'
      )
    });
  }

  loadMoyasar() {

    const params = {
      'plan_id': this.plan_id
    };

    this.moyasarService.getInitParams(params).subscribe(res => {

      if (!res.payment_moyasar_api_key) {
        this.moyasarForm = null;
        return false;
      }

      this.moyasarForm = true;

      Moyasar.init({
        element: '.mysr-form',
        language: res.language_code,
        amount: res.amount_in_halals,
        currency: res.currency,
        country: res.country,
        description: res.description,
        publishable_api_key: res.payment_moyasar_api_key,
        methods: res.payment_moyasar_payment_methods_json,
        //supported_networks: {{payment_moyasar_network_support_json|raw}},
        callback_url: res.callback_url,
        apple_pay: {
          label: res.domain_name,
          validate_merchant_url: res.validate_merchant_url,
          version: 9,
          country: res.country,
        },
        //Add more information
        metadata: res.metadata_json,
        translations: {
          ar: {
            "button.pay": "دفع"
          }
        },
      });
    });
  }

  /**
   * load plan detail
   */
  loadData() {
    this.loading = true;

    this.planService.view(this.plan_id).subscribe(data => {

      this.plan = data;

      this.paymentMethods = data.paymentMethods;

      this.loading = false;

      this.initForm();

      //if having apple pay enabled 

      if(navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')){
        this.isSafari = true;
      }
  
      /*const payment_method = this.paymentMethods.find(pm => pm.payment_method_code == "apple-pay");

      // Check if Apple Pay is available on the user's device //&& this.isSafari
      if (payment_method && window['ApplePaySession'] && window['ApplePaySession'].canMakePayments()) {
        this.loadApplePay();
      } */
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      plan_id: [this.plan_id, Validators.compose([Validators.required])],
      payment_method_id: ['', Validators.compose([Validators.required])],
      source: ['']
    });
  }

  /**
   * make payment
   */
  makePayment() {

    this.submitting = true;

    if (!this.form.controls.payment_method_id.value) {
      return false;
    }

    this.planService.confirm(this.form.value).subscribe(async response => {

      //check callback working 

      //api in postman 

      //todo: on app load show message/redirect accordingly 

      if (response.redirect) {
        if (this.platform.is('hybrid')) {

          Browser.open({ url: response.redirect }).then((e) => {
          });

          Browser.addListener('browserPageLoaded', () => {

            if (window.location.href.includes('plugn')) {
              /*this.alertCtrl.create({
                header:this.translateService.transform('Success'), 
                message: this.translateService.transform('Plan updated successfully!'),
                buttons: [this.translateService.transform('Okay')]
              }).then(prompt => prompt.present());*/

              Browser.removeAllListeners();

              return this.dismiss({ refresh: true });
            }
          });

          Browser.addListener('browserFinished', () => {

            if (window.location.href.includes('plugn')) {
              /*this.alertCtrl.create({
                header:this.translateService.transform('Success'), 
                message: this.translateService.transform('Plan updated successfully!'),
                buttons: [this.translateService.transform('Okay')]
              }).then(prompt => prompt.present());*/

              Browser.removeAllListeners();

              return this.dismiss({ refresh: true });
            }
          });

        } else {
          window.open(response.redirect, '_self');
        }

        this.dismiss();
      }
      else if (response.operation == 'success') {
        this.submitting = false;

        //todo: refresh activeSubscription 

        this.dismiss();

        //this.router.navigate(['settings/payment-methods']);

        /*this.alertCtrl.create({
          message: response.message,
          buttons: [this.translateService.transform('Okay')]
        }).then(prompt => prompt.present());*/
      }
      else {
        this.submitting = false;

        this.authService.showAlert(
          this.translateService.transform('Error upgrading store'),
          this.translateService.errorMessage(response.message),
          this.form.value
        );  
      }
    }, err => {
      this.submitting = false;
    });
  }

  /**
   * price to upgrade
   * @returns 
   */
  getTotal() {

    if (this.authService.store && this.authService.store.custom_subscription_price > 0) {
      return this.authService.store.custom_subscription_price;
    }

    return this.plan?.formatedPrice
  }

  onMethodSelect(method) {
    this.form.controls.payment_method_id.setValue(method.payment_method_id);
    this.form.controls.payment_method_id.updateValueAndValidity();
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  /**
   * close popup
   */
  dismiss(data = {}) {
    this.popOverCtrl.dismiss(data);
  }
}
