import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from "@ionic/angular";
// services
import { EventService } from 'src/app/services/event.service';
import { CityService } from 'src/app/services/logged-in/city.service';
import { DeliveryZoneService } from 'src/app/services/logged-in/delivery-zone.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { DeliveryAreaService } from "../../../../../services/logged-in/delivery-area.service";
//components
import { DeliveryZoneTaxComponent } from 'src/app/components/delivery-zone-tax/delivery-zone-tax.component';
//pages
import { DeliveryZoneFormPage } from "../delivery-zone-form/delivery-zone-form.page";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-delivery-zone-cities',
  templateUrl: './delivery-zone-cities.page.html',
  styleUrls: ['./delivery-zone-cities.page.scss'],
})
export class DeliveryZoneCitiesPage implements OnInit {

  public delivery_zone_id: any;

  public deliveryZone;
  public store_uuid;
  public zone;
  public selectedCity = null;
  public business_location_id;
  public deliveryZones = {};

  public cities = [];
  public saving = null;

  public loading: boolean = false;

  public pageCount = 0;
  public currentPage = 1;
  public totalCount = 0;

  constructor(
    public route: ActivatedRoute,
    public cityService: CityService,
    public translateService: TranslateLabelService,
    public deliveryZoneService: DeliveryZoneService,
    public deliveryAreaService: DeliveryAreaService,
    public eventService: EventService,
    public authService: AuthService,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {

    // this.delivery_zone_id = this.route.snapshot.paramMap.get('delivery_zone_id');
    // this.business_location_id = this.route.snapshot.paramMap.get('business_location_id');

    const state = window.history.state;

    if (state && state.deliveryZone) {
      this.deliveryZone = state.deliveryZone;
    }

    this.loadData();

    // this.eventService.deliveryAreasUpdated$.subscribe((data: any) => {
    //   if (data.delivery_zone_id == this.delivery_zone_id) {
    //     this.loadCities();
    //   }
    // });

    this.eventService.deliveryZoneDeleted$.subscribe((data: any) => {
      if(data.delivery_zone_id == this.deliveryZone.delivery_zone_id) {
         this.dismiss(); 
      }
    }); 
  }

  loadData() {
    this.loading = true;

    this.deliveryZoneService.view(this.delivery_zone_id).subscribe(response => {
      
      this.loading = false;

      this.deliveryZone = response;

      this.loadCities();
    });
  }

  listAreaDeliveryZone() {

    const query = '&delivery_zone_id=' + this.delivery_zone_id;

    this.deliveryAreaService.list(null, query).subscribe(response => {

      this.deliveryZones = {};

      for (const deliveryZone of response.body) {
        this.deliveryZones[deliveryZone.area_id] = true;
      }
    });
  }

  loadCities() {

    this.loading = true;

    const query = '&expand=totalDeliveryAreas,areas&delivery_zone_id=' + this.delivery_zone_id;

    this.cityService.list(1, query).subscribe(response => {
      
      this.loading = false;

      this.cities = response.body;
      if (this.cities.length > 0) {
        this.selectedCity = this.cities[0].city_id;
      }
      this.listAreaDeliveryZone();

      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));
      this.pageCount = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
    });
  }

  /**
   * load more on scroll to bottom
   * @param event
   */
  doInfinite(event) {

    if(this.currentPage >= this.pageCount) {
      if(event && event.target)
        event.target.complete();
      return null;
    }

    this.loading = true;

    this.currentPage++;

    const query = '&expand=totalDeliveryAreas&delivery_zone_id=' + this.delivery_zone_id;

    this.cityService.list(this.currentPage, query).subscribe(response => {

      this.loading = false;
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));
      this.pageCount = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));

      this.cities = this.cities.concat(response.body);
    },
      error => { },
      () => { 
        if(event && event.target)
          event.target.complete(); 
      }
    );
  }

  /**
   * close popup if any
   * @param event
   */
  close(event) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        event.preventDefault();
        event.stopPropagation();
        o.dismiss();
      }
    })
  }

  save(event) {
    const selectedAreas = {};

    if (this.cities) {
      this.cities.map(city => {
        if (city && city.areas) {
          city.areas.map(area => {
            for (let key in this.deliveryZones) {
              if (key && key == area.area_id && this.deliveryZones[key]) {
                selectedAreas[area.area_id] = {
                  city_id: area.city_id,
                  area_id: area.area_id,
                  delivery_zone_id: this.delivery_zone_id
                };
                continue;
              }
            }
          });
        }
      });
    }
    this.deliveryAreaService.save(this.store_uuid, this.delivery_zone_id, selectedAreas).subscribe(async response => {

      this.saving = false;

      if(response.operation == 'success') {
        this.eventService.deliveryAreasUpdated$.next({
          delivery_zone_id : this.delivery_zone_id
        });
        this.close(event);
      } else {
        this.authService.showAlert(
          this.translateService.transform('Error saving delivery areas'),
          this.translateService.errorMessage(response.message),
          selectedAreas
        );  
      }
    });
  }

  /**
   * remove VAT value
   * @param zone
   */
  cancelVat(zone) {
    // this.deletingZone = zone.delivery_zone_id;
    this.deliveryZoneService.cancelOverride(zone).subscribe(async response => {
      if (response.operation == 'success') {
        this.loadData();
      } else {
        this.authService.showAlert(
          this.translateService.transform('Error canceling VAT'),
          this.translateService.errorMessage(response.message),
          zone
        );  
      }
    });
  }

  /**
   * override tax for city
   * @param zone
   */
  async updateVat(zone) {
    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: DeliveryZoneTaxComponent,
      cssClass: 'modal-delivery-zone-tax',
      componentProps: {
        tax: zone.delivery_zone_tax
      }
    });
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.tax <= 0) {
      return false;
    }

    const oldZone = Object.assign({}, zone);

    zone.delivery_zone_tax = data.tax;

    this.deliveryZoneService.update(zone).subscribe(async (response: any) => {
      if (response.operation != 'success') {
        zone = oldZone;

        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Error'),
          message: this.translateService.errorMessage(response.message),
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();
      }
    });
  }

  dismiss(data = null) {
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }

  citySelection(city) {
    this.selectedCity = city.city_id;
  }

  /**
   * open popup to update/delete zone
   * @param zone
   */
  async editZone(zone) {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: DeliveryZoneFormPage,
      cssClass: 'popup-modal',
      componentProps: {
        business_location_id: this.business_location_id,
        zone_id: zone.delivery_zone_id
      }
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if (data && data.refresh) {
      zone = data.zone;
    }

    if (data && data.delete) {
      this.loadData();
    }
  }

  selectAll(event, city) {
    if (city && city.areas && city.areas.length > 0) {
      city.areas.map(area => {
        this.deliveryZones[area.area_id] = event.detail.checked;
      });
    }
  }
}
