import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import mixpanel from 'mixpanel-browser';
import { AnalyticsService } from 'src/app/services/analytics.service';
//services
import { PaymentMethodService } from 'src/app/services/logged-in/payment-method.service';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-moyasar',
  templateUrl: './moyasar.page.html',
  styleUrls: ['./moyasar.page.scss'],
})
export class MoyasarPage implements OnInit {

  public borderLimit = false;

  public form: FormGroup;

  public loading: boolean = false;
  
  public saving: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public translateService: TranslateLabelService,
    public storeServices: StoreService,
    public analytics: AnalyticsService,
    public paymentServices: PaymentMethodService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.analytics.page('Moyasar config page');

    this.loadData();
  }
  
  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Moyasar config page'
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  loadData() {
    this.storeServices.getSettings('Moyasar').subscribe(data => {
      this.initForm(data);
    });
  }

  initForm(data) {
    this.form = this.formBuilder.group({
      payment_moyasar_api_secret_key: [data.payment_moyasar_api_secret_key, Validators.required],
      payment_moyasar_api_key: [data.payment_moyasar_api_key, Validators.required],
      payment_moyasar_payment_type: [data.payment_moyasar_payment_type],
      payment_moyasar_network_type: [data.payment_moyasar_network_type],
      payment_moyasar_apple_domain_association: [data.payment_moyasar_apple_domain_association]
    });
  }

  /**
   * save detail
   */
   save() {

    if(!this.form || !this.form.valid) {
      return false;
    }

    this.saving = true;

    this.paymentServices.setConfig("Moyasar", this.form.value).subscribe(async response => {

      this.saving = false;

      if(response.operation == 'success') {

        this.dismiss(this.form.value);

        /*this.router.navigate(['/']);
       
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Success'),
          message: response.message,
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();*/

      } else {
        this.authService.showAlert(
          this.translateService.transform('Error saving moyasar'),
          this.translateService.errorMessage(response.message),
          this.form.value
        );  
      }
    });
  }

  dismiss(data = {}) {
    this.modalCtrl.dismiss(data);
  }
}
