import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderProfilePopupPageRoutingModule } from './header-profile-popup-routing.module';
import { HeaderProfilePopupPage } from './header-profile-popup.page';
import { RouterModule } from '@angular/router';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        IonicModule,
        HeaderProfilePopupPageRoutingModule,
        TranslateModule
    ],
  declarations: [HeaderProfilePopupPage]
})
export class HeaderProfilePopupPageModule {}
