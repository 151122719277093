import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ItemsComponent } from './items.component';
import { ItemModule } from 'src/app/components/item/item.module';
import {ItemListPageModule} from "../../pages/logged-in/item/item-list/item-list.module";
import { ItemOptionsModule } from 'src/app/components/item-options/item-options.module';



@NgModule({
  declarations: [
    ItemsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    //ItemModule,
    TranslateModule.forChild(),
    ItemListPageModule,
   // ItemOptionsModule
  ],
})
export class ItemsModule { }
