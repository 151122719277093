import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ColorPickerModule } from 'ngx-color-picker';
// Layout components
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
/// cards components
import { ChartCardComponent } from './cards/chart-card/chart-card.component';
import { DashboardCardComponent } from './cards/dashboard-card/dashboard-card.component';
import { ReportChartComponent } from './cards/report-chart/report-chart.component';

/// Settings components layout

import { TranslateModule } from '@ngx-translate/core';
import { HeaderShopPopupPageModule } from '../modals/header-shop-popup/header-shop-popup.module';
import { CategoryActionModule } from './category-action/category-action.module';
/// forms components


export const components: any = [
  // layout components
  SidebarComponent, HeaderComponent,
  /// cards components
  ChartCardComponent, DashboardCardComponent, ReportChartComponent
]

@NgModule({
    declarations: [
      components,
    ],
  exports: components,
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    NgApexchartsModule,
    ColorPickerModule,
    CategoryActionModule,
    //NgxDaterangepickerMd.forRoot(),
    CommonModule, 
    IonicModule, 
    RouterModule,
    FormsModule,
    HeaderShopPopupPageModule,
		ReactiveFormsModule,
    TranslateModule.forChild()
  ]
})
export class ComponentsModule { }
