import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-order-option',
  templateUrl: './order-option.component.html',
  styleUrls: ['./order-option.component.scss'],
})
export class OrderOptionComponent implements OnInit {

  public order;

  constructor(
    public popupCtrl: PopoverController,
    public authService: AuthService
  ) { }

  ngOnInit() {}

  invoice() {
    this.dismiss({
      action: 'view-invoice'
    });
  }

  refund() {
    this.dismiss({
      action: 'refund'
    });
  }


  delete() {
    this.dismiss({
      action: 'delete'
    });
  }
  
  /**
   * close popup
   */
  dismiss(data = {}) {
    this.popupCtrl.getTop().then(overlay => {
      if(overlay) {
        overlay.dismiss(data);
      }
    });
  }
}
