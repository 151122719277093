import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
//services
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-header-profile-popup',
  templateUrl: './header-profile-popup.page.html',
  styleUrls: ['./header-profile-popup.page.scss'],
})
export class HeaderProfilePopupPage implements OnInit {

  constructor(
    private _router: Router,
    public authService: AuthService,
    public popoverController: PopoverController,
  ) { }

  ngOnInit() {
  }

  navigate(route: string) {
    this.popoverController.dismiss();
    this._router.navigate([route]);
  }

  logout() {
    this.popoverController.dismiss();
    this.authService.logout();
  }
}
