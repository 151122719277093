import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryZoneTaxComponent } from './delivery-zone-tax.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    DeliveryZoneTaxComponent
  ],
  imports: [
    CommonModule, 
    FormsModule,
    IonicModule, 
    TranslateModule.forChild()
  ],
  exports: [
    DeliveryZoneTaxComponent
  ]
})
export class DeliveryZoneTaxModule { }
