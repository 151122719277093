import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
//services
import { CurrencyService } from 'src/app/services/logged-in/currency.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-curency-picker',
  templateUrl: './curency-picker.component.html',
  styleUrls: ['./curency-picker.component.scss'],
})
export class CurencyPickerComponent implements OnInit {

  public currencies;
  
  public perPageCount = 20;
  public currentPage = 0;
  public totalPages = 0;
  public totalCount = 0;
  
  public search = null;

  public loading = false; 

  public borderLimit = false;

  public onlyStoreCurrencies: false; 

  constructor(
    public modalCtrl: ModalController,
    public currencyService: CurrencyService,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {
    this.loadData(1);
  }

  selected(data = {}) {
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }

  dismiss() {
    this.selected({});
  }

  loadData(page) {
    this.loading = true;

    let action ;

    if(this.onlyStoreCurrencies) {
      action = this.currencyService.listStoreCurrencies(page, this.search);
    } else {
      action = this.currencyService.list(page, this.search);
    }

    action.subscribe(res => {

      this.perPageCount = parseInt(res.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(res.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);

      this.currencies = res.body;
    },
      err => {
        this.loading = false;
      },
      () => this.loading = false,
    );
  }

  loadMoreCurrencies(event) {

    if(this.currentPage >= this.totalPages) {
      if(event && event.target)
        event.target.complete();
      return null;
    }
    
    this.loading = true;
    
    this.currentPage++;

    let action ;

    if(this.onlyStoreCurrencies) {
      action = this.currencyService.listStoreCurrencies(this.currentPage, this.search);
    } else {
      action = this.currencyService.list(this.currentPage, this.search);
    }

    action.subscribe(res => {
      
      this.perPageCount = parseInt(res.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(res.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);
      this.currencies.push(...res.body);

      if(event && event.target)
        event.target.complete();
    },
    err => this.loading = false,
    () => this.loading = false);
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
