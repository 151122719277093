import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Option } from '../model/item_option';


export class CustomValidator {

    /**
     * validate order item option for checkbox min max qty 
     * @param option 
     * @returns 
     */
    static orderItemCheckboxValidator(option: Option): ValidatorFn
    {
        return (control: AbstractControl): {[key: string]: any} => {
            const value = control.value;

            if(option.min_qty > 0 && value.length < option.min_qty) {
                return { 'checkbox': 'min qty is invalid.' }
            }
            
            if(option.max_qty > 0 && value.length > option.max_qty) {
                return { 'checkbox': 'max qty is invalid.' }
            }
        };
    }

    /**
     * Validates Email Input
     * @param  {AbstractControl} control
     * @returns any
     */
    static emailValidator(control: AbstractControl): {[key: string]: any}
    {
        const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        return !control.value || emailReg.test(control.value) ? null : {'emailValidation': 'email is invalid.' };
    }

    /**
     * Factory Method
     * Takes a forbidden name and returns a validator function to be used
     * @param  {string} nameRe
     * @returns ValidatorFn
     */
    static forbiddenNameValidator(nameRe: string): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} => {
            const name = control.value;
            return name == nameRe ? {'forbiddenName': {name}} : null;
        };
    }
}
