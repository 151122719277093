import { Component, Inject, Input, OnInit } from '@angular/core';
import { NavController, Platform, PopoverController } from '@ionic/angular';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
// services
import { EventService } from 'src/app/services/event.service';
import { AuthService } from '../../../services/auth.service';
import { TranslateLabelService } from "../../../services/translate-label.service";
import { StoreService } from "../../../services/logged-in/store.service";
import { RoleGuard } from 'src/app/services/role-guard.service';
//page
import { HeaderShopPopupPage } from 'src/app/modals/header-shop-popup/header-shop-popup.page';
import { Storage } from '@capacitor/storage';
import { AnalyticsService } from 'src/app/services/analytics.service';

declare var google;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  storeAssignments = [];

  @Input() public showStores = false;

  public currentLang;
  public search;

  toogle: any = {
    orders: false,
    items: false,
    discounts: false,
    analytics: false,
    settings: false,
    integrations: false
  };

  constructor(
    public auth: Auth0Service,
    @Inject(DOCUMENT) public document: Document,
    public platform: Platform,
    public authService: AuthService,
    public popoverCtrl: PopoverController,
    public roleGuard: RoleGuard,
    public eventService: EventService,
    public navCtrl: NavController,
    public analytics: AnalyticsService,
    public translateService: TranslateLabelService,
    public storeService: StoreService
  ) {
    this.loadStoreDetail();
  }

  async ngOnInit() {
    if (this.authService.isLogged && this.authService.stores.length == 0) {
      this.eventService.refreshStoreList$.next({});
    }

    if (this.translateService.currentLang)
      this.currentLang = this.translateService.currentLang;

    /*this.platform.ready().then(() => {
      new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
    });*/  
  }

  /**
   * chagne app language
   */
  async changeLanguage() {
    this.eventService.setLanguagePref$.next((this.authService.language.code == 'en') ? 'ar' : 'en');
    this.navCtrl.navigateRoot(['/']);
  }

  tooglemenu(id: string) {
    this.toogle[id] = !(this.toogle[id]);
  }

  onLogoError(restaurant) {
    restaurant.logo = null;
  }

  showStoresList(event) {
    event.preventDefault();
    event.stopPropagation();

    this.showStores = !this.showStores;
  }

  storesList(event) {
    this.shopPopup(event);
    // this.showStores = !this.showStores;
  }

  async shopPopup(ev) {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const popover = await this.popoverCtrl.create({
      component: HeaderShopPopupPage,
      animated: true,
      // cssClass: 'profile-popover',
      // mode: 'md',
      event: ev,
      translucent: true
    });

    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });
    return await popover.present();
  }


  /**
   * load store detail
   */
  async loadStoreDetail() {

    if (!this.authService.store_id) {
      return this.resetStoreDetail(null, null);
    }

    const expand = "totalItems,totalOrders,isCashEnabled,isKnetEnabled,isCreditCardEnabled,isMadaEnabled,isBenefitEnabled,isMashkorEnabled,isArmadaEnabled";

    this.storeService.detail(expand).subscribe(response => {
      this.resetStoreDetail(response, response.role);
    });
  }

  /**
   * rest store detail
   */
  async resetStoreDetail(store, role) {

    this.authService.setStore(store, role);

    /*clearInterval(this.alertSubscription);

    this.alertSubscription = null;

    if (employer) {
      this.alertSubscribe();
    }*/
  }

  logout() {
    this.authService.logout();

    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      if(isAuthenticated) {
        this.auth.logout({ returnTo: document.location.origin });
      }
    });
  }

  changeNotification(event) {
 
 
    //this.authService.setToSilenceMode

    if (event.detail.checked) {
      
      this.authService.setToSilenceMode = true; 

      this.authService.audioPlayer.pause();
      this.authService.stopStartBell();

      this.analytics.track("Order Sound Updated", {
        order_sound_activated: false
      });

      //Storage.set({ 'key': 'setToSilenceMode', value: "true" });
 
    } else {

      this.authService.setToSilenceMode = false; 
      
      this.authService.setStartBell();
      this.authService.audioPlayer.muted = false;

      this.authService.ringTheBell();

      setTimeout(() => {
        this.authService.audioPlayer.pause();
      }, 1000);

      //this.authService.setStartBell();
 
      //Storage.set({ 'key': 'setToSilenceMode', value: "false" });

      this.analytics.track("Order Sound Updated", {
        order_sound_activated: true
      });

    }
  }
}
