import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class DiscountsService {

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  listAll(): Observable<any> {
    return this.http.getRaw(`voucher`);
  }

  /**
   * return voucher for current store
   * @param page
   * @param keyword
   * @param pageSize
   * @returns
   */
  list(page: number = 1, keyword = null, pageSize = 20, status = null): Observable<any> {
    let url = `voucher?page=${page}&keyword=${keyword}&per-page=${pageSize}`;

    if(status != null) {
      url += '&status=' + status;
    }

    return this.http.getRaw(url);
  }

  /**
   * keyword search
   * @param page
   * @param keyword
   */
  filterList(page: number = 1, keyword = null): Observable<any> {
    return this.http.post(
      `voucher?page=${page}`, {
        keyword
      }, true);
  }

  /**
   * voucher detail page
   * @param discountID
   */
  view(discountID: number, params = ''): Observable<any> {
    return this.http.get(`voucher/detail?voucher_id=${discountID}${params}`);
  }

  /**
   * create voucher
   * @param voucher
   */
  create(voucher: any): Observable<any> {

    const param = {
      ...voucher, 
      store_uuid : this.authService.store_id
    };
    return this.http.post(`voucher/create`, param);
  }

  /**
   * update request
   * @param voucher
   * @param voucherID
   */
  update(voucher: any, voucherID): Observable<any> {

    const param = {
      ...voucher
    };
    return this.http.patch(`voucher/${voucherID}/${this.authService.store_id}`, param);
  }

 /**
   * change status
   * @param voucher
   */
  changeStatus(voucher: any): Observable<any> {
    const param = {
      store_uuid: this.authService.store_id,
      voucher_id: voucher.voucher_id,
      voucherStatus: (voucher.voucher_status == 1) ? 2 : 1
    };
    return this.http.patch(`voucher/update-status`, param);
  }

  /**
   * delete voucher
   * @param voucherID
   */
  delete(voucherID): Observable<any> {
    return this.http.delete(`voucher/${voucherID}/${this.authService.store_id}`);
  }
}
