import { Attachment } from "./attachment";
import { TicketAttachment } from "./ticket_attachment";
import { TicketComment } from "./ticket_comment";

export class Ticket {
    ticket_uuid: string;
    restaurant_uuid: string;
    agent_id: number;
    staff_id: number;
    ticket_detail: string;
    ticket_status: number;
    created_at: string;
    updated_at: string;
    ticketAttachments: TicketAttachment[];
    attachments: Attachment[];
    ticketComments: TicketComment[];
    staff: any;
    agent: any;
}
