import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AramexPageRoutingModule } from './aramex-routing.module';

import { AramexPage } from './aramex.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    AramexPageRoutingModule
  ],
  declarations: [AramexPage]
})
export class AramexPageModule {}
