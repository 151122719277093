import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LocationPickerComponent } from './location-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    LocationPickerComponent
  ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
        FormsModule
    ],
  exports: [
    LocationPickerComponent
  ]
})
export class LocationPickerModule { }
