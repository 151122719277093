import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
//models
import { Item } from 'src/app/model/Item';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { CustomValidator } from 'src/app/validators/custom.validator';


@Component({
  selector: 'app-item-options',
  templateUrl: './item-options.component.html',
  styleUrls: ['./item-options.component.scss'],
})
export class ItemOptionsComponent implements OnInit {

  form: FormGroup;

  public item: Item;

  public variants = [];

  public borderLimit = false;

  constructor(
    public formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    let formArray = [];

    for (let option of this.item.options) {

      let key = 'option_' + option.option_id;

      let value = option.option_type == 1 ? []: null; 

      let validations = option.option_type == 1 ? [CustomValidator.orderItemCheckboxValidator(option)]: [];

      if(option.is_required) {
        validations.push(Validators.required);
      }

      Object.assign(formArray, {
        [key] : [{value: value, disabled: false}, validations]
      });
    }

    this.form = this.formBuilder.group(formArray);

  }
 
  toggle(option, extraOption) {
    
    let currentValues = this.form.controls['option_' + option.option_id].value;

    if(currentValues.indexOf(extraOption.extra_option_id) > -1) {
      currentValues = currentValues.filter((row) => {
        return row != extraOption.extra_option_id;
      });

      this.form.controls['option_' + option.option_id].setValue(currentValues);
    }
    else
    {
      currentValues.push(extraOption.extra_option_id);
      this.form.controls['option_' + option.option_id].setValue(currentValues);
    }
    
    this.form.controls['option_' + option.option_id].markAsDirty();
    this.form.controls['option_' + option.option_id].updateValueAndValidity();
  }

  isOptionValueSelected(option, extraOption) {

    const currentValues = this.form.controls['option_' + option.option_id].value;

    return currentValues.indexOf(extraOption.extra_option_id) > -1;
  }

  /**
   * dismiss with select values 
   */
  save() {
   
    let extraOptions = [];

    let index = 0;

    for(let i in this.form.value) {

      // this.form.value[i] can be array if checkbox 

      //checkbox
      if(this.item.options[index].option_type == 1) 
      {      
        
        for(let value of this.form.value[i])
        {
          const extraOption = this.getExtraOption(index, value);

          extraOptions.push({
            extra_option_id: value,
            option_id: this.item.options[index].option_id,
            ...extraOption
          });
        }
      } 
      //radio
      else if(this.item.options[index].option_type == 2) 
      {
        const extraOption = this.getExtraOption(index, this.form.value[i]);
        
        extraOptions.push({
          extra_option_name: this.form.value[i],
          extra_option_name_ar: this.form.value[i],
          option_id: this.item.options[index].option_id,
          ...extraOption
        });
      } 
      //text
      else    
      {
        //const extraOption = this.getExtraOption(index, this.form.value[i]);
        const option = this.item.options[index];
        extraOptions.push({
          extra_option_name: this.form.value[i],
          extra_option_name_ar: this.form.value[i],
          option_id: this.item.options[index].option_id,
          //...extraOption
          ...option
        });
      }

      index++;
    }

    //this.item.extraOptions = extraOptions;

    this.dismiss({
      item: this.item,
      orderItemExtraOptions: extraOptions
    })
  }

  /**
   * return extra option detail
   * @param i 
   * @param extra_option_id 
   * @returns 
   */
  getExtraOption(i, extra_option_id) {
    
    for(let extraOption of this.item.options[i].extraOptions) {
      if(extraOption.extra_option_id == extra_option_id) {
        return extraOption;
      }
    }
  }

  /**
   * see if typescript can be checked in browser 
   */
  generateVariants() {

    this.item.options[0].extraOptions.forEach((option, index) => {

      let s = {
        qty: option.stock_qty,
        extraOptions: [option]
      };

      this.generateVariant(1, s); 

    });
  }

  generateVariant(i = 0, variant = null) {

    if(i == this.item.options.length && variant) {
      this.variants.push(variant);
      return;
    }

    for (let extraOption of this.item.options[i].extraOptions) {

      let v = Object.assign({}, variant);

      if(v.extraOptions.length == this.item.options.length) {
        v.extraOptions = [];
      }

      /*if(!v) {

        let v;

        //v.qty = extraOption.stock_qty;
        v.extraOptions = [extraOption]; 
      }*/

      /**
       * check if all possible combination already added prev, then move to 
       * next option value
       */ 
      if(false) {
        continue;
      } 
      else 
      {
        if(!v.extraOptions) {
          v.extraOptions = [];
        }

        v.extraOptions.push(extraOption); 
      }

      //if variant got all options

      if(v.extraOptions.length == this.item.options.length) {

        this.variants.push(v);

        //reset to add new option 
        
        v = Object.assign({}, variant); 

        //continue;

        return variant;
      }
  
      //if last option 

      if(i + 1 == this.item.options.length) {
        return;
      }

      return this.generateVariant(i++, v);

      /*for (let optionOtherExtra of this.item.options[1].extraOptions) {

        let variant;

        variant.qty = extraOption.stock_qty;
        variant.extraOptions = [extraOption];

        if (optionOtherExtra.stock_qty < variant.qty) {
          variant.qty = optionOtherExtra.stock_qty;
        }

        //can try recursion here 
        variant.extraOptions.push(optionOtherExtra);

        this.variants.push(variant);
      }*/
    }

    //this.generateVariant(i++,);

    //remove duplicate 
  }

  /**
   * select variant 
   * @param variant 
   */
  selectVariant(variant) {
    this.dismiss({
      item: this.item,
      orderItemExtraOptions: variant.extraOptions
    })
  }

  onBackButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dismiss();
  }

  /**
   * dismiss popup
   * @param data 
   */
  dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        o.dismiss(data);
      }
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
