import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OpeningHoursFormPageRoutingModule } from './opening-hours-form-routing.module';

import { OpeningHoursFormPage } from './opening-hours-form.page';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    OpeningHoursFormPageRoutingModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    TranslateModule.forChild()
  ],
  declarations: [OpeningHoursFormPage]
})
export class OpeningHoursFormPageModule {}
