import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/model/Item';
// services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  public endpoint = 'items';

  constructor(
    public authService: AuthService,
    private _authhttp: AuthHttpService
  ) { }

  /**
   * @param page
   * @param queryString
   * @param pageSize
   * @param expand
   */
  list(page: number = 1, queryString = null, pageSize = 20, expand = null, query = ''): Observable<any> {
    const url = `items?expand=${expand}&page=${page}&per-page=${pageSize}&keyword=${queryString}${query}`;
    return this._authhttp.getRaw(url);
  }

  /**
   * return item detail
   * @param item_uuid
   * @returns
   */
  view(item_uuid: string): Observable<any> {
    const url = `${this.endpoint}/${item_uuid}?expand=currency,itemImage,itemImages,itemVideos,categoryItems,categoryItems.category,options,options.extraOptions,itemVariants,itemVariants.itemVariantImages,itemVariants.itemVariantOptions,itemVariants.itemVariantOptions.option,itemVariants.itemVariantOptions.extraOption`;
    return this._authhttp.get(url);
  }

  /**
   * Add item
   * @param item
   */
  add(item: Item): Observable<any> {
    const url = this.endpoint;
    return this._authhttp.post(url, item);
  }

  /**
   * Update item
   * @param item_uuid
   * @param item
   */
  update(item_uuid: string, item: Item): Observable<any> {
    const url = this.endpoint + '/' + item_uuid;
    return this._authhttp.patch(url, item);
  }
  
  /**
   * Update item
   * @param item_uuid
   * @param item
   */
  updateQuantity(item_uuid: string, item: Item): Observable<any> {
    return this._authhttp.post(
      this.endpoint + '/update-stock',
      {
        item_uuid: item.item_uuid,
        track_quantity: item.track_quantity,
        stock_qty: item.stock_qty
      }
    );
  }

  /**
   * Update item
   * @param uuids
   */
  updateSortNumber(uuids: any): Observable<any> {
    return this._authhttp.post(
      this.endpoint + '/update-position',
      {
        items: uuids
      }
    );
  }

  /**
   * delete item
   * @param item
   * @returns
   */
  delete(item: Item): Observable<any> {
    const url = this.endpoint + '/' + item.item_uuid;
    return this._authhttp.delete(url);
  }

  deleteVideo(item_video_id ): Observable<any> {
    const url = `${this.endpoint}/delete-video/${item_video_id}`;
    return this._authhttp.delete(url);
  }

  /**
   * delete item variant image from cloudinary + database
   * @param item 
   * @param image 
   * @returns 
   */
  deleteVariantImage(item: Item, image = null): Observable<any> {
    const url = `${this.endpoint}/delete-variant-image/${item.item_uuid}/${image}`;
    return this._authhttp.delete(url);
  }

  /**
   * delete item image
   * @param item
   * @param image
   */
  deleteImage(item: Item, image = null): Observable<any> {
    const url = `${this.endpoint}/delete-image/${item.item_uuid}/${image}`;
    return this._authhttp.delete(url);
  }

  /**
   * Download excel containing items info
   * @returns {Observable<any>}
   */
  exportToExcel(): Observable<any> {
    const url = `${this.endpoint}/export-to-excel`;
    return this._authhttp.excelget(url, `Items.xlsx`);
  }

  /**
   * export item report
   * @returns
   */
  exportReport(from = null, to = null): Observable<any> {
    let url = `${this.endpoint}/items-report`;

    if(from && to) {
      url += '?from=' + from + '&to=' + to;
    }

    return this._authhttp.excelget(url, `Items-report.xlsx`);
  }

  /**
   * Update item status
   * @param item_uuid
   */
  updateStatus(item_uuid: string): Observable<any> {
    return this._authhttp.patch(`${this.endpoint}/update-status/${item_uuid}/${this.authService.store_id}`, [ ]);
  }
}
