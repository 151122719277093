import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// services
import { AreaService } from "../../services/logged-in/area.service";
import { AuthService } from "../../services/auth.service";
import { TranslateLabelService } from "../../services/translate-label.service";


@Component({
  selector: 'app-area-location-picker',
  templateUrl: './area-location-picker.component.html',
  styleUrls: ['./area-location-picker.component.scss'],
})
export class AreaLocationPickerComponent implements OnInit {

  public currency_code;
  public currency_rate;

  public areas;
  public perPageCount = 20;
  public currentPage = 0;
  public totalPages = 0;
  public totalCount = 0;
  public search: string = null;
  public loading = false;
  public loadingMore = false;

  public borderLimit = false;
  
  constructor(
    public modalCtrl: ModalController,
    public areaService: AreaService,
    public authService: AuthService,
    public translateService: TranslateLabelService,

  ) { }

  ngOnInit() {
    this.loadArea();
  }

  selected(data = {}) {
    this.modalCtrl.getTop().then(overlay => {
      if (overlay) {
        overlay.dismiss(data);
      }
    });
  }

  /**
   * close popup
   * @param event 
   */
  dismiss(event) {
    event.preventDefault();
    event.stopPropagation();
    
    this.selected({});
  }

  /**
   * load delivery area
   */
  loadArea() {
    
    this.loading = true;

    this.areaService.listDeliveryAreas(this.currentPage, this.getParams()).subscribe(res => {
      this.perPageCount = parseInt(res.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(res.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);
      this.areas = res.body;
    },
      err => this.loading = false,
      () => this.loading = false,
    );
  }

  /**
   * load more on scroll to bottom
   * @param event 
   * @returns 
   */
  loadMoreAreas(event) {
    
    if(this.currentPage >= this.totalPages) {
      if(event && event.target)
        event.target.complete();
      return null;
    }
    
    this.loadingMore = true;
    
    this.currentPage += 1;

    this.areaService.listDeliveryAreas(this.currentPage, this.getParams()).subscribe(res => {
      this.perPageCount = parseInt(res.headers.get('X-Pagination-Per-Page'), 10);
      this.currentPage = parseInt(res.headers.get('X-Pagination-Current-Page'), 10);
      this.totalPages = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);
      this.areas.push(...res.body);

      if(event && event.target)
        event.target.complete();
    },
      err => this.loadingMore = false,
      () => this.loadingMore = false
    );
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  getParams() {
    let param = `expand=businessLocation,area,deliveryZone&store_id=${this.authService.store_id}`;

    if (this.search) {
      param += `&keyword=${this.search}`;
    }

    return param;
  }

  filterSearch(event) {
    this.search = event.detail.value;
    this.currentPage = 1;
    this.loadArea();
  }
}
