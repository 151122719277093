import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ItemListPageRoutingModule } from './item-list-routing.module';

import { ItemListPage } from './item-list.page';
import { ComponentsModule } from "../../../../components/components.module";
import { TranslateModule } from '@ngx-translate/core';
import { ItemComponent } from "../../../../components/item/item.component";
import { CatalogActionsComponent } from 'src/app/components/catalog-actions/catalog-actions.component';
import {LoadingModalModule} from "../../../../components/loading-modal/loading-modal.module";


@NgModule({

    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ItemListPageRoutingModule,
        ComponentsModule,
        TranslateModule.forChild(),
        LoadingModalModule,
    ],
    exports: [
        ItemComponent
    ],
    declarations: [
        ItemListPage,
        ItemComponent,
        CatalogActionsComponent
    ]
})
export class ItemListPageModule { }
