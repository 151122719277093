import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { AuthService } from './auth.service';
//import { environment } from 'src/environments/environment';


declare let analytics; 

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(public authService: AuthService) { 
   // mixpanel.init(environment.mixpanelKey);
  }

  /**
   * register user
   * @param id 
   * @param params 
   */
  async user(id, params) {
    
    //segment

    /*if(window.analytics)
      window.analytics.identify(id, params);
  */

    //customer.io 

    if(analytics) {
          
      analytics.identify({
        // Required attributes
        id: id,    // Use either cio_id, id, or email.

        ...params  

        // Strongly recommended attributes
        //created_at: 1339438758,   // Timestamp in your system that represents when
                                  // the user first signed up. You'll want to send it
                                  // as seconds since the epoch.


        // Example attributes (you can name attributes what you wish)
        //first_name: 'John',       // Add any attributes you'd like to use in the email subject or body.
        //last_name: 'Smith',       // First name and last name are shown on people pages.
        //plan_name: 'premium'      // To use the example segments, set this to 'free' or 'premium'.
      });
    
      analytics.identify(id, params);
    }
    
    //mixpanel 

    mixpanel.identify(id);

    mixpanel.people.set(params); 
  }

  /**
   * page event
   * @param name 
   */
  async page(name) {
 
    /*if(window.analytics)
      window.analytics.page(name, name, {
        "channel": "Dashboard Web App"
      });
 
    if(window.analytics)
      window.analytics.page(name);

    mixpanel.track("Page View", {
      "name": name
    });*/
    
    let params = {
      company_id: this.authService.store_id,
      store_id : this.authService.store_id,
      language : this.authService.language_pref,
      channel : "Dashboard Web App",
      role : this.authService.role,
      do_not_disturb : !this.authService.ringBell || 
        this.authService.ringBell == '0' || 
        this.authService.showPlayButton == true
    };
    //this.authService.ringBell == '1' && this.authService.showPlayButton == false

    mixpanel.track_pageview({"page": name, ...params });

    if(analytics) {
      analytics.page(name, params); /*{
        "channel": "Dashboard Web App"
      });*/
    }
     
    // track the elapsed time between a page viewed and page exit
    //call time_event with page_viewed event
    mixpanel.time_event("page_exit");
  }

  /**
   * custom event
   * @param eventName 
   * @param params 
   */
  async track(eventName, params) {

    params.company_id = this.authService.store_id;
    params.store_id = this.authService.store_id;
    params.language = this.authService.language_pref; 
    params.channel = "Dashboard Web App"; 
    params.role = this.authService.role;
    params.do_not_disturb = !this.authService.ringBell || this.authService.ringBell == '0' || this.authService.showPlayButton == true

    /*if(window.analytics)
      window.analytics.track(eventName, params);*/

    if(analytics) {
      analytics.track(eventName, params);
    }
    
    mixpanel.track(eventName, params);

   // mixpanel.flush();
  }

  refresh() {
    mixpanel.reset();
  }
}
