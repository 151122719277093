import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { AppGuidanceComponent } from './app-guidance.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [AppGuidanceComponent],
    imports: [
        IonicModule,
        TranslateModule.forChild()
    ],
    exports: [AppGuidanceComponent]
})
export class AppGuidanceModule { }
