import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ItemVariantFormPageRoutingModule } from './item-variant-form-routing.module';

import { ItemVariantFormPage } from './item-variant-form.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ItemVariantFormPageRoutingModule,
    TranslateModule.forChild ()
  ],
  declarations: [ItemVariantFormPage]
})
export class ItemVariantFormPageModule {}
