import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { Customer } from 'src/app/model/customer';
// services
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/logged-in/customer.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.page.html',
  styleUrls: ['./customer-form.page.scss'],
})
export class CustomerFormPage implements OnInit {

  public form: FormGroup;

  public loading = false;

  public saving: boolean = false; 

  public borderLimit: boolean = false; 

  public customer_id;

  public model;
  
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public customerService: CustomerService,
    public translateService: TranslateLabelService
  ) {
  }

  /**
   * initialize customer form 
   */
  initForm() {
    this.form = this.formBuilder.group({
      customer_email: [''],//Validators.compose([Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])  Validators.required, 
      customer_name: ['', Validators.compose([Validators.minLength(5), Validators.required])],
      customer_phone_number: ['', Validators.compose([Validators.minLength(5), Validators.required])],
      country_code: ['', Validators.required],
    });
  }

  setPhoneNumber(event) {

    this.form.controls.customer_phone_number.setValue(event.phone_number);
    this.form.controls.country_code.setValue(event.country_code);
    
    this.form.controls.customer_phone_number.markAsDirty();
    this.form.controls.country_code.markAsDirty();

    this.form.controls.customer_phone_number.updateValueAndValidity();
    this.form.controls.country_code.updateValueAndValidity();
  }

  async ngOnInit() {

    this.customer_id = this.activatedRoute.snapshot.paramMap.get('id');
    
    if(!this.customer_id) {
      this.initForm();
    } else {
      this.loadData();
    }
  }

  /**
   * load customer details
   */
  loadData() {
    this.loading = true;

    this.customerService.view(this.customer_id).subscribe(response => {
      this.loading = false;

      this.model = response;

      this.initForm();
    });
  }

  /**
   * dismiss popup
   * @param data 
   */
   dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if (o) {
        o.dismiss(data);
      }
    });
  }

  updateModalFromForm() {
    if(!this.model) {
      this.model = new Customer;
    }

    this.model.customer_name = this.form.value.customer_name;
    this.model.customer_email = this.form.value.customer_email;

    this.model.customer_phone_number = this.form.value.customer_phone_number;//.number;
    this.model.country_code = this.form.value.country_code;//.dialCode.replace("+", ""); 
  }

  /**
   * save custome details 
   */
  save() {

    if(!this.form || !this.form.valid) {
      return false;
    }
    
    this.saving = true; 

    this.updateModalFromForm();

    let action; 

    if(this.model.customer_id) {
      action = this.customerService.update(this.customer_id, this.model);
    } else {
      action = this.customerService.add(this.model);
    }
    
    action.subscribe(response => {

      this.saving = false; 

      if (response.operation != 'success') {
        this.authService.showAlert(
          this.translateService.transform('Error saving customer'),
          this.translateService.errorMessage(response.message),
          this.form.value
        );  
      } else {
        response.customer.totalOrders = 0;
        
        this.dismiss({
          customer: response.customer
        });
      }
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}

