import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Order } from 'src/app/model/order';
import { AuthService } from 'src/app/services/auth.service';
//services
//import { DeliveryZonesService } from 'src/app/services/delivery-zones.service';
import { EventService } from 'src/app/services/event.service';
import { CustomerService } from 'src/app/services/logged-in/customer.service';
import { DeliveryZoneService } from 'src/app/services/logged-in/delivery-zone.service';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-shipping-to',
  templateUrl: './shipping-to.page.html',
  styleUrls: ['./shipping-to.page.scss'],
})
export class ShippingToPage implements OnInit {

  public order: Order;

  public borderLimit: boolean = false;

  public unfilteredCountries = [];
  public unfilteredBusinessLocations = [];
  public unfilteredCities = [];
  public countries = [];
  public cities = [];
  public areas = [];
  public loadingCountriesAvailableForDelivery = true;
  public loadingCitiesData = true;
  public loadingAreasData = true;

  public areaExists: boolean;
  public countryExists: boolean;
 
  //User search input
  searchTerm: string = '';
  searchText: string = '';

  public perPageCount: number;
  public currentPage: number;
  public totalPages: number;

  public states = [];
  
  public savingShippingDetail;

  state_id: any;
  country_id: any; 
  country: any;
  state: any;
  city: any;
  area: any;

  constructor(
    public router: Router,
    public alertCtrl: AlertController,
    public deliveryZonesService: DeliveryZoneService,
    public storeService: StoreService,
    //public authService: AuthService,
    public eventService: EventService,
    public customerService: CustomerService,
    public location: Location,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public authService: AuthService,
    public translateService: TranslateLabelService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {

    if (this.authService.store) {
      this.listOfCountriesAvailableForDelivery();
    } 
  }

  doInfinite(event) {//{ target: { complete: () => void; }; }

    if(this.currentPage >= this.totalPages) {
      if(event && event.target)
        event.target.complete();
      return null;
    }
    
    this.loadingAreasData = true;

    this.currentPage += 1;

    const action = this.state_id?
      this.deliveryZonesService.getCities(this.state_id):
      this.deliveryZonesService.getStates(this.country_id);
    
    action.subscribe(response => {

      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));

      if(this.state_id) {
        this.cities.push(...response.body);
      }else {
        this.states.push(...response.body);
      }

      if(event && event.target)
        event.target.complete();
    },
      err => this.loadingAreasData = false,
      () => this.loadingAreasData = false
    );
  }

  searchState(event) {
    this.loadStoreStates(this.country.country_id, event.target.value);
  }

  loadStoreStates(country_id: any, query = '') {
    
    this.loadingAreasData = true;

    this.deliveryZonesService.getStates(country_id, query).subscribe(response => {

      this.loadingAreasData = false;

      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));

      this.states = response.body; 
    });
  }

  searchCity(event) {
    this.loadStoreCities(this.state.state_id, event.target.value);
  }

  loadStoreCities(state_id: any, query = '') {

    this.loadingAreasData = true;

    const action = state_id? this.deliveryZonesService.getCities(state_id, query): 
      this.deliveryZonesService.getCountryCities(this.country.country_id, query);
    
    action.subscribe(response => {

      this.loadingAreasData = false;
 
      this.perPageCount = parseInt(response.headers.get('X-Pagination-Per-Page'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));

      this.cities = response.body; 
    });
  }

  /**
   * load all restaurant delivery areas
   */
  loadStoreDeliveryAreas(countryId: any) {
    this.loadingAreasData = true;

    this.deliveryZonesService.getDeliveryAreaList(countryId).subscribe(response => {

      this.loadingAreasData = false;

      if (response) {
        this.cities = response;
        this.unfilteredCities = this.cities;
      }
    });
  }

  /**
   * List all countries and areas that store shipping to
   */
  listOfCountriesAvailableForDelivery() {
  
    this.loadingCountriesAvailableForDelivery = true;

    this.deliveryZonesService.listOfCountriesAvailableForDelivery().subscribe(response => {
 
      this.countries = response;

      if (this.countries && this.countries.length == 1 && this.countries[0]['areas'] > 0) {
        
        this.country = this.countries[0];
        this.loadStoreDeliveryAreas(this.countries[0]['country_id']);
      }

      this.unfilteredCountries = this.countries;

      this.loadingCountriesAvailableForDelivery = false;
    });
  }
 
  /**
   * Display search results based on user input
   */
  searchFilter() {

    this.loadingCountriesAvailableForDelivery = true;

    let clonedCountries = JSON.parse(JSON.stringify(this.unfilteredCountries));

    this.countries = clonedCountries.filter((country: { country_name: string; country_name_ar: string; }) => {
      this.countryExists = false;

      if (country.country_name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 || country.country_name_ar.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
        this.countryExists = true;
        return true;
      }

      this.loadingCountriesAvailableForDelivery = false;

      return this.countryExists;
    });
  }
  
  onStateSelected(state) {

    this.state = state; 

    this.state_id = state.state_id; 
    
    if (state.areaDeliveryZone) {

      const params = Object.assign({
          country: this.country, 
          state: this.state
      }, state.areaDeliveryZone);
      
      Object.assign(this.order, params);
      this.dismiss();
    }
    else {
      this.loadStoreCities(this.state_id);
    }
  }

  async onCitySelected(city: any) {
 
    const areaDeliveryZone = city.areaDeliveryZone ? city.areaDeliveryZone : 
      this.state.areaDeliveryZone;

    if (!areaDeliveryZone) {

        const alert = await this.alertCtrl.create({    
            header: this.translateService.transform('Sorry!'),
            message: this.translateService.transform("This store currently doesn't deliver to this location"),
            buttons: [this.translateService.transform('Okay')],
        });
        await alert.present();

        return false;
    }

    const params = {
        ...areaDeliveryZone,
        city: city,
        country: this.country, 
        state: this.state
    };
  
    Object.assign(this.order, params);

    this.dismiss();
  }
  
  getDetailByLocation(city) {
    return new Promise((resolve, reject) => {
      this.deliveryZonesService.getDetailByLocation(city.city_id, this.state.state_id, this.country.country_id).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getDetailByCity(city) {
    this.deliveryZonesService.getDetailByLocation(city.city_id, this.state.state_id, this.country.country_id).subscribe({
      next: (res) => {
          
        const params = {
          deliveryZone: res,
          city: city,
          country: this.country,
          state: this.state
        };
    
        Object.assign(this.order, params);
        
        this.dismiss();
      }, 
      error: async () => {

        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Unable to Log In'),
          message: this.translateService.transform('This store currently doesn\'t deliver to this location'),
          buttons: [this.translateService.transform('Okay')],
        });
        await alert.present(); 
      },
      complete() {
        
      },
    }); 
  }

  onAreaSelected(area) {

    console.log(area);
    const params = {
      ...area,
      country: this.country,
      order_mode: 1
    };
 
    Object.assign(this.order, params);

    this.dismiss();
  }

  /**
   * goToShippingToPageModule
   */
  onCountrySelected(selectedCountry) {

    this.country = selectedCountry;

    this.country_id = selectedCountry['country_id'];

    if(this.country.iso == "KW") 
    {
      this.loadStoreDeliveryAreas(selectedCountry['country_id']);
    } else {
      this.loadStoreStates(selectedCountry['country_id']);
    }
  }

  dismiss() {
    this.modalCtrl.getTop().then(o => {
      if(o) {
        o.dismiss({ ...this.order });
      } else {
        this.navCtrl.setDirection('root');
        this.location.back();
      }
    });
  }
 
  /**
   * Display search results based on user input
   */
  searchArea() {

    this.loadingAreasData = true;

    //Search through restaurant delivery areas
    let clonedCities = JSON.parse(JSON.stringify(this.unfilteredCities));

    this.cities = clonedCities.filter((city: { areas: any[]; city_name: string; city_name_ar: string; }) => {
      this.areaExists = false;

      city.areas = city.areas.filter((areaDeliveryZone: { area: { area_name: string; area_name_ar: string; }; }) => {

        if (
          areaDeliveryZone.area && (
            areaDeliveryZone.area.area_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || 
            areaDeliveryZone.area.area_name_ar.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 
          ) || 
          city.city_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || 
          city.city_name_ar.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
        )
        {
          this.areaExists = true;
          return true;
        }
      });

      this.loadingAreasData = false;

      return this.areaExists;
    });
  }

  /**
   * set Business Location 
   * @param selected_area 
   *
  setBusinessLocation(businessLocation) {

    this.order['order_mode'] = 2;
    this.order.pickupLocation = businessLocation;
    this.order.pickup_location_id =  businessLocation.business_location_id;
    this.order.business_location_name
    this.dismiss(); 
  }*/

  logScrolling(e: any) { //{ detail: { scrollTop: number; }; }
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  /**
   * 
   */
  getDirection(businessLocation: { latitude: string; longitude: string; }){
    if(businessLocation && businessLocation.latitude && businessLocation.longitude){
      let redirectUrl =  'https://www.google.com/maps/dir/?api=1&destination=' + businessLocation.latitude + ',' + businessLocation.longitude ;
      window.open(redirectUrl, '_blank');
    }
  }
}
