import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ItemVariantsComponent } from './item-variants.component';



@NgModule({
  declarations: [
    ItemVariantsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild()
  ]
})
export class ItemVariantsModule { }
