import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, LoadingController, Platform, PopoverController, ToastController } from '@ionic/angular';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import { AuthService } from 'src/app/services/auth.service';
import { ItemsService } from 'src/app/services/logged-in/items.service';
import { EventService } from 'src/app/services/event.service';
//component
import { ItemActionsComponent } from '../item-actions/item-actions.component';
//models
import { Item } from "../../model/Item";


@Component({
  selector: 'app-item-component',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {

  @Input() item;
  @Input() drag: boolean = true;
  @Input() view = 'item';
  public loading = null;

  @Output() updateEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public translateService: TranslateLabelService,
    public authService: AuthService,
    public platform: Platform,
    public itemServices: ItemsService,
    public eventService: EventService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController
  ) {
  }

  ngOnInit() { }

  /**
   * change item publish status
   * @param event
   */
  changeStatus(event) {
    event.preventDefault();
    this.itemServices.updateStatus(this.item.item_uuid).subscribe(res => {
      if (res.operation != 'success') {
        this.alertCtrl.create({
          message: this.authService.errorMessage(res.message)
        }).then(alert => alert.present());
      } else {
        this.item.item_status = (this.item.item_status == 1) ? 2 : 1;
      }
    });
  }

  /**
   * remove item from catalog
   * @param event
   */
  // removeItem(event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   this.eventService.removeEvent$.next(this.item);
  // }

  /**
   * remove item
   * @param data
   */
  async removeItem(event) {
    const data = this.item;
    event.preventDefault();
    event.stopPropagation();
    // event.preventDefault();
    this.alertCtrl.create({
      message: this.translateService.transform('Are you sure you want to remove this item?'),
      buttons: [
        {
          text: this.translateService.transform('Yes'),
          role: 'Yes',
          handler: () => {
            this.loading = true;
            this.itemServices.delete(data).subscribe(res => {
                if (res.operation != 'success') {
                  this.alertCtrl.create({
                    message: this.authService.errorMessage(res.message)
                  }).then(alert => alert.present());
                } else {
                  this.eventService.reloadStock$.next({});
                  // this.loadData();
                }
              },
              err => this.loading = false,
              () => this.loading = false
            );
          }
        },
        {
          text: this.translateService.transform('No'),
          role: 'Cancel',
        }
      ]
    }).then(alert => alert.present());
  }

  /**
   * show action option
   * @param event todo:
   */
  async showItemOption(event) {

    event.preventDefault();
    event.stopPropagation();

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const popover = await this.popoverCtrl.create({
      component: ItemActionsComponent,
      componentProps: {
        item: this.item
      },
      event: event,
      translucent: true
    });
    await popover.present();

    popover.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }

      if (!e.data || !e.data.action) {
        return null;
      }

      if (e.data.action == 'change-status') {
        this.changeStatus(event)
      } else if (e.data.action == 'remove') {
        this.removeItem(event);
      } else if (e.data.action == 'out-of-stock') {
        this.item.track_quantity = 1;
        this.item.stock_qty = 0;
        this.updateQuantity(this.item);
      }
    });
  }

  onItemImageError(item) {
    if(item.itemImage) {
      item.itemImage.product_file_name = null;
    }
  }

  /**
   * item
   * @param item
   */
  async updateQuantity(item: Item) {
    this.loading = item.item_uuid;
    this.itemServices.updateQuantity(item.item_uuid, item).subscribe(res => {
      if (res.operation == 'success') {
        // this.eventService.reloadStock$.next(this.item);
      }
      this.toastCtrl.create({
        message: this.authService.errorMessage(res.message),
        duration: 3000
      }).then(toast => {
        toast.present();
      });
    },
      error => this.loading = null,
      () => this.loading = null
    );
  }


  changeQnty($event, item) {
    if (!item.stock_qty) {
      item.stock_qty = 0;
    }

    this.updateQuantity(item);
  }

  remove(event) {
    if (this.item.stock_qty > 0) {
      this.item.stock_qty = parseInt(this.item.stock_qty, 10) - 1;
    }
  }

  add(event) {
    if (!this.item.stock_qty) {
      this.item.stock_qty = 0;
    }
    this.item.stock_qty = parseInt(this.item.stock_qty, 10) + 1;
  }

  onRenderItems(event) {
    event.detail.complete();
  }

  countChange(event) {
    if (!Number(event.target.value) && event.target.value != '0') {
      event.target.value = event.target.value.replace(/[^0-9]*/g, '');
    }
  }

  update(event) {
    this.updateEvent.emit(this.item);
  }
}
