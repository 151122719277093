import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { LocationPickerComponent } from 'src/app/components/location-picker/location-picker.component';
//services
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AuthService } from 'src/app/services/auth.service';
import { CountryService } from 'src/app/services/logged-in/country.service';
import { ShippingMethodService } from 'src/app/services/logged-in/shipping-method.service';
import { StoreService } from 'src/app/services/logged-in/store.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-aramex',
  templateUrl: './aramex.page.html',
  styleUrls: ['./aramex.page.scss'],
})
export class AramexPage implements OnInit {

  public borderLimit = false;

  public form: FormGroup;

  public countryList = [];

  public loading: boolean = false;
  
  public saving: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public authService: AuthService,  
    public translateService: TranslateLabelService,
    public storeServices: StoreService,
    public countryService: CountryService,
    public analytics: AnalyticsService,
    public shippingService: ShippingMethodService
  ) { }

  ngOnInit() {
    this.analytics.page('Aramex config page');

    this.loadData();

    this.loadCountries();
  }
  
  ionViewWillLeave() {
    this.analytics.track('page_exit', {
      'page': 'Aramex config page'
    });
  }

  loadCountries() {
    this.countryService.list(-1).subscribe(res => { 
      this.countryList = res.body;
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }

  loadData() {
    this.storeServices.getSettings('Aramex').subscribe(data => {
      this.initForm(data);
    });
  }

  initForm(data) { 
    this.form = this.formBuilder.group({
      sandbox: [parseInt(data.shipping_aramex_sandbox), Validators.required],//1, "1", true].contain 
      account_number: [data.shipping_aramex_account_number, Validators.required],
      account_entity: [data.shipping_aramex_account_entity, Validators.required],
      account_pin: [data.shipping_aramex_account_pin, Validators.required],
      username: [data.shipping_aramex_username, Validators.required],
      password: [data.shipping_aramex_password, Validators.required],
      city: [data.shipping_aramex_city, Validators.required],
      country_code: [data.shipping_aramex_country_code, Validators.required],
      state: [data.shipping_aramex_state, Validators.required],
      post_code: [data.shipping_aramex_post_code, Validators.required]
    });
  }

  /**
   * save detail
   */
   save() {

    if(!this.form || !this.form.valid) {
      return false;
    }

    this.saving = true;

    this.shippingService.setConfig("Aramex", this.form.value).subscribe(async response => {

      this.saving = false;

      if(response.operation == 'success') {

        this.dismiss(this.form.value);

        /*this.router.navigate(['/']);
       
        const alert = await this.alertCtrl.create({
          header: this.translateService.transform('Success'),
          message: response.message,
          buttons: [this.translateService.transform('Okay')]
        });

        await alert.present();*/

      } else {

        this.authService.showAlert(
          this.translateService.transform('Error saving aramex config'),
          this.translateService.errorMessage(response.message),
          this.form.value
        );  
      }
    });
  }

  /**
   * open popup to select country
   * @returns
   */
  async selectCountry() {

    window.history.pushState({ navigationId: window.history.state.navigationId }, null, window.location.pathname);

    const modal = await this.modalCtrl.create({
      component: LocationPickerComponent,
      cssClass : 'country-modal'
    });
    modal.present();
    modal.onDidDismiss().then(e => {

      if (!e.data || e.data.from != 'native-back-btn') {
        window['history-back-from'] = 'onDidDismiss';
        window.history.back();
      }
    });

    const { data } = await modal.onWillDismiss();

    if (data && data.country_id) {
 
      this.form.controls.country_code.setValue(data.iso);
      this.form.controls.country_code.markAsDirty();

      this.form.updateValueAndValidity();
    }
  }

  dismiss(data = {}) {
    this.modalCtrl.dismiss(data);
  }
}
