import { Injectable } from '@angular/core';
// services
import { AuthHttpService } from './authhttp.service';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  business_location_id;

  constructor(
    public authService: AuthService,
    private http: AuthHttpService
  ) { }

  get_delivery_zones(delivery_id: string = null): Observable<any> {
    let id: string = (delivery_id == null) ? '' : `&delivery_zone_id=${delivery_id}&business_location_id=${this.business_location_id}`;
    return this.http.get(`delivery-zone/detail?${id}`);
  }

  get_weblinks(web_link_id: string = null): Observable<any> {
    let url = (web_link_id == null) ? 'web-link' : 'web-link/detail';
    let id: string = (web_link_id == null) ? '' : `&web_link_id=${web_link_id}` ;
    return this.http.get(`${url}?${id}`);
  }

  get_country(country_id: string = null): Observable<any> {
    let url: string = (country_id == null)
    ? `country`
    : `country/detail?country_id=${country_id}`;
    return this.http.get(url);
  }
}
