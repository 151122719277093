import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ItemVideoPageRoutingModule } from './item-video-routing.module';

import { ItemVideoPage } from './item-video.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ItemVideoPageRoutingModule,
    TranslateModule.forChild ()
  ],
  declarations: [ItemVideoPage]
})
export class ItemVideoPageModule {}
