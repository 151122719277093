import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { DatePipe } from '@angular/common';
//services 
import { TranslateLabelService } from '../services/translate-label.service';


@Pipe({
	name:'orderTime',
	pure: false
})
export class OrderTimePipe implements PipeTransform {

    public timer: number;
    
    constructor(
        private datePipe: DatePipe,
        public translate: TranslateLabelService
    ) {}
    
	transform(value: string) {

		let d = (value) ? new Date(value.replace(/-/g, '/')):new Date();
		let now = new Date();
        
        let locale = this.translate.currentLang == 'ar' ? 'ar-KW' : 'en-US';

        //if today 
        
        let date = (value) ? new Date(value.replace(/-/g, '/')):new Date();

        if(date.setHours(0,0,0,0) == now.setHours(0,0,0,0)) {
            return this.translate.transform('txt_today_order', { 
                time: this.datePipe.transform(d, 'hh:mm a','', locale) 
            });
        }

        //Thu 01, Apr 21 - 12:26 PM

        return this.datePipe.transform(d, 'EEE dd, MMM yy - hh:mm a','',locale);
    }
}

