import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'cookie-message',
  templateUrl: './cookie-message.component.html',
  styleUrls: ['./cookie-message.component.scss'],
})
export class CookieMessageComponent implements OnInit {
  
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor(
    public router: Router,
    public translate: TranslateLabelService
  ) { }

  ngOnInit() { }
 
  close() {
    this.onClose.emit();
  }
}

