import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddStaffPageRoutingModule } from './add-staff-routing.module';

import { AddStaffPage } from './add-staff.page';
import {ComponentsModule} from 'src/app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        AddStaffPageRoutingModule,
        ComponentsModule,
        TranslateModule.forChild()
    ],
  declarations: [AddStaffPage]
})
export class AddStaffPageModule {}
