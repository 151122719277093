import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrderInvoicePageRoutingModule } from './order-invoice-routing.module';

import { OrderInvoicePage } from './order-invoice.page';
import {ComponentsModule} from "../../../../components/components.module";
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule,
        OrderInvoicePageRoutingModule,
        ComponentsModule,
        TranslateModule.forChild(),
    ],
  declarations: [OrderInvoicePage],
  providers: [DatePipe]

})
export class OrderInvoicePageModule {}
