import { Injectable } from '@angular/core';
//models
import { Campaign } from 'src/app/model/campaign';
//services
import { AuthService } from '../auth.service';
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {

    private endpoint = 'campaigns';
  
    constructor(
      public authService: AuthService,
      private http: AuthHttpService
    ) { }
  
    /**
     * return campaigns
     * @param page
     */
    list(page: number = 1) {
      let url = `${this.endpoint}?expand=totalSale&page=${page}`;  
      return this.http.getRaw(url);
    }
    
    /**
     * return campaign detail
     * @param utm_uuid
     * @returns
     */
    view(utm_uuid: string) {
      const url = `${this.endpoint}/${utm_uuid}?expand=totalSale,campaignChartData`;
      return this.http.get(url);
    }
  
    /**
     * ability to delete campaign
     * @param utm_uuid
     * @returns
     */
    delete(utm_uuid) {
      const url = `${this.endpoint}/delete/${utm_uuid}`;
      return this.http.delete(url);
    }
  
    /**
     * create campaign
     * @param campaign
     */
    create(campaign: Campaign) {
      const url = `${this.endpoint}`;
      const params = {
        source: campaign.utm_source,
        medium: campaign.utm_medium,
        campaign: campaign.utm_campaign,
        content: campaign.utm_content,
        term: campaign.utm_term,
      }
      return this.http.post(url, params);
    }
  
    /**
     * update campaign
     * @param campaign
     */
    update(campaign: Campaign) {
      const url = `${this.endpoint}/${campaign.utm_uuid}`;
      const params = {
        source: campaign.utm_source,
        medium: campaign.utm_medium,
        campaign: campaign.utm_campaign,
        content: campaign.utm_content,
        term: campaign.utm_term,
      }
      return this.http.patch(url, params);
    }

    click(utm_uuid) {
      const url = `${this.endpoint}/click/${utm_uuid}`;
      return this.http.patch(url, {});
    }
  }
  
  
